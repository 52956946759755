<!--Contenedor-->
<div class="container align-items-center flex-column pb-5">
    <div class="row mt-4">
        <div class="col-12">
            <!--p class="text-danger"><a class="text-danger font-weight-bold" href="/e-bienvenida">Inicio</a> > {{ Modulo.nombre }}</p-->
            <div id="breadcrumbs">
                <ul class="steps pl-0 mb-0">
                    <li class="step">                        
                        <a [routerLink]="['/administracion']">
                            Inicio
                        </a>
                    </li>
                    <li class="step inactive">
                        <a >
                            Estudiantes
                        </a>
                    </li>
                </ul>                
            </div>
        </div>
    </div>
    <!--Encabezado-->
    <div class="text-center py-4">
      <h4 class="title-section">Estudiantes</h4>
    </div>
  
    <!--Filtros-->
  
    <!--Listado-->
  
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped text-center table-fixed">
      <thead class="bg-secondary text-white">
        <tr>
          <th scope="col" class="encabezado orden">Nombre</th>
          <th scope="col" class="encabezado orden">Cuenta</th>
          <th scope="col" class="encabezado orden">Carrera</th>
          <th scope="col" class="encabezado orden">Tutor Asignado</th>
          <th scope="col" class="encabezado orden">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr #item *ngFor="let item of TutoresLista; let i = index">
          <td>{{item.nombres + ' ' + item.aPaterno + ' ' + item.aMaterno}}</td>
          <td>{{ item.cuentaAlumno }}</td>
          <td>{{ item.carrera }}</td>
          <td>{{ item.nombreTutor }}</td>
          <td>
              <button class="btn icon-btn" (click)="detalle(item)" ngbTooltip="Ver">
                  <fa-icon [icon]="['fas','eye']" class="text-dark"></fa-icon>
              </button>
          </td>
        </tr>
      </tbody>
      <tfoot class="tfoot-ibero text-center bg-secondary text-white">
        <tr>
          <td colspan="5">{{ (TutoresLista)?.length }} Registros</td>
        </tr>
      </tfoot>
    </table>
  </div>
  