<!--Contenedor-->
<div class="container align-items-center flex-column">
    <div class="row mt-4">
        <div class="col-12">
            <!--p class="text-danger"><a class="text-danger font-weight-bold" href="/e-bienvenida">Inicio</a> > {{ Modulo.nombre }}</p-->
            <div id="breadcrumbs">
                <ul class="steps pl-0 mb-0">
                    <li class="step">                        
                        <a [routerLink]="['/administracion']">
                            Inicio
                        </a>
                    </li>
                    <li class="step">                        
                        <a [routerLink]="['/guia']">
                            Guías
                        </a>
                    </li>
                    <li class="step inactive">
                        <a >
                            {{guiaNombre}}
                        </a>
                    </li>
                </ul>                
            </div>
        </div>
    </div>
  <!--Encabezado-->
  <div class="row justify-content-between py-2 mt-4 mb-2">
    <div class="col-lg-8 col-sm-12 ">
      <h4 class="title-section">{{guiaNombre}}</h4>
    </div>    
    <div class="col-md-4 col-sm-12 text-right">
      <button class="btn btn-danger" (click)="nuevo(content)">Agregar Módulo</button>
    </div>    
    <div class="col-md-12 mt-4">
        <div [innerHtml]="Guia.descripcion"></div>
    </div> 
  </div>

  <!--Filtros-->

  <!--Arbol-->
  <table #lineameta class="table table-striped text-left table-fixed">
    <thead class="bg-secondary text-white text-center">
        <tr>
            <th colspan="2">Módulos</th>
            <!--th scope="col" class="text-center encabezado orden">Diseño de Encuesta</th>
            <th scope="col" class="text-center encabezado orden">Acción</th-->
        </tr>
    </thead>
    <tbody>
        <tr #item *ngFor="let item of Guia.modulos; let i = index">
            <td>
                <div class="modulo-title">
                    <button class="btn icon-btn" (click)="temas(item)" ngbTooltip="Ver Temas" *ngIf="!item.expand"><fa-icon [icon]="['fas','plus']" class="text-dark"></fa-icon></button>
                    <button class="btn icon-btn" (click)="temasCollapse(item)" ngbTooltip="Ocultar Temas" *ngIf="item.expand"><fa-icon [icon]="['fas','minus']" class="text-dark"></fa-icon></button>
                    <span class="mr-2">Módulo:</span> {{item.nombre}}
                </div>
                <div class="tema-title" *ngFor="let itm of item.temas; let j = index">
                    <button class="btn icon-btn" (click)="actividades(itm)" ngbTooltip="Ver Actividades" *ngIf="!itm.expand"><fa-icon [icon]="['fas','plus']" class="text-dark"></fa-icon></button>
                    <button class="btn icon-btn" (click)="actividadesCollapse(itm)" ngbTooltip="Ocultar Actividades" *ngIf="itm.expand"><fa-icon [icon]="['fas','minus']" class="text-dark"></fa-icon></button>
                    <span class="mr-2">Tema:</span> {{itm.nombre}}
                    <fa-icon [icon]="['fas','trash-alt']" class="text-dark ml-2" (click)="quitarTema(deleteTema, item, itm)" ngbTooltip="Quitar Tema"></fa-icon>
                    <fa-icon [icon]="['fas','plus-circle']" class="text-dark ml-2" (click)="agregarActividad(agregaractividad, itm)" ngbTooltip="Agregar Actividad"></fa-icon>
                    <div class="actividad-title" *ngFor="let it of itm.actividades; let k = index">
                        <span class="mr-2">Actividad:</span> {{it.nombre}}
                        <fa-icon [icon]="['fas','trash-alt']" class="text-dark ml-2" (click)="quitarActividad(deleteActividad, itm, it)" ngbTooltip="Quitar Actividad"></fa-icon>
                    </div>
                </div>
            </td>
            <td>
                <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
                  <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
                </button>
                <button class="btn icon-btn" (click)="eliminar(delete, item)" ngbTooltip="Eliminar">
                  <fa-icon [icon]="['fas','trash-alt']" class="text-dark"></fa-icon>
                </button>
                <button class="btn icon-btn" (click)="agregarTema(agregartema, item)" ngbTooltip="Agregar Tema">
                    <fa-icon [icon]="['fas','plus-circle']" class="text-dark"></fa-icon>
                </button>
            </td>
        </tr>
    </tbody>
    <tfoot class="tfoot-ibero text-center bg-secondary text-white">
      <tr>
        <td colspan="2">{{ (Guia.modulos)?.length }} Registros</td>
      </tr>
    </tfoot>
  </table>      
</div>

<ng-template #content let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Módulo</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="fprincipal">
            <div class="row text-dark">
              <input class="form-control" type="hidden" formControlName="idModulo"
                [(ngModel)]="mprincipal.idModulo">
            </div>
  
            <div class="row text-dark">
              <div class="form-group col-lg-12">
                <label for="nombre">Nombre:</label>
                <input class="form-control" type="text" formControlName="nombre" [(ngModel)]="mprincipal.nombre">
              </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="objetivo">Objetivo:</label>
                    <quill-editor class="d-block" formControlName="objetivo" [(ngModel)]="mprincipal.objetivo" id="objetivo"></quill-editor>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="queVasEncontrar">¿Qué vas a encontrar?</label>
                    <quill-editor class="d-block" formControlName="queVasEncontrar" [(ngModel)]="mprincipal.queVasEncontrar" id="queVasEncontrar"></quill-editor>
                </div>
            </div>
            
            <div class="row text-dark pt-3">
              <div class="col-lg-12">
                <div class="buttonSelect text-center">
                  <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                  <button class="btn btn-danger" (click)="guardar()" [disabled]="fprincipal.invalid">Guardar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #delete let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Eliminar Módulo</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="fprincipal">
              <div class="row text-dark">
                  <input class="form-control" type="hidden" formControlName="idModulo"
                    [(ngModel)]="mprincipal.idModulo">
              </div>
  
              <div class="row text-dark">
                  <p>Se borrará el módulo: {{ mprincipal.nombre }}</p>
              </div>
  
              <div class="row text-dark pt-3">
                  <div class="col-lg-12">
                      <div class="buttonSelect text-center">
                          <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                          <button class="btn btn-danger" (click)="borrar()">Eliminar</button>
                      </div>
                  </div>
              </div>
          </form>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #agregartema let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Tema</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="ftema">
            <div class="row text-dark">
              <input class="form-control" type="hidden" formControlName="idModulo"
                [(ngModel)]="mtema.idModulo">
            </div>
  
            <div class="row text-dark">
                <div class="form-group col-lg-12">
                  <label for="temas">Temas</label>
                  <select class="form-control" formControlName='idTema' [(ngModel)]="mtema.idTema" (change)="onChange()">
                    <!--option [ngValue]="0">--Seleccionar--</option-->
                    <option *ngFor="let item of TemasList"
                    [ngValue]="item.idTema">
                      {{ item.nombre }}
                      </option>
                  </select>
                  <p *ngIf="temaWrong" class="text-danger">El Tema seleccionado ya existe en el Módulo</p>
                </div>
            </div>
            
            <div class="row text-dark pt-3">
              <div class="col-lg-12">
                <div class="buttonSelect text-center">
                  <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                  <button class="btn btn-danger" (click)="guardarTema()" [disabled]="ftema.invalid">Guardar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #deleteTema let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Eliminar Tema</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="ftema">
                <div class="row text-dark">
                    <input class="form-control" type="hidden" formControlName="idModulo"
                        [(ngModel)]="mtemad.idModulo">
                </div>

                <div class="row text-dark">
                    <input class="form-control" type="hidden" formControlName="idTema"
                    [(ngModel)]="mtemad.idTema">
                </div>
    
                <div class="row text-dark">
                    <p>Se eliminará el Tema: "{{ mtemad.nombreTema }}" del Módulo: "{{ mtemad.nombreModulo }}"</p>
                </div>
    
                <div class="row text-dark pt-3">
                    <div class="col-lg-12">
                        <div class="buttonSelect text-center">
                            <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                            <button class="btn btn-danger" (click)="borrarTema()">Eliminar</button>
                        </div>
                    </div>
                </div>
          </form>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #agregaractividad let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Actividad</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="factividad">
            <div class="row text-dark">
              <input class="form-control" type="hidden" formControlName="idTema"
                [(ngModel)]="mactividad.idTema">
            </div>
  
            <div class="row text-dark">
                <div class="form-group col-lg-12">
                  <label for="actividades">Actividades</label>
                  <select class="form-control" formControlName='idActividad' [(ngModel)]="mactividad.idActividad" (change)="onChange()">
                    <!--option [ngValue]="0">--Seleccionar--</option-->
                    <option *ngFor="let item of ActividadesList"
                    [ngValue]="item.idActividad">
                      {{ item.nombre }}
                      </option>
                  </select>
                  <p *ngIf="actividadWrong" class="text-danger">La Actividad seleccionada ya existe en el Tema</p>
                </div>
            </div>
            
            <div class="row text-dark pt-3">
              <div class="col-lg-12">
                <div class="buttonSelect text-center">
                  <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                  <button class="btn btn-danger" (click)="guardarActividad()" [disabled]="factividad.invalid">Guardar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #deleteActividad let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Eliminar Actividad</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="factividad">
                <div class="row text-dark">
                    <input class="form-control" type="hidden" formControlName="idTema"
                        [(ngModel)]="mactividadd.idTema">
                </div>

                <div class="row text-dark">
                    <input class="form-control" type="hidden" formControlName="idActividad"
                    [(ngModel)]="mactividadd.idActividad">
                </div>
    
                <div class="row text-dark">
                    <p>Se eliminará la Actividad: "{{ mactividadd.nombreActividad }}" del Tema: "{{ mactividadd.nombreTema }}"</p>
                </div>
    
                <div class="row text-dark pt-3">
                    <div class="col-lg-12">
                        <div class="buttonSelect text-center">
                            <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                            <button class="btn btn-danger" (click)="borrarActividad()">Eliminar</button>
                        </div>
                    </div>
                </div>
          </form>
        </div>
      </div>
    </div>
</ng-template>