import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { CarreraService } from 'src/app/data-access/carrera.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';



@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styles: [
  ]
})
export class RegistroComponent implements OnInit {
  forma: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  enabled = false;
  Colegios;
  model;
  features;
  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private activatedRoute: ActivatedRoute,
    private carreraService: CarreraService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.iniciarForm();
   }


  iniciarForm() {
    /*
    this.forma = this.formBuilder.group({
      correo: ['', [Validators.required]],
      contrasenia: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      apaterno: ['', [Validators.required]],
      amaterno: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      extension: ['', [Validators.required]],
      colegioId: [0],
      usuarioId: [0],
      rolId: [0],
      perfilId: [0],
      usuario_tipoId: [0],
      activo: ['S', [Validators.required]]
    });
    */
   this.forma = this.formBuilder.group({
    cuenta: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    nombres: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
    aPaterno: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
    aMaterno: ['', Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)],
    correo: ['', [Validators.required, Validators.email]],
    telefonoPersonal: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
    Validators.minLength(10), Validators.maxLength(10)]],
    telefonoCasa: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
    Validators.minLength(10), Validators.maxLength(10)]],
    //extension: ['', [Validators.pattern("^[0-9]*$"),Validators.minLength(1), Validators.maxLength(5)]],
    clave: ['', Validators.required],
    confirmaclave: ['', Validators.required],
    idCarrera: [0, [Validators.required]],
    semestre: [0, [Validators.required, Validators.pattern("^[1-9]*$"),
    Validators.minLength(1), Validators.maxLength(2)]],
    idUsuario: [0],
    //rolId: [0],
    //perfilId: [0],
    idRol: [0],
    activo: [true],
    //otro_colegio_nombre: ['']
  },
  { validator: this.passwordConfirming }
  );
    this.model = this.forma;
  }

  /**********Validador de contraseñas iguales ***********/
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('clave').value !== c.get('confirmaclave').value) {
        return {invalid: true};
    }
  }

  Carreras;
  ngOnInit(): void {
    //this.carreraService.getCarrera();
    //alert(1);
    //this.spinner.show();
    //this.Colegios = this.usuarioService.getColegioListado();
    //this.features = this.usuarioService.getColegioListado();

    this.carreraService.getCarrera()
    .toPromise().then(data => {
        this.Carreras = data;
      })

    //console.log(this.Colegios);
    /*
    this.registro_usuario = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellidoPaterno: ['', Validators.required],
      apellidoMaterno: ['', Validators.required],
      telefono: ['', Validators.required],
      extension: ['', Validators.required],
      correoelectronico: ['', Validators.required],
      contraseña: ['', Validators.required],
      confirmacontrasenia: ['', Validators.required],
      nombrecolegio: ['', Validators.required],
      calle: ['', Validators.required],
      no_ext: ['', Validators.required],
      no_int: ['', Validators.required],
      colonia: ['', Validators.required],
      estado: ['', Validators.required],
      municipio: ['', Validators.required],
      codigopostal: ['', Validators.required]

    });
    */

  }

  guardar() {
    console.log(this.forma.value);
    /*
    var objColegio = this.forma.controls["colegioId"].value;
    //Si seleciona uno de la lista
    if(typeof objColegio === 'object'){
      //El control de búsqueda asigna un objeto al colegioId
      //Extraemos el id del Colegio y reemplazamos el valor
      //var objColegio = this.forma.controls["colegioId"].value;
      this.forma.controls["colegioId"].setValue(Number(objColegio.colegioId));
    }
    //Si captura uno nuevo
    if(typeof objColegio === 'string'){
      this.forma.controls["colegioId"].setValue(0);
      this.forma.controls["otro_colegio_nombre"].setValue(objColegio);
    }
    */
    //console.log(this.forma.value);

    //return false;

    //console.log(this.forma.value);
    //return false;
    this.spinner.show();
    console.log(this.forma.value);
    this.usuarioService.addData(this.forma.value).subscribe(
      (res) => {
        this.spinner.hide();
        this.recargar();
      },
      (error) => {
        if(error.error.result === 3)
        {
          this.spinner.hide();
          this.toastr.error(error.error.message);
        }
        console.log(error);
      });
  }

  recargar() {
    //this.modal.dismissAll();
    //this.Usuario = this.usuarioService.getData();
    this.toastr.success('Se ha completado tu registro.');
    //alert("La solicitud ha sido enviada");
    this.router.navigate(['/']);
    //this.router.navigate(['./login']);
  }

  cancelar(){
    this.router.navigate(['/']);
  }

}
