<!--Contenedor-->
<div class="container align-items-center flex-column pb-5">
    <div class="row mt-4">
        <div class="col-12">
            <!--p class="text-danger"><a class="text-danger font-weight-bold" href="/e-bienvenida">Inicio</a> > {{ Modulo.nombre }}</p-->
            <div id="breadcrumbs">
                <ul class="steps pl-0 mb-0">
                    <li class="step">                        
                        <a [routerLink]="['/administracion']">
                            Inicio
                        </a>
                    </li>
                    <li class="step inactive">
                        <a >
                            Guías
                        </a>
                    </li>
                </ul>                
            </div>
        </div>
    </div>

  <!--Encabezado-->
  <div class="text-center pt-3">
    <h4 class="title-section">Guías</h4>
  </div>

  <!--Filtros-->

  <!--Listado-->

  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
      <h5 class="title-subsection">&nbsp;</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
      <button *ngIf="us.adm() || us.coordPIT()" class="btn btn-danger" (click)="nuevo(content)">Agregar</button>
    </div>
  </div>

  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped text-center table-fixed">
    <thead class="bg-secondary text-white">
      <tr>
        <th scope="col" class="encabezado orden">Nombre</th>
        <th scope="col" class="encabezado orden">Fecha Registro</th>
        <th scope="col" class="encabezado orden">Estatus</th>
        <th scope="col" class="encabezado orden">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr #item *ngFor="let item of GuiaLista; let i = index">
        <td class="w-20">{{item.nombre}}</td>
        <td class="w-10">{{ item.fechaRegistroFormateado }}</td>
        <td class="w-10">
          {{ item.activo ? "Activo":"Inactivo" }}
        </td>
        <td class="w-10">
            <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
                <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
            </button>
            <button class="btn icon-btn" (click)="detalle(item)" ngbTooltip="Gestionar">
                <fa-icon [icon]="['fas','cogs']" class="text-dark"></fa-icon>
            </button>
            <button class="btn icon-btn" (click)="eliminar(delete, item)" ngbTooltip="Eliminar">
                <fa-icon [icon]="['fas','trash-alt']" class="text-dark"></fa-icon>
            </button>
            <button class="btn icon-btn" ngbTooltip="{{ item.descripcion }}">
                <fa-icon [icon]="['fas','info-circle']" class="text-dark"></fa-icon>
            </button>
            <button class="btn icon-btn" (click)="preview(item)" ngbTooltip="Vista Previa">
                <fa-icon [icon]="['fas','eye']" class="text-dark"></fa-icon>
            </button>
        </td>
      </tr>
    </tbody>
    <tfoot class="tfoot-ibero text-center bg-secondary text-white">
      <tr>
        <td colspan="4">{{ (GuiaLista)?.length }} Registros</td>
      </tr>
    </tfoot>
  </table>
</div>

<!--Formulario-->

<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Guía</div>
      <div class="card-body text-dark">

        <form [formGroup]="fprincipal">
          <div class="row text-dark">
            <input class="form-control" type="hidden" formControlName="idGuia"
              [(ngModel)]="mprincipal.idGuia">
          </div>

          <div class="row text-dark">
            <div class="form-group col-lg-12">
              <label for="nombre">Nombre:</label>
              <input class="form-control" type="text" formControlName="nombre" [(ngModel)]="mprincipal.nombre">
            </div>
          </div>

          <div class="row text-dark">
            <div class="form-group col-lg-12">
              <label for="descripcion">Descripción:</label>
              <quill-editor class="d-block" formControlName="descripcion"
              [(ngModel)]="mprincipal.descripcion" id="descripcion"></quill-editor>
            </div>
          </div>

          <div class="row text-dark pt-3">
            <div class="col-lg-12">
              <div class="buttonSelect text-center">
                <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                <button class="btn btn-danger" (click)="guardar()" [disabled]="fprincipal.invalid">Guardar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!--Perfiles-->
<ng-template #delete let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Eliminar Guía</div>
      <div class="card-body text-dark">

        <form [formGroup]="fprincipal">
            <div class="row text-dark">
                <input class="form-control" type="hidden" formControlName="idGuia"
                  [(ngModel)]="mprincipal.idGuia">
            </div>

            <div class="row text-dark">
                <p>Se borrará la guía: {{ mprincipal.nombre }}</p>
            </div>

            <div class="row text-dark pt-3">
                <div class="col-lg-12">
                    <div class="buttonSelect text-center">
                        <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                        <button class="btn btn-danger" (click)="borrar()">Eliminar</button>
                    </div>
                </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
