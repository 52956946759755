<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <div class="row justify-content-between py-2">
    <div class="col-lg-4 col-sm-12 ">
      <h4 class="title-section">Diseño de Evaluación</h4>
    </div>
    <div class="col-lg-4 col-sm-12 ">
      <h4 class="title-section">{{evaluacionNombre}}</h4>
    </div>    
    <div class="col-md-4 col-sm-12 text-right">
      <button class="btn btn-danger" (click)="nuevo(content)">Agregar Sección</button>
    </div>    
  </div>

  <!--Filtros-->

  <!--Arbol-->
  <table #lineameta class="table table-striped text-left table-fixed">
    <thead class="bg-secondary text-white">
      <tr>
        <th colspan="2">&nbsp;</th>
        <!--th scope="col" class="text-center encabezado orden">Diseño de Encuesta</th>
        <th scope="col" class="text-center encabezado orden">Acción</th-->
      </tr>
    </thead>
    <tbody>

      <ng-container *ngFor="let seccion of Evaluacion; let a = index">

        <tr class="eStyle t-show bg-secondary" id="e_{{seccion.idSeccion}}">
          <td>
            <label class="elinea text-white ">
              Sección {{ a+1 }}: {{ seccion.seccionNombre }}
            </label>
          </td>
          <td class="w-10 text-right">
            <button class="btn icon-btn" (click)="editar(content, seccion)" ngbTooltip="Editar Sección">
              <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
            </button>
            <button class="btn icon-btn" (click)="nuevaPregunta(pregunta, seccion)" ngbTooltip="Agregar Pregunta">
              <fa-icon [icon]="['fas','plus-circle']" class="text-dark"></fa-icon>
            </button>                
          </td>          
        </tr>
        <ng-container *ngFor="let item of seccion.children; let b = index">
          <tr class="pStyle t-show" id="p_{{item.idPregunta}}">
            <td>
              <label class="elinea">
                {{ b+1 }} . {{ item.preguntaDescripcion }}
              </label>
            </td>
            <td class="w-10  text-right ">
              <button class="btn icon-btn" (click)="editarPregunta(pregunta, item)" ngbTooltip="Editar">
                <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
              </button>
              <!--button class="btn icon-btn" (click)="detalle(item)" ngbTooltip="Ver Detalle">
                <fa-icon [icon]="['fas','plus-circle']" class="text-dark"></fa-icon>
              </button-->                
            </td>            
          </tr>
          <tr class="rStyle t-show" id="r_{{item.idRespuesta}}">
            <td colspan="2">
              <label class="elinea">
                Respuesta: {{ item.respuestaDescripcion }}
              </label>
            </td>           
          </tr>                    
        </ng-container>
      </ng-container>

    </tbody>
    <tfoot class="tfoot-ibero text-center bg-secondary text-white">
      <tr>
        <td colspan="2">
          &nbsp;
        </td>
      </tr>
    </tfoot>
  </table>      
</div>

<!--Formulario-->

<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Sección</div>
      <div class="card-body text-dark">

        <form [formGroup]="fseccion">
          <div class="row text-dark">
            <input class="form-control" type="hidden" formControlName="idEvalSeccion"
                   [(ngModel)]="mseccion.idEvalSeccion">
            <input class="form-control" type="hidden" formControlName="idEvaluacion"
                   [(ngModel)]="mseccion.idEvaluacion">
          </div>

          <div class="row text-dark">
            <div class="form-group col-lg-12">
              <label for="idSeccion">Sección:</label>
              <select class="form-control" formControlName="idSeccion"
              [(ngModel)]="mseccion.idSeccion">
                <!--option [ngValue]="0">--Seleccionar--</option-->
                <option *ngFor="let item of Secciones"
                [ngValue]="item.idSeccion">
                  {{ item.nombre }}
                  </option>
              </select>     
          </div>
          </div>

          <div class="row text-dark pt-3">
            <div class="col-lg-12">
              <div class="buttonSelect text-center">
                <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                <button class="btn btn-danger" (click)="guardarSeccion()">Guardar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>


<!--Preguntas-->
<ng-template #pregunta let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Sección</div>
      <div class="card-body text-dark">

        <form [formGroup]="fpregunta">
          <div class="row text-dark">
            <input class="form-control" type="hidden" formControlName="idEvalSeccion"
                   [(ngModel)]="mpregunta.idEvalSeccion">            
            <input class="form-control" type="hidden" formControlName="idEvalPregunta"
                   [(ngModel)]="mpregunta.idEvalPregunta">
          </div>

          <div class="row text-dark">
            <div class="form-group col-lg-12">
              <label for="idPregunta">Pregunta:</label>
              <select class="form-control" formControlName="idPregunta"
              [(ngModel)]="mpregunta.idPregunta">
                <!--option [ngValue]="0">--Seleccionar--</option-->
                <option *ngFor="let item of Preguntas"
                [ngValue]="item.idPregunta">
                  {{ item.descripcion }}
                  </option>
              </select>     
          </div>
          </div>

          <div class="row text-dark">
            <div class="form-group col-lg-12">
              <label for="idRespuesta">Respuesta:</label>
              <select class="form-control" formControlName="idRespuesta"
              [(ngModel)]="mpregunta.idRespuesta">
                <!--option [ngValue]="0">--Seleccionar--</option-->
                <option *ngFor="let item of Respuestas"
                [ngValue]="item.idRespuesta">
                  {{ item.descripcion }}
                  </option>
              </select>     
          </div>
          </div>          

          <div class="row text-dark pt-3">
            <div class="col-lg-12">
              <div class="buttonSelect text-center">
                <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                <button class="btn btn-danger" (click)="guardarPregunta()">Guardar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #pregunta let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Pregunta</div>
      <div class="card-body text-dark">

        <form [formGroup]="fpregunta">
          <div class="row text-dark">
            <input class="form-control" type="hidden" formControlName="idSeccion"
                   [(ngModel)]="mpregunta.idSeccion">                 
          </div>

          <div class="row text-dark">
            <div class="form-group col-lg-12">
              <label for="idSeccion">Sección:</label>
              <select class="form-control" formControlName="idSeccion"
              [(ngModel)]="mseccion.idSeccion">
                <!--option [ngValue]="0">--Seleccionar--</option-->
                <option *ngFor="let item of Secciones"
                [ngValue]="item.idSeccion">
                  {{ item.nombre }}
                  </option>
              </select>     
            </div>
          </div>

          <div class="row text-dark pt-3">
            <div class="col-lg-12">
              <div class="buttonSelect text-center">
                <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                <button class="btn btn-danger" (click)="guardar()" [disabled]="fprincipal.invalid">Guardar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>


<!--Perfiles-->
<!--ng-template #cperfil let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Perfiles</div>
      <div class="card-body text-dark">

        <form [formGroup]="fperfil">
          <div class="row text-dark">
            <h5 class="title-subsection">Perfiles de sistema</h5>
            <div class="form-group col-lg-12">
              <ul class="interior" id="menu2">
                <li name="list" *ngFor="let Perfil of aPerfil; let a = index; let last = last">
                  <label for="{{ Perfil.id }}">
                    {{ Perfil.perfil }}
                  </label>
                  <ul class="interior">
                    <li name="list" *ngFor="let Modulo of Perfil.children; let b = index">
                      <label for="{{ Modulo.id }}">
                        {{ Modulo.modulo }}
                      </label>
                      <ul class="interior">
                        <li name="list" *ngFor="let Operacion of Modulo.children; let c = index">
                          <label for="{{ Operacion.id }}">
                            <input type="checkbox" name="opts" [checked]="(Operacion.acceso==1)?'checked':''" id="{{ Operacion.id }}">  {{ Operacion.operacion }}
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="row text-dark pt-3">
            <div class="col-lg-12">
              <div class="buttonSelect text-center">
                <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                <button class="btn btn-danger" (click)="updIdPerfil()">Guardar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template-->
