import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InvitadoService } from 'src/app/data-access/invitado.service';

@Component({
  selector: 'app-contra-invitado',
  templateUrl: './contra-invitado.component.html',
  styleUrls: ['./contra-invitado.component.css']
})
export class ContraInvitadoComponent implements OnInit {

  usuarios:any;
  contra:string = "";
  contraConf: string = "";
  idUsuario:number;

  constructor(private invitadoService: InvitadoService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.invitadoService.getInvitados().toPromise()
    .then((data:any)=>{
      this.usuarios = data;
    });
  }

  seleccionarId(idUsuario){
    this.idUsuario = idUsuario;
  }

  guardarContrasena(){
    
    if(this.contra != this.contraConf){
      this.toastr.warning("Las contraseñas no coinciden", '', {positionClass: 'toast-bottom-left'});
    }else{
      this.spinner.show();
      let usuario = {idUsuario: this.idUsuario,password: this.contra}
      
      this.invitadoService.cambiarContra(usuario).toPromise().then((data:any)=>{
        if(data.valido == true){
          this.toastr.success("Se cambió correctamente", 'Contraseña guardada', {positionClass: 'toast-bottom-left'})
        }else{
          this.toastr.error("Error al actualizar",'Error', {positionClass: 'toast-bottom-left'});
        }
        this.contra = '';
        this.contraConf = '';
        this.spinner.hide();
        $('#cerrarContra').trigger('click');
      });
    }

  }

  cancelarContra(){
    this.contra = '';
    this.contraConf = '';
  }

  resetContrasena(){

    this.spinner.show();

    this.invitadoService.resetContra().toPromise().then((data:any)=>{
      if(data.valido === true){
        this.toastr.success("Se actualizó correctamente", 'Contraseñas guardadas', {positionClass: 'toast-bottom-left'});
      }else{
        this.toastr.error("Error al actualizar", 'Error', {positionClass: 'toast-bottom-left'});
      }
      $('#cerrarReset').trigger('click');
      this.spinner.hide();
    });
  }

}
