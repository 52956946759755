import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../data-access/config';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(private http: HttpClient) { }

  getAreas(){
    return this.http.get(config.apiUrl+'/reporte/areas');
  }

  getPreguntasHA(){
    return this.http.get(config.apiUrl+'/Reporte/HA/preguntas');
  }

  getReporteHA(cohorte, anio, periodo, area, cuenta){
    return this.http.get(config.apiUrl+'/reporte/HA/'+cohorte+'/'+anio+'/'+periodo+'/'+area+'/'+cuenta);
  }

  getReporteHACoord(cohorte, anio, periodo, coord, cuenta){
    return this.http.get(config.apiUrl+'/reporte/HACoord/'+cohorte+'/'+anio+'/'+periodo+'/'+coord+'/'+cuenta);
  }

  getReporteHATut(tutor, cuenta){
    return this.http.get(config.apiUrl+'/reporte/HATut/'+tutor+'/'+cuenta);
  }

}
