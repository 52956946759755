<div class="container">
    <div class="row mt-4">
        <div class="col-12">
            <!--p class="text-danger"><a class="text-danger font-weight-bold" href="/e-bienvenida">Inicio</a> > {{ Modulo.nombre }}</p-->
            <div id="breadcrumbs">
                <ul class="steps pl-0 mb-0">
                    <li class="step">                        
                        <a [routerLink]="['/e-bienvenida']">
                            {{ Guia.nombre }}
                        </a>
                    </li>
                    <li class="step">
                        <a [routerLink]="['/e-modulo']">
                            {{ Modulo.nombre }}
                        </a>
                    </li>
                    <li class="step inactive">
                        <a >
                            {{ Tema.nombre }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-4">
            
        </div>
        <div class="col-md-4">
            <h4 class="text-danger font-weight-bold text-center">{{ nivel == 'tema' ? 'Tema' : 'Tema: ' + Tema.nombre }}</h4>
        </div>
        <div class="col-md-4 text-right">
            <fa-icon [icon]="['far','compass']" [ngClass]="seccion == 'objetivo' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('objetivo', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="Objetivo del Tema"></fa-icon>
            <fa-icon [icon]="['fas','key']" [ngClass]="seccion == 'conceptos' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('conceptos', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="Conceptos Clave"></fa-icon>
            <fa-icon [icon]="['fas','chart-line']" [ngClass]="seccion == 'actividades' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('actividades', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="Actividades"></fa-icon>
            <fa-icon [icon]="['far','clipboard']" [ngClass]="seccion == 'encontrar' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('encontrar', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="¿Que vas a encontrar?"></fa-icon>
            <fa-icon [icon]="['fas','asterisk']" [ngClass]="seccion == 'competencias' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('competencias', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="Competencias Manresa"></fa-icon>
            <fa-icon [icon]="['fas','globe']" [ngClass]="seccion == 'metas' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('metas', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="Metas"></fa-icon>
            <!--fa-icon [icon]="['far','star']" class="text-dark mr-2"></fa-icon>
            <fa-icon [icon]="['far','question-circle']" class="text-dark mr-2"></fa-icon-->
        </div>
    </div>
    <p class="font-weight-bold mb-0 text-danger" style="font-size: 14px">{{ Cuenta.cuenta + ' - ' + Cuenta.nombres + ' ' + Cuenta.aPaterno + ' ' + Cuenta.aMaterno }}</p>
    <div class="row mt-1" *ngIf="nivel=='tema'">
        <div class="col-md-12">
            <div class="bg-secondary text-center font-weight-bold text-white mb-4 py-2">
                {{ Tema.nombre }}
            </div>
        </div>
        <div class="col-md-4">
            <img src="../../../../assets/img/inaki-armando.png" class="img-fluid">
        </div>
        <div class="col-md-8">
            <div class="arrows-nav text-right">
                <fa-icon *ngIf="seccion!='objetivo'" [icon]="['fas','arrow-circle-left']" (click)="prev('tema')" class="text-danger mr-2 h4" style="cursor:pointer" ngbTooltip="Anterior"></fa-icon>
                <fa-icon *ngIf="seccion!='metas'" [icon]="['fas','arrow-circle-right']" (click)="next('tema')" class="text-danger mr-2 h4" style="cursor:pointer" ngbTooltip="Siguiente"></fa-icon>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='objetivo'">
                <h5 class="mb-4">Objetivo:</h5>
                <div [innerHtml]="Tema.objetivo"></div>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='conceptos'">
                <h5 class="mb-4">Conceptos Clave:</h5>
                <ul>
                    <li *ngFor="let item of Tema.conceptosClaveGlobales">
                        <p class="font-weight-bold mb-2">{{ item.concepto }}:</p>
                        <div [innerHtml]="item.significado"></div>
                    </li>
                </ul>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='actividades'">
                <h5 class="mb-4">Actividades:</h5>
                <ul>
                    <li class="mb-2" *ngFor="let item of Tema.actividades"><a class="text-danger font-weight-bold" (click)="cambiarVista('contexto', 'actividad', 'nacho-escribiendo.png', item)" style="cursor:pointer">{{ item.nombre }}</a></li>
                </ul>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='encontrar'">
                <h5 class="mb-4">¿Qué vas a encontrar en este tema?</h5>
                <div [innerHtml]="Tema.queVasEncontrar"></div>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='competencias'">
                <h5 class="mb-4">Competencias Genéricas Manresa:</h5>
                <div *ngFor="let item of Tema.competencias">
                    <p class="font-weight-bold mb-2">{{ item.nombre }}</p>
                    <div class="mb-3" [innerHtml]="item.elementos"></div>
                </div>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='metas'">
                <h5 class="mb-4">Metas:</h5>
                <div [innerHtml]="Tema.metas"></div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="bg-secondary mt-4 py-3"></div>
        </div>
    </div>
    <!-- ********************************************  Actividad  *************************************************** -->
    <div class="row mt-1" *ngIf="nivel=='actividad'">
        <div class="col-md-12">
            <div class="row mb-4 mx-0 bg-secondary">
                <div class="col-md-4">
                    
                </div>
                <div class="col-md-4">
                    <div class="text-center font-weight-bold text-white py-2">
                        Actividad: {{ Actividad.nombre }}
                    </div>
                </div>
                <div class="col-md-4 text-right pt-2">
                    <fa-icon [icon]="['fas','map-marker-alt']" [ngClass]="seccion == 'contexto' ? 'text-black h5' : 'text-light'" class="mr-2" style="cursor:pointer" (click)="cambiarVista('contexto', 'actividad', 'nacho-escribiendo.png', Actividad)" ngbTooltip="Contexto"></fa-icon>
                    <fa-icon [icon]="['fas','heart']" [ngClass]="seccion == 'experiencia' ? 'text-black h5' : 'text-light'" class="mr-2" style="cursor:pointer" (click)="cambiarVista('experiencia', 'actividad', 'nacho-explicando.png', Actividad)" ngbTooltip="Experiencia"></fa-icon>
                    <fa-icon [icon]="['fas','balance-scale']" [ngClass]="seccion == 'reflexion' ? 'text-black h5' : 'text-light'" class="mr-2" style="cursor:pointer" (click)="cambiarVista('reflexion', 'actividad', 'inaki-pensando.png', Actividad)" ngbTooltip="Reflexión"></fa-icon>
                    <fa-icon [icon]="['fas','running']" [ngClass]="seccion == 'accion' ? 'text-black h5' : 'text-light'" class="mr-2" style="cursor:pointer" (click)="cambiarVista('accion', 'actividad', 'inaki-compu.png', Actividad)" ngbTooltip="Acción"></fa-icon>
                    <fa-icon [icon]="['far','check-circle']" [ngClass]="seccion == 'evaluacion' ? 'text-black h5' : 'text-light'" class="mr-2" style="cursor:pointer" (click)="cambiarVista('evaluacion', 'actividad', 'inaki-lupa.png', Actividad)" ngbTooltip="Evaluación"></fa-icon>
                    <!--fa-icon [icon]="['far','star']" class="text-dark mr-2"></fa-icon>
                    <fa-icon [icon]="['far','question-circle']" class="text-dark mr-2"></fa-icon-->
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <img src="../../../../assets/img/{{imagen}}" class="mt-4 img-fluid">
        </div>
        <div class="col-md-9">
            <div class="arrows-nav text-right">
                <fa-icon *ngIf="seccion!='contexto'" [icon]="['fas','arrow-circle-left']" (click)="prev('actividad')" class="text-danger mr-2 h4" style="cursor:pointer" ngbTooltip="Anterior"></fa-icon>
                <fa-icon *ngIf="seccion!='evaluacion'" [icon]="['fas','arrow-circle-right']" (click)="next('actividad')" class="text-danger mr-2 h4" style="cursor:pointer" ngbTooltip="Siguiente"></fa-icon>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='contexto'">
                <h5 class="mt-4 mb-4">Dirigido a:</h5>
                <p>{{ Actividad.dirigidoA }}</p>
                <h5 class="mt-4 mb-4">Conceptos clave:</h5>
                <ul>
                    <li *ngFor="let item of Actividad.conceptosClave">
                        <p class="font-weight-bold mb-2">{{ item.concepto }}:</p> 
                        <div [innerHtml]="item.significado"></div>
                    </li>
                </ul>
                <h5 class="mt-4 mb-4">Objetivo:</h5>
                <div [innerHtml]="Actividad.objetivo"></div>
                <h5 class="mt-4 mb-4">Bibliografía y recursos:</h5>
                <div [innerHtml]="Actividad.bibliografiaRec"></div>
                <h5 class="mt-4 mb-4">Momento de la Trayectoria:</h5>
                <div [innerHtml]="Actividad.momentosTrayectoria"></div>
                <h5 class="mt-4 mb-4">Actividades relacionadas:</h5>
                <ul>
                    <li *ngFor="let item of Actividad.actividadesRelacionadas">
                        <p class="mb-2">{{ item.nombre }}</p> 
                    </li>
                </ul>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='experiencia'">
                <h5 class="mt-4 mb-4">¿Qué encontrarás en esta actividad?</h5>
                <div [innerHtml]="Actividad.queVasEncontrar"></div>
                <h5 class="mt-4 mb-4">Presentación:</h5>
                <div [innerHtml]="Actividad.inicio"></div>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='reflexion'">
                <h5 class="mt-4 mb-4">¿Qué vamos a aprender?</h5>
                <div [innerHtml]="Actividad.desarrollo"></div>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='accion'">
                <h5 class="mt-4 mb-4">¿Qué vamos a hacer?</h5>
                <div [innerHtml]="Actividad.cierre"></div>
                <div *ngIf="Actividad.idTipoActividad==1" class="my-2 py-4">
                    <div *ngIf="Actividad.idActividad != 3 || respuestasAlt.respuestas.length">
                        <div *ngIf="Actividad.idActividad == 3">
                            <p class="font-weight-bold">RESULTADOS:</p>
                            <div *ngFor="let item of respuestasAlt.resultadosClasificaciones" class="mb-4">
                                <div class="d-flex justify-content-between">
                                    <p class="mb-0 small"><b>ESTILO {{ item.clasificacion }}</b></p>
                                    <p class="mb-0 small">Nivel de preferencia: <span class="font-weight-bold" [ngClass]="{'ACTIVO' : 'text-orange2', 'REFLEXIVO' : 'text-green2', 'TEÓRICO' : 'text-purple2', 'PRAGMÁTICO' : 'text-blue2'}[item.clasificacion]">{{ item.preferenciaAlumno }}</span></p>
                                </div>
                                <div class="progress finbyz-fadeinup" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                    [ngClass]="{'ACTIVO' : 'bg-orange2', 'REFLEXIVO' : 'bg-green2', 'TEÓRICO' : 'bg-purple2', 'PRAGMÁTICO' : 'bg-blue2'}[item.clasificacion]"
                                        role="progressbar" [ngStyle]="{'width': (item.totalClasificacion / 20 * 100) + '%' }">{{ item.totalClasificacion }} de Valoración</div>
                                </div>
                            </div>
                            <div>
                                <apx-chart [series]="chartOptions.series"
                                [chart]="chartOptions.chart"
                                [xaxis]="chartOptions.xaxis"
                                [stroke]= "chartOptions.stroke"
                                [dataLabels]= "chartOptions.dataLabels"
                                [tooltip]= "chartOptions.tooltip"
                                [plotOptions]= "chartOptions.plotOptions"
                                [fill]= "chartOptions.fill"
                                [colors]= "chartOptions.colors"
                                [yaxis]= "chartOptions.yaxis"
                                [markers]= "chartOptions.markers"
                                [title]="chartOptions.title"></apx-chart>
                            </div>
                        </div>
                        <table class="table table-bordered">
                            <thead>
                                <tr class="table-danger">
                                    <th *ngFor="let item of Actividad.dinamica[0].dinamicaTabla.columnas">{{ item.nombre }}</th>
                                    <th><fa-icon class="text-danger" [icon]="['fas','trash-alt']"></fa-icon></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of tablaRespuestas;let i = index; trackBy: trackByFn">
                                    <td *ngFor="let itm of item;let j = index; trackBy: trackByFn"><textarea class="form-control" [(ngModel)]="tablaRespuestas[i][j]"></textarea></td>
                                    <td><fa-icon class="text-danger" [icon]="['fas','times']" (click)="borrarFila(i)" style="cursor:pointer" ngbTooltip="Eliminar Fila"></fa-icon></td>
                                </tr>
                            </tbody>
                        </table>
                        <button class="btn btn-danger" (click)="agregarFila()">Agregar Nueva Fila</button>
                        <div *ngIf="Actividad.idActividad != 3 || respuestasAlt.respuestas.length">
                            <button class="my-3 btn btn-danger mr-2" (click)="guardarActividad()">Guardar Actividad</button>
                            <button *ngIf="Actividad.idTipoActividad == 4" class="my-3 btn btn-danger" (click)="descargarSemanal()">Descargar Plan Semanal</button>
                            <button *ngIf="Actividad.idTipoActividad == 5" class="my-3 btn btn-danger" (click)="descargarSemestral()">Descargar Plan Semestral</button>
                        </div>
                        <p *ngIf="Actividad.dinamica[0].dinamicaTabla.fechaRegistroFilasFormateado" class="mb-0 mt-2">
                            <small>Fecha de Respuestas: {{ Actividad.dinamica[0].dinamicaTabla.fechaRegistroFilasFormateado }}</small>
                        </p>
                    </div>
                    <div *ngIf="Actividad.idActividad == 3 && !respuestasAlt.respuestas.length">
                        <p class="h4">Se debe completar antes la actividad "Cuestionario Honey-Alonso de Estilos de aprendizaje -CHAEA" para poder continuar con esta actividad.</p> 
                    </div>
                </div>
                <div *ngIf="Actividad.idTipoActividad==2" class="my-2 py-4">
                    <!--div class="form-check mb-2" *ngFor="let item of Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.preguntas; let i = index">
                        <input class="form-check-input mr-2" type="checkbox" value="" id="pregunta{{i}}">
                        <label class="form-check-label" for="pregunta{{i}}">
                          {{ item.pregunta }}
                        </label>
                    </div-->
                    <div class="mb-2 border-bottom" *ngFor="let item of Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.preguntas; let i = index">
                        <input class="custom d-none" type="checkbox" id="pregunta{{i}}" [(ngModel)]="respuestasCheck[i].respuesta" />
                        <label for="pregunta{{i}}">
                            <p class="mb-0 font-weight-bold">{{ (i + 1) + ".- " + item.pregunta }}</p>
                        </label>
                    </div>
                    <div *ngIf="Actividad.idActividad != 3 || respuestasAlt.respuestas.length">
                        <button class="my-3 btn btn-danger mr-2" (click)="guardarActividad()">Guardar Actividad</button>
                        <button *ngIf="Actividad.idTipoActividad == 4" class="my-3 btn btn-danger" (click)="descargarSemanal()">Descargar Plan Semanal</button>
                        <button *ngIf="Actividad.idTipoActividad == 5" class="my-3 btn btn-danger" (click)="descargarSemestral()">Descargar Plan Semestral</button>
                    </div>
                    <p *ngIf="Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.fechaRegistroRespuestasFormateado" class="mb-0">
                        <small>Fecha de Respuestas: {{ Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.fechaRegistroRespuestasFormateado }}</small>
                    </p>
                </div>
                <div *ngIf="Actividad.idTipoActividad==2 && Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.resultadosClasificaciones.length" class="mb-4 pr-3">
                    <p class="font-weight-bold">RESULTADOS:</p>
                    <div *ngFor="let item of Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.resultadosClasificaciones" class="mb-4">
                        <div class="d-flex justify-content-between">
                            <p class="mb-0 small"><b>ESTILO {{ item.clasificacion }}</b></p>
                            <p class="mb-0 small">Nivel de preferencia: <span class="font-weight-bold" [ngClass]="{'ACTIVO' : 'text-orange2', 'REFLEXIVO' : 'text-green2', 'TEÓRICO' : 'text-purple2', 'PRAGMÁTICO' : 'text-blue2'}[item.clasificacion]">{{ item.preferenciaAlumno }}</span></p>
                        </div>
                        <div class="progress finbyz-fadeinup" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                            [ngClass]="{'ACTIVO' : 'bg-orange2', 'REFLEXIVO' : 'bg-green2', 'TEÓRICO' : 'bg-purple2', 'PRAGMÁTICO' : 'bg-blue2'}[item.clasificacion]"
                                role="progressbar" [ngStyle]="{'width': (item.totalClasificacion / 20 * 100) + '%' }">{{ item.totalClasificacion }} de Valoración</div>
                        </div>
                    </div>
                    <div>
                        <apx-chart [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [xaxis]="chartOptions.xaxis"
                        [stroke]= "chartOptions.stroke"
                        [dataLabels]= "chartOptions.dataLabels"
                        [tooltip]= "chartOptions.tooltip"
                        [plotOptions]= "chartOptions.plotOptions"
                        [fill]= "chartOptions.fill"
                        [colors]= "chartOptions.colors"
                        [yaxis]= "chartOptions.yaxis"
                        [markers]= "chartOptions.markers"
                        [title]="chartOptions.title"></apx-chart>
                    </div>
                </div>
                <div *ngIf="Actividad.idTipoActividad==3" class="my-2 py-2">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Valor</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.opciones">
                                <td class="font-weight-bold">{{ item.valor }}</td>
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.descripcion }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="Actividad.idTipoActividad==3" class="my-2 py-4">
                    <div class="row mx-0">
                        <div class="col-6 pb-2"></div>
                        <div class="col text-center py-2 text-light" style="background: #1e3882;border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let item of Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.opciones; let i = index">
                            <span class="font-weight-bold">{{ item.valor }}</span>
                        </div>
                    </div>
                    <div class="row mx-0" *ngFor="let item of Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.preguntas; let i = index">
                        <div class="col-6 py-3" style="border-bottom: 1px solid #ccc">{{ item.pregunta }}</div>
                        <div class="col text-center py-3" style="border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let itm of Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.opciones">
                            <div class="w-100 h-100" [ngClass]="respuestasOption[i].idDinamicaFrecuenciaRealizacionOpcion == itm.idDinamicaFrecuenciaRealizacionOpcion ? 'selected' : ''" (click)="selectOption(i, itm.idDinamicaFrecuenciaRealizacionOpcion)"></div>
                        </div>
                    </div>
                    <div *ngIf="Actividad.idActividad != 3 || respuestasAlt.respuestas.length">
                        <button class="my-3 btn btn-danger mr-2" (click)="guardarActividad()">Guardar Actividad</button>
                        <button *ngIf="Actividad.idTipoActividad == 4" class="my-3 btn btn-danger" (click)="descargarSemanal()">Descargar Plan Semanal</button>
                        <button *ngIf="Actividad.idTipoActividad == 5" class="my-3 btn btn-danger" (click)="descargarSemestral()">Descargar Plan Semestral</button>
                    </div>
                    <p *ngIf="Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.fechaRegistroRespuestasFormateado" class="mb-0">
                        <small>Fecha de Respuestas: {{ Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.fechaRegistroRespuestasFormateado }}</small>
                    </p>
                </div>
                <div *ngIf="Actividad.idTipoActividad==3 && Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.resultadoPuntaje.length" class="mb-4">
                    <p class="font-weight-bold">RESULTADOS:</p>
                    <div *ngFor="let item of Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.resultadoPuntaje" 
                        [ngClass]="{'bg-blue': item.clasificacionAlcanzada === 'EXCELENTE', 'bg-green' : item.clasificacionAlcanzada === 'BIEN', 'bg-yellow' : item.clasificacionAlcanzada === 'REGULAR', 'bg-orange' : item.clasificacionAlcanzada === 'MAL' }"
                        class="mb-4 text-white p-4 d-inline-block">
                        <p class="mb-0"><b>{{ item.clasificacionAlcanzada }}</b></p>
                        <p class="mb-0">Puntaje Total: {{ item.puntajeTotal }}</p>
                    </div>
                </div>
                <div *ngIf="Actividad.idTipoActividad==4" class="my-2 py-2">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Clave</th>
                                <th>Significado</th>
                                <th>Clave</th>
                                <th>Significado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of clavesTabla">
                                <td class="font-weight-bold">{{ item[0].clave }}</td>
                                <td>{{ item[0].significado }}</td>
                                <td class="font-weight-bold" *ngIf="item[1]">{{ item[1].clave }}</td>
                                <td *ngIf="item[1]">{{ item[1].significado }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="Actividad.idTipoActividad==4" class="my-2 py-4">
                    <div #actSemanal>
                        <div class="row mx-0">
                            <div class="col pb-2"></div>
                            <div class="col text-center py-2" style="background: #f5c2c9;border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let item of Actividad.dinamica[0].dinamicaConcentracionSemanal.diasSemana; let i = index">
                                <span class="font-weight-bold">{{ item.nombre }}</span>
                            </div>
                        </div>
                        <div class="row mx-0" *ngFor="let item of Actividad.dinamica[0].dinamicaConcentracionSemanal.rangosHoras; let i = index">
                            <div class="col py-3" style="border-bottom: 1px solid #ccc">{{ item.rango }}</div>
                            <div class="col text-center py-3 d-flex justify-content-center align-items-center" style="border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let itm of Actividad.dinamica[0].dinamicaConcentracionSemanal.diasSemana; let j = index">
                                <div class="w-100 h-100" style="cursor:pointer;font-size:14px" class="badge badge-pill" [ngClass]="respuestasOption[i][j] ? 'badge-info' : 'badge-light'" (click)="selectClaveSemanal(i, j, item.idRangoHoraDia, itm.idDiaSemana)">{{ respuestasOption[i][j] ? respuestasOption[i][j].clave : '---' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <div class="col-12 mb-2" *ngFor="let item of respuestasCheck; let i = index; trackBy: trackByFn">
                            <p>{{ item.pregunta }}</p>
                            <textarea class="form-control" [(ngModel)]="item.respuesta"></textarea>
                        </div>
                    </div>
                    <div *ngIf="Actividad.idActividad != 3 || respuestasAlt.respuestas.length">
                        <button class="my-3 btn btn-danger mr-2" (click)="guardarActividad()">Guardar Actividad</button>
                        <button *ngIf="Actividad.idTipoActividad == 4" class="my-3 btn btn-danger" (click)="descargarSemanal()">Descargar Plan Semanal</button>
                        <button *ngIf="Actividad.idTipoActividad == 5" class="my-3 btn btn-danger" (click)="descargarSemestral()">Descargar Plan Semestral</button>
                    </div>
                    <p *ngIf="Actividad.dinamica[0].dinamicaConcentracionSemanal.fechaRegistroRespuestasOpcionesFormateado" class="mb-0">
                        <small>Fecha de Respuestas: {{ Actividad.dinamica[0].dinamicaConcentracionSemanal.fechaRegistroRespuestasOpcionesFormateado }}</small>
                    </p>
                </div>
                <div *ngIf="Actividad.idTipoActividad==4 && Actividad.dinamica[0].dinamicaConcentracionSemanal.resultadoActividades.length" class="mb-4">
                    <p class="font-weight-bold">RESULTADOS:</p>
                    <div *ngFor="let item of Actividad.dinamica[0].dinamicaConcentracionSemanal.resultadoActividades" class="mb-4">
                        <p class="mb-0"><b>{{ item.actividadEstudiante }}</b></p>
                        <p>Total de horas: {{ item.totalHoras }}</p>
                    </div>
                </div>
                <div *ngIf="Actividad.idTipoActividad==5" class="my-2 py-2">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Clave</th>
                                <th>Significado</th>
                                <th>Clave</th>
                                <th>Significado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of clavesTabla">
                                <td class="font-weight-bold">{{ item[0].clave }}</td>
                                <td>{{ item[0].significado }}</td>
                                <td class="font-weight-bold" *ngIf="item[1]">{{ item[1].clave }}</td>
                                <td *ngIf="item[1]">{{ item[1].significado }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="Actividad.idTipoActividad==5" class="my-2 py-4">
                    <div #actSemestral>
                        <div class="row mx-0">
                            <div class="col pb-2"></div>
                            <div class="col text-center py-2" style="background: #f5c2c9;border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let item of Actividad.dinamica[0].dinamicaPlaneacionSemestral.diasSemana; let i = index">
                                <span class="font-weight-bold">{{ item.nombre }}</span>
                            </div>
                        </div>
                        <div class="row mx-0" *ngFor="let item of Actividad.dinamica[0].dinamicaPlaneacionSemestral.semanasSemestre; let i = index">
                            <div class="col py-3 text-center" style="border-bottom: 1px solid #ccc; font-size:17px">{{ item.semana }}</div>
                            <div class="col text-center py-3 d-flex justify-content-center align-items-center" style="border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let itm of Actividad.dinamica[0].dinamicaPlaneacionSemestral.diasSemana; let j = index">
                                <div class="w-100 h-100" style="cursor:pointer;font-size:14px" class="badge badge-pill" [ngClass]="respuestasOption[i][j] ? 'badge-info' : 'badge-light'" (click)="selectClaveSemanal(i, j, item.idSemanaSemestre, itm.idDiaSemana)">{{ respuestasOption[i][j] ? respuestasOption[i][j].clave : '---' }}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="Actividad.idActividad != 3 || respuestasAlt.respuestas.length">
                        <button class="my-3 btn btn-danger mr-2" (click)="guardarActividad()">Guardar Actividad</button>
                        <button *ngIf="Actividad.idTipoActividad == 4" class="my-3 btn btn-danger" (click)="descargarSemanal()">Descargar Plan Semanal</button>
                        <button *ngIf="Actividad.idTipoActividad == 5" class="my-3 btn btn-danger" (click)="descargarSemestral()">Descargar Plan Semestral</button>
                    </div>
                    <p *ngIf="Actividad.dinamica[0].dinamicaPlaneacionSemestral.fechaRegistroRespuestasOpcionesFormateado" class="mb-0">
                        <small>Fecha de Respuestas: {{ Actividad.dinamica[0].dinamicaPlaneacionSemestral.fechaRegistroRespuestasOpcionesFormateado }}</small>
                    </p>
                </div>
                <div [innerHtml]="Actividad.cierre2"></div>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='evaluacion'">
                <h5 class="mt-4 mb-4">Al Concluir:</h5>
                <div [innerHtml]="Actividad.alConcluir"></div>
                <h5 class="mt-4 mb-4">Para Saber Más:</h5>
                <div [innerHtml]="Actividad.paraSaberMas"></div>
                <div *ngIf="mensajeTutor.comentario">
                    <h5 class="mt-4 mb-4">Comentario del Tutor:</h5>
                    <textarea class="form-control" readonly>{{ mensajeTutor.comentario }}</textarea>
                    <p *ngIf="mensajeTutor.comentario"><small>{{ mensajeTutor.nombreComenta }} - {{ mensajeTutor.fechaRegistro }}</small></p>
                    <form [formGroup]="fprincipal">
                        <h5 class="mt-4 mb-4">Responder al Tutor:</h5>
                        <textarea formControlName="comentario" class="form-control" [(ngModel)]="mprincipal.comentario" [readonly]="mensajeAlumno.comentario"></textarea>
                        <button *ngIf="!mensajeAlumno.comentario" class="mt-2 btn btn-danger" (click)="guardar()" [disabled]="fprincipal.invalid">Guardar Mensaje</button>
                        <p *ngIf="mensajeAlumno.comentario"><small>{{ mensajeAlumno.nombreComenta }} - {{ mensajeAlumno.fechaRegistro }}</small></p>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="bg-secondary mt-4 py-3"></div>
        </div>
    </div>
</div>

<ng-template #claveSemanal let-modal>
    <div class="modal-hidden">
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card border-dark mb-0">
            <div class="card-header text-white text-center bg-dark">Seleccionar Clave</div>
            <div class="card-body text-dark">
                <form [formGroup]="claveForm">
                    <div class="row text-dark mb-4" *ngIf="Actividad.idTipoActividad==4">
                        <label>Clave: </label>
                        <select class="form-control" formControlName='id' (change)="seleccionClaveSemanal($event.target.value)">
                            <option *ngFor="let item of claveSemanalList" value="{{item.idClaveActividadSemanal}}">{{ item.clave }}</option>
                        </select>
                    </div>
                    <div class="row text-dark mb-4" *ngIf="Actividad.idTipoActividad==5">
                        <label>Clave: </label>
                        <select class="form-control" formControlName='id' (change)="seleccionClaveSemanal($event.target.value)">
                            <option *ngFor="let item of claveSemanalList" value="{{item.idClaveActividadSemestral}}">{{ item.clave }}</option>
                        </select>
                    </div>
                    <div class="row text-dark">
                        <label>Significado: </label>
                        <textarea class="form-control" formControlName="significado" readonly></textarea>
                    </div>
                    <div class="row text-dark pt-3">
                        <div class="col-lg-12">
                            <div class="buttonSelect text-center">
                                <button class="btn btn-danger mr-2" (click)="agregarClaveSemanal()">Agregar</button>
                                <button class="btn btn-dark mr-2" (click)="eliminarClaveSemanal()">Eliminar</button>
                                <button class="btn btn-light" (click)="modal.close()">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>