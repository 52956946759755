//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
//import { UsuarioService } from 'src/app/data-access/usuario.service';
import { CompetenciaService } from 'src/app/data-access/competencia.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';
import { DataTableDirective } from 'angular-datatables';
//import {NgbCalendar, NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-evaluacion',
    templateUrl: './competencia.component.html',
    styles: []
})
export class CompetenciaComponent implements OnInit {
    fprincipal: FormGroup;
    mprincipal;

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;
  
    constructor(
        private router: Router,
        public fb: FormBuilder,
        private competenciaService: CompetenciaService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
    ) {
        this.iniciarForm();
    }

    sUsuario;
    ngOnInit(): void {
        this.dtOptions = {
            language: myGlobals.dtLanguage()
        };
        this.addLista();
        this.sort();
        //this.sUsuario = this.usuarioService.getUser();
    }

    iniciarForm() {
        this.fprincipalIniciar();
    }

    fprincipalIniciar(){
        //Principal
        this.fprincipal = this.fb.group({
            idCompetencia: [0],
            nombre: ['', [Validators.required]],
            elementos: ['']
        });
        //this.patch();
        this.mprincipal = this.fprincipal;
    }

    Competencia;
    CompetenciaLista;
    addLista() {
        this.spinner.show();
        this.competenciaService.getData().toPromise()
        .then(data => {
            this.Competencia = data;
            //Asignamos el universo de items al listado, mediante una entidad de filtro
            this.CompetenciaLista = this.Competencia;
            this.rerender();
            this.spinner.hide();
        })
    }

    addFiltros() {
        //this.loadRoles();
    }

    recargar() {
        this.spinner.hide();
        this.modal.dismissAll();
        this.addLista();
    }
    
    rerender(): void {
        if(this.dtElement.dtInstance){
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.dtTrigger.next();
            });
        }else{
            this.dtTrigger.next();
        }
    }
    
    nuevo(content) {
        this.iniciarForm();
        //this.model.activo = 'S';
        this.modal.open(content, { size: 'xl' });
        //document.getElementById("mprincipal.cuenta").focus();
    }

    editar(content, modelo) {
        console.log(modelo);
        this.mprincipal = modelo;
        this.modal.open(content, { size: 'xl' });
        //document.getElementById("mprincipal.cuenta").focus();
    }

    eliminar(content, modelo) {
        console.log(modelo);
        this.mprincipal = modelo;
        this.modal.open(content, { size: 'md' });
        //document.getElementById("mprincipal.cuenta").focus();
    }

    guardar() {
        this.spinner.show();
        console.log(this.fprincipal.value);
        if (this.fprincipal.controls["idCompetencia"].value == undefined) {
            this.competenciaService.addData(this.fprincipal.value).subscribe(
                (res) => { this.recargar(); },
                (error) => {
                    //this.spinner.hide();
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
            });
        }
        else {
            this.competenciaService.updData(this.fprincipal.controls["idCompetencia"].value, this.fprincipal.value).subscribe(
                (res) => { this.recargar(); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
                });
        }

    }

    borrar() {
        this.spinner.show();
        console.log(this.fprincipal);
        if (this.fprincipal.controls["idCompetencia"].value != undefined) {
            this.competenciaService.dltData(this.fprincipal.controls["idCompetencia"].value).subscribe(
                (res) => { this.recargar(); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
                }
            );
        }
    } 

    formatDate(fecha) {
        var d = new Date(fecha);
        d.setHours(0, 0, 0, 0);
        return [
            ('0' + d.getDate()).slice(-2),
            ('0' + (d.getMonth() + 1)).slice(-2),
            d.getFullYear()
        ].join('/');
    }

    asc;
    sort() {
        const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

        const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
            v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

        // do the work...
        document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
            const table = th.closest('table');
            const tbody = table.querySelector('tbody');
            Array.from(tbody.querySelectorAll('tr'))
                .sort(
                    comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc)
                )
                .forEach(tr => tbody.appendChild(tr));
        })));
    }
}
