import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { SeccionService } from '../../../data-access/seccion.service';
import { UsuarioService } from '../../../data-access/usuario.service';

@Component({
  selector: 'app-seccion',
  templateUrl: './seccion.component.html',
  styles: [
  ]
})
export class SeccionComponent implements OnInit {
  forma: FormGroup;
  model;
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private usuarioService: UsuarioService,
    private seccionService: SeccionService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.addSeccion();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idSeccion: [0],
      nombre: [''],    
      activo: [true]
    })
    this.model = this.forma;
  }

  Secciones;
  addSeccion(){
    this.spinner.show();
    this.seccionService.getSeccion().toPromise()
    .then(data => {
      this.spinner.hide();
      this.Secciones = data;
    });
  }

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = true;
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    this.model = modelo;
    this.modal.open(content,{size:'xl'});
  }

  guardar() {
    this.spinner.show();
    console.log(this.forma.value);
    if (this.forma.controls["idSeccion"].value == undefined) {
      this.seccionService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();this.spinner.hide();},
        (error) => {console.log(error);this.spinner.hide();});
    }
    else {
      this.seccionService.updData(this.forma.controls["idSeccion"].value, this.forma.value).subscribe(
          (res) => {this.recargar();this.spinner.hide();},
          (error) => {console.log(error);this.spinner.hide();});
    }
  }
  
  recargar() {
    this.modal.dismissAll();
    this.addSeccion();
  }
}





