import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';

import { ShellModule } from './feature/shell/shell.module';
import { AdminModule } from './feature/admin/admin.module';
import { EstudianteModule } from './feature/estudiante/estudiante.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HoneyAlonsoComponent } from './feature/reportes/honey-alonso/honey-alonso.component';


@NgModule({
  declarations: [
    AppComponent,
    HoneyAlonsoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    //FormGroup,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgxSpinnerModule,
    ShellModule,
    AdminModule,
    EstudianteModule
  ],
  providers: [
    {provide: LocationStrategy, 
    useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
