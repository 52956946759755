import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './feature/shell/login/login.component';
import { RegistroComponent } from './feature/shell/registro/registro.component';

import { UsuarioComponent } from './feature/admin/usuario/usuario.component';
import { RolComponent } from './feature/admin/rol/rol.component';
import { AdministracionComponent } from './feature/admin/administracion/administracion.component';
import { GuiaComponent } from './feature/admin/guia/guia.component';
import { TutoresComponent } from './feature/admin/tutores/tutores.component';
import { EstudiantesComponent } from './feature/admin/estudiantes/estudiantes.component';
import { GuiaDetalleComponent } from './feature/admin/guia-detalle/guia-detalle.component';
import { ActividadComponent } from './feature/admin/actividad/actividad.component';
import { TemaComponent } from './feature/admin/tema/tema.component';
import { CompetenciaComponent } from './feature/admin/competencia/competencia.component';
import { ConceptoClaveComponent } from './feature/admin/concepto-clave/concepto-clave.component';
import { ClaveSemanalComponent } from './feature/admin/clave-semanal/clave-semanal.component';
import { ClaveSemestralComponent } from './feature/admin/clave-semestral/clave-semestral.component';
import { EvaluacionDetalleComponent } from './feature/admin/evaluacion-detalle/evaluacion-detalle.component';
import { EBienvenidaComponent } from './feature/estudiante/e-bienvenida/e-bienvenida.component';
import { EStatusComponent } from './feature/estudiante/e-status/e-status.component';
import { EStatusTutorComponent } from './feature/estudiante/e-status-tutor/e-status-tutor.component';
import { EStatusCoordinadorComponent } from './feature/estudiante/e-status-coordinador/e-status-coordinador.component';
import { EResumenComponent } from './feature/estudiante/e-resumen/e-resumen.component';
import { EAsignarActividadComponent } from './feature/estudiante/e-asignar-actividad/e-asignar-actividad.component';
import { ETemaComponent } from './feature/estudiante/e-tema/e-tema.component';
import { EModuloComponent } from './feature/estudiante/e-modulo/e-modulo.component';
import { EEvaluacionSeccionComponent } from './feature/estudiante/e-evaluacion-seccion/e-evaluacion-seccion.component';
import { EEvaluacionDetalleComponent } from './feature/estudiante/e-evaluacion-detalle/e-evaluacion-detalle.component';

import { GrupoComponent } from './feature/admin/grupo/grupo.component';
import { EInscripcionComponent } from './feature/estudiante/e-inscripcion/e-inscripcion.component';
import { InscripcionComponent } from './feature/admin/inscripcion/inscripcion.component';
import { RespuestaComponent } from './feature/admin/respuesta/respuesta.component';
import { TipoRespuestaComponent } from './feature/admin/tipo-respuesta/tipo-respuesta.component';

import { PerfilComponent } from './feature/shell/perfil/perfil.component';
import { PreguntaComponent } from './feature/admin/pregunta/pregunta.component';
import { SeccionComponent } from './feature/admin/seccion/seccion.component';
import { HoneyAlonsoComponent } from './feature/reportes/honey-alonso/honey-alonso.component';
import { ContraInvitadoComponent } from './feature/admin/contra-invitado/contra-invitado.component';
import { ActividadesInvitadoComponent } from './feature/admin/actividades-invitado/actividades-invitado.component';


const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'login/:id', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'registro', component: RegistroComponent }, 
  { path: 'perfil', component: PerfilComponent }, 
  { path: 'usuario', component: UsuarioComponent }, 
  { path: 'rol', component: RolComponent }, 
  { path: 'grupo', component: GrupoComponent }, 
  { path: 'seccion', component: SeccionComponent }, 
  { path: 'respuesta', component: RespuestaComponent }, 
  { path: 'pregunta', component: PreguntaComponent }, 
  { path: 'tipo-respuesta', component: TipoRespuestaComponent }, 
  { path: 'inscripcion', component: InscripcionComponent }, 
  { path: 'administracion', component: AdministracionComponent }, 
  { path: 'guia', component: GuiaComponent }, 
  { path: 'tutores', component: TutoresComponent }, 
  { path: 'estudiantes', component: EstudiantesComponent }, 
  { path: 'guia-detalle', component: GuiaDetalleComponent }, 
  { path: 'actividad', component: ActividadComponent }, 
  { path: 'tema', component: TemaComponent }, 
  { path: 'competencia', component: CompetenciaComponent }, 
  { path: 'concepto-clave', component: ConceptoClaveComponent }, 
  { path: 'clave-semanal', component: ClaveSemanalComponent }, 
  { path: 'clave-semestral', component: ClaveSemestralComponent }, 
  { path: 'evaluacion-detalle', component: EvaluacionDetalleComponent },
  { path: 'e-inscripcion', component: EInscripcionComponent },
  { path: 'e-bienvenida', component: EBienvenidaComponent }, 
  { path: 'e-status', component: EStatusComponent }, 
  { path: 'e-status-tutor', component: EStatusTutorComponent }, 
  { path: 'e-status-coordinador', component: EStatusCoordinadorComponent },
  { path: 'e-resumen', component: EResumenComponent }, 
  { path: 'e-asignar-actividad', component: EAsignarActividadComponent },  
  { path: 'e-tema', component: ETemaComponent }, 
  { path: 'e-modulo', component: EModuloComponent }, 
  { path: 'e-evaluacion-seccion', component: EEvaluacionSeccionComponent },  
  { path: 'e-evaluacion-detalle', component: EEvaluacionDetalleComponent },
  { path: 'reporte-ha', component: HoneyAlonsoComponent },
  { path: 'contra-invitado', component: ContraInvitadoComponent },
  { path: 'actividades-invitado', component: ActividadesInvitadoComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
