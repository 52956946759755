<div class="container-fluid  py-3">

    <div class="row justify-content-center py-1">
      <div class="col-sm-12 col-md-7 content-registro shadow">
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-8">
            <div class="text-center">
            <h4 class="text-danger pt-1">Inscripción</h4>
            </div>
           <form [formGroup]="forma">

              <div class="form-group form-row">
                <div class="col-sm-12 col-md-6">
                <input class="form-control" type="hidden" formControlName="idUsuario" [(ngModel)]="model.idUsuario">
                <input class="form-control" type="hidden" formControlName="comprobante" [(ngModel)]="model.comprobante">  
                <label for="idIdioma"><span class="text-danger">*</span>Idioma</label>

                <select class="form-control" formControlName="idIdioma"
                [(ngModel)]="model.idIdioma">
                  <!--option [ngValue]="0">--Seleccionar--</option-->
                  <option *ngFor="let item of Idiomas"
                  [ngValue]="item.idIdioma">
                    {{ item.descripcion }}
                    </option>
                </select>
                <div class="text-danger" *ngIf="forma.controls['idIdioma'].touched && forma.controls['idIdioma'].errors?.required">El idioma es requerido</div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <label for="nivel">Nivel</label>
                  <input class="form-control" type="text" formControlName="nivel" [(ngModel)]="model.nivel">
                  <div class="text-danger" *ngIf="forma.controls['nivel'].touched && forma.controls['nivel'].errors?.pattern">Ingresa solo números</div>
                </div>
              </div>
              <div class="form-group form-row">
                <div class="col-md-6">
                    <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" style="display:none;">
                    <button type="button" class="btn btn-danger" (click)="file.click()">Comprobante de pago</button>
                  </div>
                  <div class="col-md-6 text-left mt-1">
                    <p><a class="vimg" href="/assets/img/{{ model.comprobante }}" target="_blank">Ver comprobante</a></p>
                  </div>                                    
              </div>
             <!--div class="form-group">
              <label for="nombre"><span class="text-danger">*</span>Nombre</label>
              <input class="form-control" type="text" formControlName="nombre" [(ngModel)]="model.nombre">
              <div class="text-danger" *ngIf="forma.controls['nombre'].touched && forma.controls['nombre'].errors?.required">El nombre es requerido</div>
              <div class="text-danger" *ngIf="forma.controls['nombre'].touched && forma.controls['nombre'].errors?.pattern">Ingresa solo letras</div>
            </div-->
  
            <div class="text-center">
              <button class="btn btn-danger" (click)="cancelar()">Cancelar</button>&nbsp;
              <button type="submit" class="btn btn-danger my-2" (click)="guardar()" [disabled]="forma.invalid">Inscribirse</button>
             </div>
  
           </form>
          </div>
        </div>
    </div>
    </div>
  </div>