//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
//import { UsuarioService } from 'src/app/data-access/usuario.service';
import { ClaveSemestralService } from 'src/app/data-access/claveSemestral.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';
import { UsuarioService } from 'src/app/data-access/usuario.service';
//import {NgbCalendar, NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-evaluacion',
    templateUrl: './administracion.component.html',
    styles: []
})
export class AdministracionComponent implements OnInit {
  
    constructor(
        private router: Router,
        public fb: FormBuilder,
        private claveSemestralService: ClaveSemestralService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
        public us: UsuarioService
    ) {
        
    }

    ngOnInit(): void {
        
    }

    irGuia(){
        this.router.navigate(['/guia']);
    }

    irTutores(){
        this.router.navigate(['/tutores']);
    }

    irEstudiantes(){
        this.router.navigate(['/estudiantes']);
    }

    irReportes(){
        this.router.navigate(['/reporte-ha']);
    }
    
}
