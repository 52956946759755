<div class="container align-items-center flex-column">
    <div class="row mt-4" *ngIf="!usuarioService.isAlumno() && !usuarioService.adm() && !usuarioService.coordPIT()">
        <div class="col-12">
            <!--p class="text-danger"><a class="text-danger font-weight-bold" href="/e-bienvenida">Inicio</a> > {{ Modulo.nombre }}</p-->
            <div id="breadcrumbs">
                <ul class="steps pl-0 mb-0">
                    <li class="step">                        
                        <a [routerLink]="['/e-status-tutor']">
                            Inicio
                        </a>
                    </li>
                    <li class="step inactive">
                        <a >
                            {{ Cuenta.nombres }} {{ Cuenta.aPaterno }} {{ Cuenta.aMaterno }}
                        </a>
                    </li>
                </ul>                
            </div>
        </div>
    </div>
</div>
<div class="pit-container">
    <div class="pit-card">
        <div class="pit-card-info">
            <div class="pit-card-name">
                <span class="pit-card-welcome">Hola!</span>
                <h2>{{ Cuenta.nombres }}<br>{{ Cuenta.aPaterno }} {{ Cuenta.aMaterno }}</h2>
                <h4>{{ Cuenta.perfil }}</h4>
            </div>
            <div class="pit-card-data">
                <div class="pit-card-data-content">
                    <div class="pit-card-data-row">
                        <h3>Cuenta</h3>
                        <p>{{ Cuenta.cuenta }}</p>
                    </div>
                    <div class="pit-card-data-row">
                        <h3>Carrera</h3>
                        <p>{{ Cuenta.carrera }}</p>
                    </div>
                    <!--div class="pit-card-data-row">
                        <h3>Coordinación</h3>
                        <p>{{ Cuenta.coordinacion }}</p>
                    </div-->
                    <div class="pit-card-data-row">
                        <h3>Tutor</h3>
                        <p>{{ Cuenta.tutor ? Cuenta.tutor.nombres + ' ' + Cuenta.tutor.aPaterno + ' ' + Cuenta.tutor.aMaterno : '' }}</p>
                    </div>
                    <div class="pit-card-data-row">
                        <h3>Periodo</h3>
                        <p>{{ Cuenta.periodo }}</p>
                    </div>
                    <div class="pit-card-data-row">
                        <h3>Año</h3>
                        <p>{{ Cuenta.anio }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let item of Guia" class="pit-card-progress">
            <div class="pit-card-progress-info">
                <h2 class="text-white"><a class="text-white" [routerLink]="" (click)="irGuia(item.idGuia)">{{ item.nombre }}
                    <fa-icon [icon]="['fas','long-arrow-alt-right']" class="text-white"></fa-icon></a></h2>
                <p>{{ item.status ? item.status.porcentajeTotalRealizado : '0%' }} <span>({{ item.status ? item.status.numeroActividadesCompletadas : 0 }} de {{ item.status ? item.status.numeroTotalActividades : 0 }})</span></p>
            </div>
            <div class="pit-card-bar">
                <div class="pit-card-bar-fill" [ngStyle]="{'width': item.status ? item.status.porcentajeTotalRealizado : '0%'}"></div>
            </div>
        </div>
    </div>
</div>