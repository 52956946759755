import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { GuiaService } from 'src/app/data-access/guia.service';
import { EvalSeccionService } from 'src/app/data-access/evalseccion.service';
import { EvalPreguntaService } from 'src/app/data-access/evalpregunta.service';
import { SeccionService } from '../../../data-access/seccion.service';
import { PreguntaService } from '../../../data-access/pregunta.service';
import { TipoRespuestaService } from '../../../data-access/tipoRespuesta.service';
import { RespuestaService } from '../../../data-access/respuesta.service';
import { ResultadoService } from '../../../data-access/resultado.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import * as myGlobals from 'src/app/data-access/util';

@Component({
  selector: 'app-e-evaluacion-detalle',
  templateUrl: './e-evaluacion-detalle.component.html',
  styles: [
  ]
})
export class EEvaluacionDetalleComponent implements OnInit {

  constructor(
    private router: Router,
    public fb: FormBuilder,
    private usuarioService: UsuarioService,
    private seccionService: SeccionService,
    private preguntaService: PreguntaService,
    private tipoRespuestaService: TipoRespuestaService,
    private respuestaService: RespuestaService,
    private evalSeccionService: EvalSeccionService,
    private evalPreguntaService: EvalPreguntaService,
    private resultadoService: ResultadoService,
    private guiaService: GuiaService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal,
  ) {}

  sUsuario;
  idEvaluacion;
  ngOnInit(): void {
    let evaluacion = JSON.parse(localStorage.getItem('evaluacion') || '{}') || [];
    this.idEvaluacion = evaluacion.idEvaluacion;
    this.evaluacionNombre = evaluacion.nombre;
    //console.log()
    this.addEvalSeccion();
    this.sort();
    /*
    this.activatedRoute.params.subscribe(params => {
      //this.spinner.show();
      this.idEvaluacion = Number(params['id']);
      this.addEvalSeccion();
      //this.addFiltros();
      this.sort();      
    });
    */
  }

  evaluacionNombre;
  EvalSeccion;
  addEvalSeccion() {
    //this.spinner.show();    
    this.evalSeccionService.getEvalSeccion().toPromise()
      .then(data => {
        this.EvalSeccion = data;
        console.log(this.EvalSeccion);
        this.addEvalPregunta();
        //this.spinner.hide();
      }); 
  }
  
  EvalPregunta;
  addEvalPregunta() {
    //this.spinner.show();
    this.evalPreguntaService.getEvalPregunta().toPromise()
      .then(data => {
        this.EvalPregunta = data;
        this.loadTree();
        //this.spinner.hide();
      }); 
  }
  
  Evaluacion;
  loadTree(){
        /*
         * Armar arbol con Lineas, Objetivo y metas
         * planLista, objetivoLista, metaLista
         */
        var result = this.EvalSeccion;
        //var idx;
        //Lineas
        for (var i = 0; i < this.EvalSeccion.length; i++) {
          var prop = this.EvalSeccion[i].idEvalSeccion;
          var child;

          result[i].children = [];
          //Objetivos
          for (var j = 0; j < this.EvalPregunta.length; j++) {
            var parent = this.EvalPregunta[j].idEvalSeccion;

            if (parent == prop) {
              child = this.EvalPregunta[j];
              //child["idTipoRespuesta"] = 2;
              child.respuestaOpciones = JSON.parse(child.respuestaOpciones || '{}') || [];
              //return JSON.parse(localStorage.getItem('user') || '{}') || [];
              result[i].children.push(child);
            }
          }          
        }
        this.Evaluacion = result;
        //console.log(result);    
  }
  

  ultimo;
  siguiente() {
    //this.router.navigate(['./e-evaluacion-detalle/', Number(this.idSeccion)+1]);
    /*
    this.idSeccion++;
    this.EncuestaSeccion = this.EncuestaDetalle.filter(x => x.idSeccion == this.idSeccion);

    this.SeccionNumero = this.EncuestaSeccion[0].seccionNumero;
    this.SeccionNombre = this.EncuestaSeccion[0].descripcion;
    */
    //let idMeta = this.fmeta.controls["idMeta"].value;
    this.primero = false;
    this.ultimo = false;
    let next = true;//false;



      this.spinner.show();
      //if (this.fseccion.controls["idEvalSeccion"].value == undefined) {
        this.resultadoService.addData(null).subscribe(
          (res) => { 
            //this.recargar(); 
          },
          (error) => {
            //this.spinner.hide();
            console.log(error);
            this.toastr.error(error.error.message);
            this.spinner.hide();
          });
          /*
      }
      else {
        this.evalSeccionService.updData(this.fseccion.controls["idEvalSeccion"].value, this.fseccion.value).subscribe(
          (res) => { this.recargar(); },
          (error) => {
            console.log(error);
            this.toastr.error(error.error.message);
            this.spinner.hide();
          });
      }
      */


    /*
    for (let i = 0; i < this.MetaLista.length; i++) {
      if (next) {
        this.getTmpVal();
        this.mmeta = this.MetaLista[i];
        this.setTmpVal();
      }
      next = false;
      if (this.MetaLista[i].idMeta == idMeta) {
        next = true;
      }
    }
    if (this.MetaLista[this.MetaLista.length - 1].idMeta == this.mmeta.idMeta) { this.ultimo = true;}
    */
  }

  primero;
  anterior() {
    //let idMeta = this.fmeta.controls["idMeta"].value;
    this.primero = false;
    this.ultimo = false;
    let prev = true;//false;
    /*
    for (let i = this.MetaLista.length - 1; i >= 0; i--) {
      if (prev) {
        this.getTmpVal();
        this.mmeta = this.MetaLista[i];
        this.setTmpVal();
      }
      prev = false;
      if (this.MetaLista[i].idMeta == idMeta) {
        prev = true;
      }
    }
    
    if (this.MetaLista[0].idMeta == this.mmeta.idMeta) { this.primero = true; }
    */
  }  

  formatDate(fecha) {
    var d = new Date(fecha);
    d.setHours(0, 0, 0, 0);
    return [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear()
    ].join('/');
  }

  asc;
  sort() {
    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

    const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
      v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
    )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    // do the work...
    document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
      const table = th.closest('table');
      const tbody = table.querySelector('tbody');
      Array.from(tbody.querySelectorAll('tr'))
        .sort(
          comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc)
        )
        .forEach(tr => tbody.appendChild(tr));
    })));
  }



}

