//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef, TemplateRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { GuiaService } from 'src/app/data-access/guia.service';
import { ModuloService } from 'src/app/data-access/modulo.service';
import { TemaService } from 'src/app/data-access/tema.service';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';

@Component({
  selector: 'app-e-evaluacion',
  templateUrl: './e-asignar-actividad.component.html',
  styles: [
  ]
})
export class EAsignarActividadComponent implements OnInit {
  
    Cuenta;
    CuentaAdmin;

    isAdmin = false;

    Actividad;

    AlumnosLista;
    marcados = false;

    constructor(
        private router: Router,
        public fb: FormBuilder,
        private guiaService: GuiaService,
        private moduloService: ModuloService,
        private temaService: TemaService,
        private usuarioService: UsuarioService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
    ) {}

    @ViewChild('actividadModal', { read: TemplateRef }) actividadModal:TemplateRef<any>;

    ngOnInit(): void {
        this.Cuenta = JSON.parse(localStorage.getItem('uCuentaTutor') || 'null') || '';
        if(!this.Cuenta){
            this.Cuenta = JSON.parse(localStorage.getItem('uCuenta') || '{}') || {};
            if(this.Cuenta.idPerfil == 1){
                this.router.navigate(['/administracion']); 
            }else if(this.Cuenta.idPerfil == 99){
                this.router.navigate(['/e-status']);
            }
        }else{
            this.CuentaAdmin = JSON.parse(localStorage.getItem('uCuenta') || '{}') || {};
            this.isAdmin = true;
        }
        this.addLista();
    }

    Guia;
    addLista() {
        this.spinner.show();
        this.guiaService.getData().toPromise().then(data => {
            this.Guia = data;
            this.spinner.hide();
        });
    }

    temas(modelo) {
        this.spinner.show();
        let temas;
        this.moduloService.getDataById(modelo.idModulo).toPromise().then(data => {
            temas = data;
            modelo.temas = temas.temas;
            modelo.expand = true;
            this.spinner.hide();
            console.log(this.Guia);
        }); 
    }

    temasCollapse(modelo) {
        modelo.temas = [];
        modelo.expand = false;
    }

    actividades(modelo) {
        this.spinner.show();
        let activ;
        this.temaService.getDataById(modelo.idTema).toPromise().then(data => {
            activ = data;
            modelo.actividades = activ.actividades;
            modelo.expand = true;
            this.spinner.hide();
        }); 
    }

    actividadesCollapse(modelo) {
        modelo.actividades = [];
        modelo.expand = false;
    }

    verEstudiantes(act){
        this.spinner.show();
        this.Actividad = act;
        if(this.usuarioService.coordAcad()){
            this.usuarioService.statusAsignacionActividadEstudiantesCarrera(this.Cuenta.idUsuario, act.idActividad).toPromise().then(dt => {
                this.AlumnosLista = dt;
                this.marcados = false;
                this.modal.open(this.actividadModal, { size: 'lg' });
                this.spinner.hide();
            });
        }else{
            this.usuarioService.statusAsignacionActividadEstudiantes(this.Cuenta.idUsuario, act.idActividad).toPromise().then(dt => {
                this.AlumnosLista = dt;
                this.marcados = false;
                this.modal.open(this.actividadModal, { size: 'lg' });
                this.spinner.hide();
            });
        }
        
    }

    marcarTodos(){
        this.AlumnosLista.estudiantes.forEach(element => {
            element.asignado = true;
        });
        this.marcados = true;
    }

    desmarcarTodos(){
        this.AlumnosLista.estudiantes.forEach(element => {
            element.asignado = false;
        });
        this.marcados = false;
    }

    toggleSelection(item){
        item.asignado = !item.asignado;
    }

    asignar(){
        this.spinner.show();
        let arrTrue = [];
        let arrFalse = [];

        this.AlumnosLista.estudiantes.forEach(element => {
            if(element.asignado){
                arrTrue.push(element.idAlumno);
            }else{
                arrFalse.push(element.idAlumno);
            }
        });

        let finishArrTrue = Promise.resolve(0); 
        let finishArrFalse = Promise.resolve(0); 

        if(arrTrue.length){
            finishArrTrue = this.usuarioService.asignarActividadGlobalEstudiantes(this.CuentaAdmin ? this.CuentaAdmin.idUsuario : this.Cuenta.idUsuario, this.Actividad.idActividad, arrTrue).toPromise();
        }

        if(arrFalse.length){
            finishArrFalse = this.usuarioService.desasignarActividadGlobalEstudiantes(this.CuentaAdmin ? this.CuentaAdmin.idUsuario : this.Cuenta.idUsuario, this.Actividad.idActividad, arrFalse).toPromise();
        }

        Promise.all([finishArrTrue, finishArrFalse]).then((values) => {
            this.spinner.hide();
            this.modal.dismissAll();
            this.toastr.success('Se ha actualizado la actividad para los estudiantes', 'Actividad Actualizada', {positionClass: 'toast-bottom-left'});
        });
        
    }

}
