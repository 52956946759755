import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';


@Injectable({
  providedIn: 'root'
})
export class FileService {
  user = [];
  constructor(private http: HttpClient) {
  }

  uploadImg(formData) {
    return this.http.post(config.apiUrl + '/upload/', formData);
    //return this.http.post('https://localhost:44345/api/upload', formData)
  }

    

}