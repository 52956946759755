import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-e-evaluacion-seccion',
  templateUrl: './e-evaluacion-seccion.component.html',
  styles: [
  ]
})
export class EEvaluacionSeccionComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.addLista();
  }

  actRows;
  Secciones;
  addLista(){
    this.Secciones=[
      {"idSeccion":1,"descripcion":"El curso"},
      {"idSeccion":2,"descripcion":"El docente"},
      {"idSeccion":3,"descripcion":"La coordinación de idiomas"},
      {"idSeccion":4,"descripcion":"La plataforma en línea"}
    ];


    this.actRows = [];
    this.Secciones.forEach((item, index) => {
      if(index % 4 == 0) {
          let row = [];
          row.push(item);
          this.actRows.push(row);
      } else {
          this.actRows[this.actRows.length - 1].push(item);
      }
    });

  }

  gotoEncuesta(item){
    //this.modal.dismissAll();
    this.router.navigate(['./e-evaluacion-detalle/', item.idSeccion]);
  }  

}
