import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from '../../../data-access/usuario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styles: [
  ]
})
export class UsuarioComponent implements OnInit {
  forma: FormGroup;
  filtros: FormGroup;
  model;
  mf;
  //features;
  constructor(
    public fb: FormBuilder,
    private usuarioService: UsuarioService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.addFiltros();
    this.addUsuarios();
    this.sort();
  }

  ngAfterViewInit(){
  }

  iniciarForm() {
    this.forma = this.fb.group({
      cuenta: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      nombres: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
      aPaterno: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
      aMaterno: ['', Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)],
      correo: ['', [Validators.required, Validators.email]],
      telefonoPersonal: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]],
      telefonoCasa: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]],
      clave: ['', Validators.required],
      confirmaclave: ['', Validators.required],
      idCarrera: [0, [Validators.required]],
      semestre: [0, [Validators.required, Validators.pattern("^[1-9]*$"),
      Validators.minLength(1), Validators.maxLength(2)]],
      idUsuario: [0],
      idRol: [0],
      activo: [true],
    });
    this.model = this.forma;

    //Filtro
    this.filtros = this.fb.group({
      //usuario_tipoId: 0,
      //perfilId: 0,
      idRol: [0],
      activo: ['S']
    });
    this.mf = this.filtros;
  }

  Usuario;
  Usuarios;
  //UsuarioLista; 
  addUsuarios(){
    this.spinner.show();
    this.usuarioService.getUsuarioAll().toPromise()
    .then(data => {
      this.Usuario = data;
      //console.log(this.Usuario);
      //Asignamos el universo de items al listado, mediante una entidad de filtro
      this.Usuarios = this.Usuario;
      this.filtrar();
      this.spinner.hide();
    })
  }

  /*
  TipoUsuario;
  TipoUsuarioLista;
  addTipoUsuario(){
    this.usuarioService.getTipoUsuario().toPromise()
    .then(data => {
      this.TipoUsuario = data;
      this.TipoUsuarioLista = this.TipoUsuario;
    })
  }
  */

  //Roles;
  //TipoUsuarioLista;
  /*
  addRol(){
    this.usuarioService.getRol().toPromise()
    .then(data => {
      this.Roles = data;
      //this.TipoUsuarioLista = this.TipoUsuario;
    })
  }

  Perfil;
  PerfilLista;
  mfPerfilLista;
  addPerfil(){
    this.usuarioService.getPerfil().toPromise()
      .then(data => {
        this.Perfil = data;
        this.PerfilLista = this.Perfil;
        this.mfPerfilLista = this.Perfil;
      })
  }
  */
  Rol;
  RolLista;
  mfRolLista;
  addRol(){
    this.usuarioService.getRol().toPromise()
      .then(data => {
        this.Rol = data;
        this.RolLista = this.Rol;
        this.mfRolLista = this.Rol;
      })
  }    

  addFiltros() {
    //this.addColegios();
    //this.addTipoUsuario();
    //this.addPerfil();
    this.addRol();
  }
  /*
  oColegio;
  addColegios(){
    this.usuarioService.getColegioListado()
    .toPromise()
      .then(data => {
        this.oColegio = data;
      })
  }
  */

  //Filtros en Pantalla
  /*
  mfPerfil(){
    //alert(this.mf.usuario_tipoId);
    this.mfPerfilLista = this.Perfil.filter(x => x.usuario_tipoId === Number(this.mf.usuario_tipoId));
    this.mf.perfilId=0;
    this.mf.rolId=0;
    this.filtrar();
  }
  */
  
  mfRol(){
    this.mfRolLista = this.Rol.filter(x => x.perfilId === Number(this.mf.perfilId));  
    this.mf.rolId=0;
    this.filtrar();
  }

  //Filtros en Modal
  /*
  fPerfil(){
    this.PerfilLista = this.Perfil.filter(x => x.usuario_tipoId === Number(this.model.usuario_tipoId));
    this.model.perfilId=0;
    this.model.rolId=0;
  }
  */
  fRol(){
    this.RolLista = this.Rol.filter(x => x.perfilId === Number(this.model.perfilId));  
    this.model.rolId=0;
  }  
/*
  setPerfil(){
    this.PerfilLista = this.Perfil.filter(x => x.usuario_tipoId === Number(this.model.usuario_tipoId));
  }
  */
  setRol(){
    this.RolLista = this.Rol.filter(x => x.perfilId === Number(this.model.perfilId));  
  }    
  

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }


  editar(content, modelo) {
    this.model = modelo;
    //console.log(this.model);
    //this.setPerfil();
    this.setRol();
    this.modal.open(content, { size: 'xl' });
  }

    /*
     * Colocamos el valor del colegio al hacer focus, ya que es el único momento
     * en el que el ngbmodal nos indica que ya están listos los controles (en este caso
     * ngbtypedhead) para su acceso por medio de la propiedad ngbAutofocus en algún control
    */  
   /*
  setEditColegio(){
    let valIn = this.oColegio.find(x => x.colegioId === Number(this.model.colegioId));
    this.forma.controls["colegioIdCI"].setValue(valIn);
  }
*/
  guardar() {
    this.spinner.show();
    if (this.forma.controls["idUsuario"].value == undefined) {
      this.usuarioService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {
          this.spinner.hide();
          console.log(error);
        });
    }
    else {
      this.usuarioService.updData(this.forma.controls["idUsuario"].value, this.forma.value).subscribe(
          (res) => {this.recargar();},
        (error) => {
          this.spinner.hide();
          console.log(error);
        });
    }
  }

  recargar() {
    this.modal.dismissAll();
    this.addUsuarios();
    /*
    this.mf.usuario_tipoId='';
    this.mf.perfilId='';
    this.mf.rolId='';
    this.mf.activo= '';
    */
  }

  Filtro; 
  filtrar() {
    //Establecemos los elementos totales de la lista
    this.Filtro = this.Usuario;
    /*
    if (this.mf.usuario_tipoId != 0 && this.mf.usuario_tipoId !== undefined) {
      this.Filtro = this.Filtro
        .filter(x => x.usuario_tipoId === Number(this.mf.usuario_tipoId ));
    }

    if (this.mf.perfilId != 0 && this.mf.perfilId !== undefined) {
      this.Filtro = this.Filtro
        .filter(x => x.perfilId === Number(this.mf.perfilId ));
    }
    */

    if (this.mf.idRol != 0 && this.mf.idRol!== undefined) {
      this.Filtro = this.Filtro
        .filter(x => x.idRol === Number(this.mf.idRol));
    }
    
    if(this.mf.activo!='' && this.mf.activo!==undefined){
      //alert(this.mf.activo);
      this.Filtro = this.Filtro
      .filter(x => x.activo.toString() === this.mf.activo.toString());
    }

    this.Usuarios = this.Filtro;
  }
/*
  colegioIdCI;
  //Buscador intuitiva
  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  selectedColegio(item) {
    this.model.colegioId = item.item.colegioId;
  }
  blurColegio() {
    this.model.colegioId = this.model.colegioIdCI.colegioId;
  }

  //public modelo: any;

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focus$),
    merge(this.click$.pipe(filter(() =>  this.instance && !this.instance.isPopupOpen()))),
    map(term => (term === '' ? this.oColegio
      : this.oColegio.filter(v => v.nombre.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
  );

  formatter = (x: {nombre: string}) => x.nombre;
*/
  asc;
  sort(){
    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

    const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    // do the work...
    document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
      const table = th.closest('table');
      const tbody = table.querySelector('tbody');
      Array.from(tbody.querySelectorAll('tr'))
        .sort(
          comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc)
          )
        .forEach(tr => tbody.appendChild(tr) );
    })));

  }
}
