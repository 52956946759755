<!--Contenedor-->
<div class="container align-items-center flex-column">

    <!--Encabezado-->
    <div class="text-center pt-2">
      <h4 class="title-section">Inscripciones</h4>
    </div>
  
    <!--Listado-->
    <div class="row justify-content-between py-2">
      <div class="col-md-4 col-sm-12">
          <h5 class="title-subsection">&nbsp;</h5>
      </div>
      <div class="col-md-4 col-sm-12 text-right">
          <button class="btn btn-danger" (click)="nuevo(content)">Agregar</button>
      </div>
    </div>
  
    <table class="table table-striped text-center table-fixed">
        <thead class="bg-secondary text-white">
        <tr>
          <th scope="col" class="encabezado">#</th>
          <!--th scope="col" class="encabezado">idInscripcion</th-->
          <th scope="col" class="encabezado">Usuario</th>
          <th scope="col" class="encabezado">Año</th>
          <th scope="col" class="encabezado">Período</th>
          <th scope="col" class="encabezado">Grupo</th>
          <th scope="col" class="encabezado">Idioma</th>
          <!--th scope="col" class="encabezado">nivel</th-->
          <th scope="col" class="encabezado">Comprobante</th>
          <th scope="col" class="encabezado">Validado</th>
          <th scope="col" class="encabezado">Acciones</th>          
        </tr>
      </thead>
      <tbody>
        <tr #item *ngFor="let item of Inscripciones; let i = index">
          <td scope="row">{{ i+1 }}</td>
          <!--td class="w-10">{{ item.idInscripcion }}</td-->
          <td class="w-10">{{ item.nombres }} {{ item.aPaterno }} {{ item.aMaterno }}</td>
          <td class="w-10">{{ item.anio }}</td>
          <td class="w-10">{{ item.periodoNombre }}</td>
          <td class="w-10">{{ item.grupoNombre }}</td>
          <td class="w-10">{{ item.idiomaDescripcion }}</td>
          <!--td class="w-10">{{ item.nivel }}</td-->
          <td class="w-10">
              <a class="vimg" href="/assets/img/{{ item.comprobante }}" target="_blank">ver</a>
            </td>
          <td>
            {{ (item.validado)?"Si":"No" }}
          </td>
          <td>
            <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
              <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot class="tfoot-ibero text-center bg-secondary text-white">
        <tr>
          <td colspan="12">{{ (Inscripciones)?.length }} Registros</td>
        </tr>
      </tfoot>
    </table>
  </div>
  
    <!--Formulario-->
  
    <ng-template #content let-modal>
      <div class="modal-hidden">
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card border-dark mb-0">
          <div class="card-header text-white text-center bg-dark">Inscripción</div>
          <div class="card-body text-dark">
    
            <div class=" card-body row">
              <div class="col-12">
                <form [formGroup]="forma">
                  <div class="row text-dark">
                    <input class="form-control" type="hidden" formControlName="idInscripcion"
                           [(ngModel)]="model.idInscripcion">
                    <input class="form-control" type="hidden" formControlName="idUsuario"
                          [(ngModel)]="model.idUsuario"> 
                   <input class="form-control" type="hidden" formControlName="idIdioma"
                         [(ngModel)]="model.idIdioma">
                   <input class="form-control" type="hidden" formControlName="idPeriodo"
                         [(ngModel)]="model.idPeriodo">
                  </div>
  
                  <!--div class="form-group form-row">
                    <label for="idTipoRespuesta">Respuesta</label>
                    <select class="form-control" formControlName="idTipoRespuesta"
                    [(ngModel)]="model.idTipoRespuesta">
                      <option [ngValue]="0">--Seleccionar--</option>
                      <option *ngFor="let item of TipoRespuestas"
                      [ngValue]="item.idTipoRespuesta">
                        {{ item.descripcion }}
                        </option>
                    </select>  
                  </div-->
  
                  <div class="form-group form-row">
                    <label for="nombreCompleto">Nombre:</label>
                    <input class="form-control" type="text" formControlName="nombreCompleto"
                           [(ngModel)]="model.nombreCompleto" [readonly]="true">
                  </div>
                  <div class="form-group form-row">
                    <div class="col-sm-12 col-md-6">
                      <label for="anio">Año:</label>
                      <input class="form-control" type="text" formControlName="anio"
                            [(ngModel)]="model.anio">
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <label for="periodoNombre">Período:</label>
                      <select class="form-control" formControlName="idPeriodo"
                      [(ngModel)]="model.idPeriodo">
                        <!--option [ngValue]="0">--Seleccionar--</option-->
                        <option *ngFor="let item of Periodos"
                        [ngValue]="item.idPeriodo">
                          {{ item.nombre }}
                          </option>
                      </select>
                    </div>
                  </div>     
                  <div class="form-group form-row">
                    <div class="col-sm-12 col-md-6">
                      <label for="idiomaDescripcion">Idioma:</label>
                      <select class="form-control" formControlName="idIdioma"
                      [(ngModel)]="model.idIdioma">
                        <!--option [ngValue]="0">--Seleccionar--</option-->
                        <option *ngFor="let item of Idiomas"
                        [ngValue]="item.idIdioma">
                          {{ item.descripcion }}
                          </option>
                      </select>
                    </div>
                    <div class="col-sm-12 col-md-6">
                    <label for="grupoNombre">Grupo:</label>
                    <select class="form-control" formControlName="idGrupo"
                    [(ngModel)]="model.idGrupo">
                      <!--option [ngValue]="0">--Seleccionar--</option-->
                      <option *ngFor="let item of Grupos"
                      [ngValue]="item.idGrupo">
                        {{ item.nombre }}
                        </option>
                    </select>
                    </div>
                  </div>                                   
                  <div class="form-group form-row">
                    <div class="col-sm-12 col-md-6">
                      <label for="comprobante">Comprobante:</label>
                      <p><a class="vimg" href="/assets/img/{{ model.comprobante }}" target="_blank">ver comprobante</a></p>
                    </div>                    
                    <div class="col-sm-12 col-md-6">
                      <label for="validado">Validado:</label>
                      <select class="form-control" formControlName="validado"
                      [(ngModel)]="model.validado">
                        <option [ngValue]="true">Válido</option>
                        <option [ngValue]="false">No Válido</option>
                      </select>
                    </div>
                  </div>
  
                  <div class="row text-dark pt-3">
                    <div class="col-lg-12">
                      <div class="buttonSelect text-center">
                        <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                        <button class="btn btn-danger" (click)="guardar()">Guardar</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  