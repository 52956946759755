//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';

import { GuiaService } from 'src/app/data-access/guia.service';
import { ModuloService } from 'src/app/data-access/modulo.service';
import { TemaService } from 'src/app/data-access/tema.service';
import { ActividadService } from 'src/app/data-access/actividad.service';
import { ModuloTemaService } from 'src/app/data-access/moduloTema.service';
import { TemaActividadService } from 'src/app/data-access/temaActividad.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import * as myGlobals from 'src/app/data-access/util';


@Component({
  selector: 'app-evaluacion-detalle',
  templateUrl: './guia-detalle.component.html',
  styles: [
  ]
})
export class GuiaDetalleComponent implements OnInit {
  
    idGuia;
    guiaNombre;
    Guia;
    TemasList;
    ActividadesList;
  
    fprincipal: FormGroup;
    ftema: FormGroup;
    factividad: FormGroup;
    
    mprincipal;
    mtema;
    mtemad;
    mactividad;
    mactividadd;

    temaWrong = false;
    actividadWrong = false;
    
    constructor(
        private router: Router,
        public fb: FormBuilder,
        private guiaService: GuiaService,
        private moduloService: ModuloService,
        private temaService: TemaService,
        private actividadService: ActividadService,
        private moduloTemaService: ModuloTemaService,
        private temaActividadService: TemaActividadService,
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
    ) {
        this.iniciarForm();
    }

  

    ngOnInit(): void {
        let guia = JSON.parse(localStorage.getItem('guia') || '{}') || [];
        this.idGuia = guia.idGuia;
        this.guiaNombre = guia.nombre;
        this.Guia = guia;
        this.getGuia();
        this.getTemas();
        this.getActividades();
        this.sort();   
    }

    iniciarForm() {
        this.fprincipalIniciar();
        this.ftemaIniciar();
        this.factividadIniciar();
    }

    fseccion: FormGroup;
    mseccion;

    fprincipalIniciar(){
        this.fprincipal = this.fb.group({
            idModulo: [0],
            idGuia: [this.idGuia],
            nombre: ['', [Validators.required]],
            objetivo: ['', [Validators.required]],
            queVasEncontrar: ['', [Validators.required]],
            temas: [[]]
        });
        this.mprincipal = this.fprincipal;
    }

    ftemaIniciar(){
        this.ftema = this.fb.group({
            idModulo: [0],
            idTema: [0, [Validators.required]]
        });

        this.mtema = this.ftema;
        this.mtemad = this.ftema;
    }

    factividadIniciar(){
        this.factividad = this.fb.group({
            idTema: [0],
            idActividad: [0, [Validators.required]]
        });

        this.mactividad = this.factividad;
        this.mactividadd = this.factividad;
    }
    
    getGuia() {
        this.spinner.show();    
        this.guiaService.getDataById(this.idGuia).toPromise().then(data => {
            this.Guia = data;
            this.spinner.hide();
        }); 
    }
    
    getTemas() {
        this.spinner.show();    
        this.temaService.getData().toPromise().then(data => {
            this.TemasList = data;
            this.spinner.hide();
        }); 
    }

    getActividades() {
        this.spinner.show();    
        this.actividadService.getData().toPromise().then(data => {
            this.ActividadesList = data;
            this.spinner.hide();
        }); 
    }

    recargar() {
        this.spinner.hide();
        this.modal.dismissAll();
        this.getGuia();
        this.getTemas(); 
        this.getActividades(); 
    }  

    nuevo(content) {
        this.iniciarForm();
        this.modal.open(content, { size: 'xl' });
    }

    temas(modelo) {
        this.spinner.show();
        let temas;
        this.moduloService.getDataById(modelo.idModulo).toPromise().then(data => {
            temas = data;
            modelo.temas = temas.temas;
            modelo.expand = true;
            this.modal.dismissAll();
            this.spinner.hide();
        }); 
    }

    temasCollapse(modelo) {
        modelo.temas = [];
        modelo.expand = false;
    }

    actividades(modelo) {
        this.spinner.show();
        let activ;
        this.temaService.getDataById(modelo.idTema).toPromise().then(data => {
            activ = data;
            modelo.actividades = activ.actividades;
            modelo.expand = true;
            this.modal.dismissAll();
            this.spinner.hide();
        }); 
    }

    actividadesCollapse(modelo) {
        modelo.actividades = [];
        modelo.expand = false;
    }

    agregarTema(content, modelo) {
        this.iniciarForm();
        this.mtema = modelo;
        this.temaWrong = false;
        let temas;
        this.moduloService.getDataById(modelo.idModulo).toPromise().then(data => {
            temas = data;
            modelo.temas = temas.temas;
            modelo.expand = true;
            this.modal.open(content, { size: 'xl' });
        }); 
    }

    agregarActividad(content, modelo) {
        this.iniciarForm();
        this.mactividad = modelo;
        this.actividadWrong = false;
        let act;
        this.temaService.getDataById(modelo.idTema).toPromise().then(data => {
            act = data;
            modelo.actividades = act.actividades;
            modelo.expand = true;
            this.modal.open(content, { size: 'xl' });
            console.log(this.mactividad);
        }); 
    }

    editar(content, modelo) {
        this.spinner.show();
        this.moduloService.getDataById(modelo.idModulo).toPromise().then(data => {
            this.mprincipal = data;
            this.modal.open(content, { size: 'xl' });
            this.spinner.hide();
        }); 
    }

    eliminar(content, modelo) {
        this.mprincipal = modelo;
        this.modal.open(content, { size: 'md' });
    }

    quitarTema(content, modelo, modelo2) {
        this.mtema = modelo;
        this.mtemad.idModulo = modelo.idModulo;
        this.mtemad.nombreModulo = modelo.nombre;
        this.mtemad.idTema = modelo2.idTema;
        this.mtemad.nombreTema = modelo2.nombre;
        this.modal.open(content, { size: 'md' });
    }

    quitarActividad(content, modelo, modelo2) {
        this.mactividad = modelo;
        this.mactividadd.idTema = modelo.idTema;
        this.mactividadd.nombreTema = modelo.nombre;
        this.mactividadd.idActividad = modelo2.idActividad;
        this.mactividadd.nombreActividad = modelo2.nombre;
        this.modal.open(content, { size: 'md' });
    }

    guardar() {
        this.spinner.show();
        let temas = [];
        this.fprincipal.controls["temas"].setValue(temas); 
        this.fprincipal.controls["idGuia"].setValue(this.idGuia); 
        if (this.fprincipal.controls["idModulo"].value == undefined) {
            this.moduloService.addData(this.fprincipal.value).subscribe(
                (res) => { this.recargar(); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
            });
        }
        else {
            this.moduloService.updData(this.fprincipal.controls["idModulo"].value, this.fprincipal.value).subscribe(
                (res) => { this.recargar(); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
                });
        }

    }

    borrar() {
        this.spinner.show();
        if (this.fprincipal.controls["idModulo"].value != undefined) {
            this.moduloService.dltData(this.fprincipal.controls["idModulo"].value).subscribe(
                (res) => { this.recargar(); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
                }
            );
        }
    }

    borrarTema() {
        this.spinner.show();
        console.log(this.ftema.value);
        if (this.ftema.controls["idModulo"].value != undefined && this.ftema.controls["idTema"].value != undefined) {
            this.moduloTemaService.dltData(this.ftema.controls['idModulo'].value, this.ftema.value).subscribe(
                (res) => { this.temas(this.mtema); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
                }
            );
        }
    }

    borrarActividad() {
        this.spinner.show();
        console.log(this.factividad.value);
        if (this.factividad.controls["idTema"].value != undefined && this.factividad.controls["idActividad"].value != undefined) {
            this.temaActividadService.dltData(this.factividad.controls['idTema'].value, this.factividad.value).subscribe(
                (res) => { this.actividades(this.mactividad); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
                }
            );
        }
    }

    guardarTema() {
        console.log(this.ftema.value);
        if (this.ftema.controls["idTema"].value) {
            let check = false;
            this.mtema.temas.forEach(x => {
                if(x.idTema == this.ftema.controls["idTema"].value){
                    check = true;
                    return false;
                }
            });
            if(check){ this.temaWrong = true; return; }
            this.temaWrong = false;
            this.spinner.show();
            this.moduloTemaService.addData(this.ftema.value).subscribe(
                (res) => { this.temas(this.mtema); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
                }
            );
        }
    }

    guardarActividad() {
        console.log(this.mactividad);
        console.log(this.factividad.value);
        if (this.factividad.controls["idActividad"].value) {
            let check = false;
            this.mactividad.actividades.forEach(x => {
                if(x.idActividad == this.factividad.controls["idActividad"].value){
                    check = true;
                    return false;
                }
            });
            if(check){ this.actividadWrong = true; return; }
            this.actividadWrong = false;
            this.spinner.show();
            this.temaActividadService.addData(this.factividad.value).subscribe(
                (res) => { this.actividades(this.mactividad); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
                }
            );
        }
    }

    onChange(){
        this.temaWrong = false;
        this.actividadWrong = false;
    }

    detalle(item) {
        this.router.navigate(['/encuesta-detalle']);  
    }

    formatDate(fecha) {
        var d = new Date(fecha);
        d.setHours(0, 0, 0, 0);
        return [
        ('0' + d.getDate()).slice(-2),
        ('0' + (d.getMonth() + 1)).slice(-2),
        d.getFullYear()
        ].join('/');
    }

    asc;
    sort() {
        const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

        const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

        // do the work...
        document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
        const table = th.closest('table');
        const tbody = table.querySelector('tbody');
        Array.from(tbody.querySelectorAll('tr'))
            .sort(
            comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc)
            )
            .forEach(tr => tbody.appendChild(tr));
        })));
    }

}
