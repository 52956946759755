<div class="container align-items-center flex-column">
    <div class="row mt-4" *ngIf="!usuarioService.isAlumno() && !usuarioService.adm()">
        <div class="col-12">
            <!--p class="text-danger"><a class="text-danger font-weight-bold" href="/e-bienvenida">Inicio</a> > {{ Modulo.nombre }}</p-->
            <div id="breadcrumbs">
                <ul class="steps pl-0 mb-0">
                    <li class="step">                        
                        <a [routerLink]="['/e-status-tutor']" *ngIf="Usuario.idPerfil != 10">
                            Inicio
                        </a>
                        <a [routerLink]="['/e-bienvenida']" *ngIf="Usuario.idPerfil === 10">
                            Inicio
                        </a>
                    </li>
                    <li class="step" *ngIf="Usuario.idPerfil != 10">                        
                        <a [routerLink]="['/e-status']">
                            {{ Cuenta.nombres }} {{ Cuenta.aPaterno }} {{ Cuenta.aMaterno }}
                        </a>
                    </li>
                    <li class="step inactive">
                        <a >
                            {{ Guia ? Guia[0].nombre : '' }}
                        </a>
                    </li>
                </ul>                
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="usuarioService.isAlumno()">
        <div class="col-12">
            <!--p class="text-danger"><a class="text-danger font-weight-bold" href="/e-bienvenida">Inicio</a> > {{ Modulo.nombre }}</p-->
            <div id="breadcrumbs">
                <ul class="steps pl-0 mb-0">
                    <li class="step">                        
                        <a [routerLink]="['/e-status']">
                            Inicio
                        </a>
                    </li>
                    <li class="step inactive">
                        <a >
                            Resumen
                        </a>
                    </li>
                </ul>                
            </div>
        </div>
    </div>
</div>
<div class="pit-container">
    <div class="pit-card mb-5">
        <div class="pit-card-info flex-column">
            <div class="pit-card-name" style="flex: 0 0 100%">
                <h2>{{ Cuenta.nombres }}<br>{{ Cuenta.aPaterno }} {{ Cuenta.aMaterno }}</h2>
                <h4>{{ Cuenta.perfil }}</h4>
            </div>
            <div class="pit-card-data py-2" style="flex: 0 0 100%">
                <div class="pit-card-data-content py-2" style="border: 0">
                    <div *ngFor="let item of Guia">
                        <h4 class="text-danger mb-3">{{ item.nombre }}</h4>
                        <div *ngIf="isTutor">
                            <div *ngFor="let itm of (item.modulos ? item.modulos : [])" class="pit-modulo-collapse">
                                <h5><button class="btn icon-btn" (click)="temas(itm)" ngbTooltip="Ver Temas" *ngIf="!itm.expand"><fa-icon [icon]="['fas','plus']" class="text-dark"></fa-icon></button>
                                    <button class="btn icon-btn" (click)="temasCollapse(itm)" ngbTooltip="Ocultar Temas" *ngIf="itm.expand"><fa-icon [icon]="['fas','minus']" class="text-dark"></fa-icon></button>
                                    Módulo: <span class="font-weight-normal">{{ itm.nombre }}</span>
                                </h5>
                                <div class="tema-title" *ngFor="let it of itm.temas; let j = index">
                                    <button class="btn icon-btn" (click)="actividades(it)" ngbTooltip="Ver Actividades" *ngIf="!it.expand"><fa-icon [icon]="['fas','plus']" class="text-dark"></fa-icon></button>
                                    <button class="btn icon-btn" (click)="actividadesCollapse(it)" ngbTooltip="Ocultar Actividades" *ngIf="it.expand"><fa-icon [icon]="['fas','minus']" class="text-dark"></fa-icon></button>
                                    <span class="mr-2">Tema:</span> {{it.nombre}}
                                    <div class="pl-5">
                                        <div *ngFor="let itt of (it.actividades ? it.actividades.actividadesResueltas : []); let k = index" class="pit-card-activity" [ngClass]="itt.actividadResuelta ? 'pit-success' : ''">
                                            <h6 (click)="verActividad(itt)"><fa-icon *ngIf="itt.actividadResuelta" [icon]="['fas','medal']"></fa-icon> {{ itt.nombreActividad }}</h6>
                                            <p *ngIf="itt.fechaModificacionActividad"><small><b>Fecha de Respuesta: </b>{{ itt.fechaModificacionActividad }}</small></p>
                                            <div class="pit-star">
                                                <fa-icon *ngIf="!itt.fechaAsignacion" [icon]="['far','star']" style="color: #ddd;" (click)="asignar(itt)"></fa-icon>
                                                <fa-icon *ngIf="itt.fechaAsignacion" [icon]="['fas','star']" style="color: #ff9800;" (click)="asignar(itt)" tooltipClass="pit-tooltip" ngbTooltip="Asignado por: {{ itt.asignadoPor }}"></fa-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!isTutor">
                            <div *ngFor="let itm of (item.resumen ? item.resumen.actividadesResueltas : [])" class="pit-card-activity" [ngClass]="itm.actividadResuelta ? 'pit-success' : ''">
                                <h6 class="cursor-default"><fa-icon *ngIf="itm.actividadResuelta" [icon]="['fas','medal']"></fa-icon> {{ itm.nombreActividad }}</h6>
                                <p class="mb-0"><small><b>Ruta: </b>{{ itm.nombreModulo }} > {{ itm.nombreTema }}</small></p>
                                <p *ngIf="itm.fechaModificacionActividad"><small><b>Fecha de Respuesta: </b>{{ itm.fechaModificacionActividad }}</small></p>
                                <div class="pit-star cursor-default">
                                    <fa-icon *ngIf="!itm.fechaAsignacion" [icon]="['far','star']" style="color: #ddd;"></fa-icon>
                                    <fa-icon *ngIf="itm.fechaAsignacion" [icon]="['fas','star']" style="color: #ff9800;" tooltipClass="pit-tooltip" ngbTooltip="Asignado por: {{ itm.asignadoPor }}"></fa-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #actividadModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{ Actividad.nombre }}</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="Actividad.idTipoActividad==1" class="my-2">
            <!--div *ngIf="Actividad.idActividad != 3 || respuestasAlt.respuestas.length">
                <div *ngIf="Actividad.idActividad == 3">
                    <p class="font-weight-bold">RESULTADOS:</p>
                    <div *ngFor="let item of respuestasAlt.resultadosClasificaciones" class="mb-4">
                        <p class="mb-0"><b>{{ item.clasificacion }}</b>: {{ item.totalClasificacion }}</p>
                        <p>Preferencia: {{ item.preferenciaAlumno }}</p>
                    </div>
                </div-->
                <table class="table table-bordered">
                    <thead>
                        <tr class="table-danger">
                            <th *ngFor="let item of Actividad.dinamica[0].dinamicaTabla.columnas">{{ item.nombre }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tablaRespuestas;let i = index; trackBy: trackByFn">
                            <td *ngFor="let itm of item;let j = index">{{ tablaRespuestas[i][j] }}</td>
                        </tr>
                    </tbody>
                </table>
                <p *ngIf="Actividad.dinamica[0].dinamicaTabla.fechaRegistroFilasFormateado" class="mb-0 mt-2">
                    <small>Fecha de Respuestas: {{ Actividad.dinamica[0].dinamicaTabla.fechaRegistroFilasFormateado }}</small>
                </p>
            <!--/div-->
        </div>
        <div *ngIf="Actividad.idTipoActividad==2" class="my-2">
            <!--div class="form-check mb-2" *ngFor="let item of Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.preguntas; let i = index">
                <input class="form-check-input mr-2" type="checkbox" value="" id="pregunta{{i}}">
                <label class="form-check-label" for="pregunta{{i}}">
                  {{ item.pregunta }}
                </label>
            </div-->
            <div class="mb-2 border-bottom" *ngFor="let item of Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.preguntas; let i = index">
                <input class="custom d-none" type="checkbox" id="pregunta{{i}}" [(ngModel)]="respuestasCheck[i].respuesta" disabled />
                <label for="pregunta{{i}}">
                    <p class="mb-0 font-weight-bold">{{ (i + 1) + ".- " + item.pregunta }}</p>
                </label>
            </div>
            <p *ngIf="Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.fechaRegistroRespuestasFormateado" class="mb-0">
                <small>Fecha de Respuestas: {{ Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.fechaRegistroRespuestasFormateado }}</small>
            </p>
        </div>
        <div *ngIf="Actividad.idTipoActividad==2 && Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.resultadosClasificaciones.length" class="mb-4">
            <p class="font-weight-bold">RESULTADOS:</p>
            <div *ngFor="let item of Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.resultadosClasificaciones" class="mb-4">
                <p class="mb-0"><b>{{ item.clasificacion }}</b>: {{ item.totalClasificacion }}</p>
                <p>Preferencia: {{ item.preferenciaAlumno }}</p>
            </div>
        </div>
        <div *ngIf="Actividad.idTipoActividad==3" class="my-2 py-2">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Valor</th>
                        <th>Nombre</th>
                        <th>Descripción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.opciones">
                        <td class="font-weight-bold">{{ item.valor }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.descripcion }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="Actividad.idTipoActividad==3" class="my-2 pt-4">
            <div class="row mx-0">
                <div class="col-6 pb-2"></div>
                <div class="col text-center py-2 text-light" style="background: #1e3882;border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let item of Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.opciones; let i = index">
                    <span class="font-weight-bold">{{ item.valor }}</span>
                </div>
            </div>
            <div class="row mx-0" *ngFor="let item of Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.preguntas; let i = index">
                <div class="col-6 py-3" style="border-bottom: 1px solid #ccc">{{ item.pregunta }}</div>
                <div class="col text-center py-3" style="border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let itm of Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.opciones">
                    <div class="w-100 h-100" [ngClass]="respuestasOption[i].idDinamicaFrecuenciaRealizacionOpcion == itm.idDinamicaFrecuenciaRealizacionOpcion ? 'selected' : ''"></div>
                </div>
            </div>
            <p *ngIf="Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.fechaRegistroRespuestasFormateado" class="mb-0">
                <small>Fecha de Respuestas: {{ Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.fechaRegistroRespuestasFormateado }}</small>
            </p>
        </div>
        <div *ngIf="Actividad.idTipoActividad==3 && Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.resultadoPuntaje.length" class="mb-4">
            <p class="font-weight-bold">RESULTADOS:</p>
            <div *ngFor="let item of Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.resultadoPuntaje" 
                [ngClass]="{'bg-blue': item.clasificacionAlcanzada === 'EXCELENTE', 'bg-green' : item.clasificacionAlcanzada === 'BIEN', 'bg-yellow' : item.clasificacionAlcanzada === 'REGULAR', 'bg-orange' : item.clasificacionAlcanzada === 'MAL' }"
                class="mb-4 text-white p-4 d-inline-block">
                <p class="mb-0"><b>{{ item.clasificacionAlcanzada }}</b></p>
                <p class="mb-0">Puntaje Total: {{ item.puntajeTotal }}</p>
            </div>
        </div>
        <div *ngIf="Actividad.idTipoActividad==4" class="my-2 py-2">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Clave</th>
                        <th>Significado</th>
                        <th>Clave</th>
                        <th>Significado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of clavesTabla">
                        <td class="font-weight-bold">{{ item[0].clave }}</td>
                        <td>{{ item[0].significado }}</td>
                        <td class="font-weight-bold" *ngIf="item[1]">{{ item[1].clave }}</td>
                        <td *ngIf="item[1]">{{ item[1].significado }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="Actividad.idTipoActividad==4" class="my-2 pt-4">
            <div #actSemanal>
                <div class="row mx-0">
                    <div class="col pb-2"></div>
                    <div class="col text-center py-2" style="background: #f5c2c9;border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let item of Actividad.dinamica[0].dinamicaConcentracionSemanal.diasSemana; let i = index">
                        <span class="font-weight-bold">{{ item.nombre }}</span>
                    </div>
                </div>
                <div class="row mx-0" *ngFor="let item of Actividad.dinamica[0].dinamicaConcentracionSemanal.rangosHoras; let i = index">
                    <div class="col py-3" style="border-bottom: 1px solid #ccc">{{ item.rango }}</div>
                    <div class="col text-center py-3 d-flex justify-content-center align-items-center" style="border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let itm of Actividad.dinamica[0].dinamicaConcentracionSemanal.diasSemana; let j = index">
                        <div class="w-100 h-100" style="cursor:pointer;font-size:14px" class="badge badge-pill" [ngClass]="respuestasOption[i][j] ? 'badge-info' : 'badge-light'">{{ respuestasOption[i][j] ? respuestasOption[i][j].clave : '---' }}</div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-4">
                <div class="col-12 mb-2" *ngFor="let item of respuestasCheck; let i = index; trackBy: trackByFn">
                    <p>{{ item.pregunta }}</p>
                    <textarea class="form-control" [(ngModel)]="item.respuesta" disabled></textarea>
                </div>
            </div>
            <p *ngIf="Actividad.dinamica[0].dinamicaConcentracionSemanal.fechaRegistroRespuestasOpcionesFormateado" class="mb-0">
                <small>Fecha de Respuestas: {{ Actividad.dinamica[0].dinamicaConcentracionSemanal.fechaRegistroRespuestasOpcionesFormateado }}</small>
            </p>
        </div>
        <div *ngIf="Actividad.idTipoActividad==4 && Actividad.dinamica[0].dinamicaConcentracionSemanal.resultadoActividades.length" class="mb-4">
            <p class="font-weight-bold">RESULTADOS:</p>
            <div *ngFor="let item of Actividad.dinamica[0].dinamicaConcentracionSemanal.resultadoActividades" class="mb-4">
                <p class="mb-0"><b>{{ item.actividadEstudiante }}</b></p>
                <p>Total de horas: {{ item.totalHoras }}</p>
            </div>
        </div>
        <div *ngIf="Actividad.idTipoActividad==5" class="my-2 py-2">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Clave</th>
                        <th>Significado</th>
                        <th>Clave</th>
                        <th>Significado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of clavesTabla">
                        <td class="font-weight-bold">{{ item[0].clave }}</td>
                        <td>{{ item[0].significado }}</td>
                        <td class="font-weight-bold" *ngIf="item[1]">{{ item[1].clave }}</td>
                        <td *ngIf="item[1]">{{ item[1].significado }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="Actividad.idTipoActividad==5" class="my-2 pt-4">
            <div #actSemestral>
                <div class="row mx-0">
                    <div class="col pb-2"></div>
                    <div class="col text-center py-2" style="background: #f5c2c9;border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let item of Actividad.dinamica[0].dinamicaPlaneacionSemestral.diasSemana; let i = index">
                        <span class="font-weight-bold">{{ item.nombre }}</span>
                    </div>
                </div>
                <div class="row mx-0" *ngFor="let item of Actividad.dinamica[0].dinamicaPlaneacionSemestral.semanasSemestre; let i = index">
                    <div class="col py-3 text-center" style="border-bottom: 1px solid #ccc; font-size:17px">{{ item.semana }}</div>
                    <div class="col text-center py-3 d-flex justify-content-center align-items-center" style="border-left: 1px solid #ccc;border-bottom: 1px solid #ccc" *ngFor="let itm of Actividad.dinamica[0].dinamicaPlaneacionSemestral.diasSemana; let j = index">
                        <div class="w-100 h-100" style="cursor:pointer;font-size:14px" class="badge badge-pill" [ngClass]="respuestasOption[i][j] ? 'badge-info' : 'badge-light'">{{ respuestasOption[i][j] ? respuestasOption[i][j].clave : '---' }}</div>
                    </div>
                </div>
            </div>
            <p *ngIf="Actividad.dinamica[0].dinamicaPlaneacionSemestral.fechaRegistroRespuestasOpcionesFormateado" class="mb-0">
                <small>Fecha de Respuestas: {{ Actividad.dinamica[0].dinamicaPlaneacionSemestral.fechaRegistroRespuestasOpcionesFormateado }}</small>
            </p>
        </div>
        <hr>
        <div class="mt-4">
            <form [formGroup]="fprincipal">
                <label class="font-weight-bold"><small>Comentario para el estudiante:</small></label>
                <textarea formControlName="comentario" class="form-control" [(ngModel)]="mprincipal.comentario" [readonly]="mensajeTutor.comentario"></textarea>
                <button *ngIf="!mensajeTutor.comentario" class="mt-2 btn btn-danger" (click)="guardar()" [disabled]="fprincipal.invalid">Guardar Mensaje</button>
                <p *ngIf="mensajeTutor.comentario"><small>{{ mensajeTutor.nombreComenta }} - {{ mensajeTutor.fechaRegistro }}</small></p>
            </form>
            <div *ngIf="mensajeAlumno.comentario">
                <label class="font-weight-bold"><small>Respuesta del estudiante:</small></label>
                <textarea class="form-control" readonly>{{ mensajeAlumno.comentario }}</textarea>
                <p><small>{{ mensajeAlumno.nombreComenta }} - {{ mensajeAlumno.fechaRegistro }}</small></p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-light" (click)="modal.close()">Cerrar</button>
    </div>
</ng-template>