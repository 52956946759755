<div class="pit-container">
    <div class="pit-card">
        <div class="pit-card-info">
            <div class="pit-card-name">
                <span class="pit-card-welcome">Hola!</span>
                <h2>{{ Cuenta.nombres }}<br>{{ Cuenta.aPaterno }} {{ Cuenta.aMaterno }}</h2>
                <h4>{{ Cuenta.perfil }}</h4>
            </div>
            <div class="pit-card-data">
                <div class="pit-card-data-content">
                    <div class="pit-card-data-row">
                        <h3>Cuenta</h3>
                        <p>{{ Cuenta.cuenta }}</p>
                    </div>
                    <!--div class="pit-card-data-row">
                        <h3>Carrera</h3>
                        <p>{{ Cuenta.carrera }}</p>
                    </div-->
                    <div class="pit-card-data-row">
                        <h3>Departamento / Área</h3>
                        <p>{{ Cuenta.coordinacion }}</p>
                    </div>
                    <div class="pit-card-data-row">
                        <h3>Periodo</h3>
                        <p>{{ Cuenta.periodo }}</p>
                    </div>
                    <div class="pit-card-data-row">
                        <h3>Año</h3>
                        <p>{{ Cuenta.anio }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="pit-card-progress">
            <h2 class="text-white text-center my-2">Estudiantes Asignados</h2>
            <div class="text-right mb-4">
                <a class="text-white" [routerLink]="['/reporte-ha']"><u><small>Ir a Reportes <fa-icon [icon]="['far','file-alt']" class="text-white"></fa-icon></small></u></a>&nbsp;&nbsp;
                <a class="text-white" [routerLink]="['/e-asignar-actividad']"><u><small>Ir a Asignación Múltiple <fa-icon [icon]="['fas','star']" class="text-white"></fa-icon></small></u></a>
            </div>
            <div *ngFor="let item of Cuenta.alumnos; let i = index" class="d-flex justify-content-between mb-2">
                <div class="pit-card-progress-info">
                    <h2><a class="text-white" style="cursor:pointer" (click)="irEstudiante(item.idUsuario)">{{ i + 1 }}. {{ item.nombres }} {{ item.aPaterno }} {{ item.aMaterno }}
                        <fa-icon [icon]="['fas','long-arrow-alt-right']" class="text-white"></fa-icon></a></h2>
                </div>
                <div class="d-flex">
                    <div class="pit-card-bar mr-2" style="width: 180px">
                        <div class="pit-card-bar-fill" [ngStyle]="{'width': item.status ? item.status.porcentajeTotalRealizado : '0%'}"></div>
                    </div>
                    <p>{{ item.status ? item.status.porcentajeTotalRealizado : '0%' }} <span>({{ item.status ? item.status.numeroActividadesCompletadas : 0 }} de {{ item.status ? item.status.numeroTotalActividades : 0 }})</span></p>
                </div>
            </div>
        </div>
    </div>
</div>