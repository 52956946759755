import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class TipoRespuestaService {
  user = [];
  constructor(private http: HttpClient) {
  }

  getTipoRespuesta() {
    return this.http.get(config.apiUrl+'/TipoRespuesta');
  }

   //Alta
   addData(data:undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/TipoRespuesta/', data);
  }

  //Cambio
  updData(id:undefined, data:undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/TipoRespuesta/' + id, data);
  }   
}