<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <div class="row justify-content-between py-2">
    <div class="col-lg-4 col-sm-12 ">
      <h4 class="title-section">Evaluación</h4>
    </div>
    <div class="col-lg-4 col-sm-12 ">
      <h4 class="title-section">{{evaluacionNombre}}</h4>
    </div>    
    <div class="col-md-4 col-sm-12 text-right">
      &nbsp;
    </div>    
  </div>

  <!--Filtros-->

  <!--Arbol-->
  <table #lineameta class="table table-striped text-left table-fixed">
    <thead>
      <th class="text-left" >
        <button type="button" class="btn btn-danger mr-4" (click)="anterior();"
        [disabled]=" primero ? 'disabled' : '' ">Anterior</button>
      </th>
      <th class="text-right" >
        <button type="button" class="btn btn-danger ml-4" (click)="siguiente();"
        [disabled]=" ultimo ? 'disabled' : '' ">Siguiente</button>
      </th>      
    </thead>
    <tbody>

      <ng-container *ngFor="let seccion of Evaluacion; let a = index">

        <tr class="eStyle t-show bg-secondary" id="e_{{seccion.idSeccion}}">
          <td colspan="2">
            <label class="elinea text-white ">
              Sección {{ a+1 }}: {{ seccion.seccionNombre }}
            </label>
          </td>          
        </tr>
        <ng-container *ngFor="let item of seccion.children; let b = index">
          <tr class="pStyle t-show" id="p_{{item.idPregunta}}">
            <td colspan="2">
              <label class="elinea">
                {{ b+1 }} . {{ item.preguntaDescripcion }}
              </label>
            </td>          
          </tr>
          <tr class="rStyle t-show" id="r_{{item.idRespuesta}}">
            <td colspan="2">
              
              <label *ngIf="item.idTipoRespuesta===1" class="elinea">
                <input class="form-control" name="txt_{{item.idPregunta}}">
              </label>
              
              <span *ngIf="item.idTipoRespuesta===2">

                  <ng-container *ngFor="let op of item.respuestaOpciones">
                    <label class="elinea mr-4">
                    <input type="radio"
                    name="opts_{{item.idPregunta}}"
                    id="{{op.valor}}"
                    value="{{op.valor}}"
                    >
                    <span class="label-text text-danger ml-1">{{ op.texto }}</span>
                    </label>
                  </ng-container>

              </span>              
            </td>              
          </tr>                    
        </ng-container>
      </ng-container>

    </tbody>
    <!--tfoot class="tfoot-ibero text-center bg-secondary text-white">
      <tr>
        <td>
          &nbsp;
        </td>
      </tr>
    </tfoot-->
  </table>      
</div>