import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpEventType, HttpClient } from '@angular/common/http';

import { UsuarioService } from 'src/app/data-access/usuario.service';
import { IdiomaService } from 'src/app/data-access/idioma.service';
import { FileService } from 'src/app/data-access/file.service';


@Component({
  selector: 'app-e-inscripcion',
  templateUrl: './e-inscripcion.component.html',
  styles: [
  ]
})
export class EInscripcionComponent implements OnInit {
  //Upload File
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();

  forma: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  enabled = false;
  Colegios;
  model;
  features;
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private usuarioService: UsuarioService,
    private idiomaService: IdiomaService,
    private fileService: FileService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.iniciarForm();
   }


  iniciarForm() {
   this.forma = this.formBuilder.group({
    idUsuario: [0],
    idIdioma: [0],
    nivel: [0],
    idGrupo: [1],
    anio: [0],
    idPeriodo: [0],
    comprobante: ['', [Validators.required]],
    validado:[false]
    //imagen: ['', [Validators.required]],
  },
  );
    this.model = this.forma;
  }

  ngOnInit(): void {
    this.addIdioma();
    this.Usuario = this.usuarioService.getUser();
    console.log(this.Usuario);
  }

  Idiomas;
  addIdioma(){
    this.spinner.show();
    this.idiomaService.getIdioma().toPromise()
    .then(data => {
      this.Idiomas = data;
      this.spinner.hide();
    });    
  }

  Usuario;
  guardar() {

    this.spinner.show();
    this.forma.controls["idUsuario"].setValue(this.Usuario.idUsuario);
    this.forma.controls["idPeriodo"].setValue(1);
    this.forma.controls["nivel"].setValue(1);
    this.forma.controls["idIdioma"].setValue(1);

    console.log(this.forma.value);
    this.usuarioService.addInscripcion(this.forma.value).subscribe(
      (res) => {
        this.spinner.hide();
        this.recargar();
      },
      (error) => {
        if(error.error.result === 3)
        {
          this.spinner.hide();
          this.toastr.error(error.error.message);
        }
        console.log(error);
      });
  }

  recargar() {
    this.toastr.success('Se ha enviado tu solicitud de registro.');
    //this.router.navigate(['/']);
  }
  
  cancelar(){
    this.router.navigate(['/']);
  }

  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    let name = 'sb_' + this.model.idNivel + ' ' +  fileToUpload.name;
    name = name.replace(/ /g, '_');
    //name = name.replace('ñ', 'n');
    //let arrExt = name.split(".");
    //let ext = arrExt[arrExt.length-1];
    //this.model.imagen = "banner_"+this.model.bannerId + "." + ext;
    this.forma.controls["comprobante"].setValue(name);
    //Actualizamos Valores
    //this.bRec=false;
    //this.guardar(false);
    //this.bRec=true;

    const formData = new FormData();
    formData.append('file', fileToUpload, name);

    
    this.fileService.uploadImg(formData).subscribe(
      (res) => {
        this.toastr.success('El comprobante ha sido guardado.');
        //this.recargar();
      },
      (error) => {
        console.log(error);
      });    

  }    

}

