<div class="container" style="margin-top: 20px;">
    <div class="text-center pt-3">
        <h4 class="title-section">Administrar contraseñas</h4>
    </div>
    <div style="height: 360px; overflow-y: scroll; margin-top: 30px;">
        <table class="table table-striped text-center table-fixed dataTable">
            <thead class="bg-secondary text-white">
                <tr>
                    <th>Cuenta</th>
                    <th>Usuario</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let usuario of usuarios">
                    <td>{{usuario.cuenta}}</td>
                    <td>{{usuario.nombres}}</td>
                    <td><button class="btn btn-danger" data-toggle="modal" data-target="#modalContrasena" (click)="seleccionarId(usuario.idUsuario)">Cambiar
                            contraseña</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    <br>
    <button data-toggle="modal" data-target="#modalReset" class="btn btn-danger">Resetear contraseñas</button>
</div>

<!-- Modal -->
<div class="modal fade" id="modalContrasena" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" style="margin-top: 10%; width: 20%; margin-left: 40%;" role="document">
        <div class="modal-content" style="width: 100%;">
            <div class="modal-body">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="2" style="text-align: center;"><input style="margin-top: 20px;"
                                class="form-control" type="password" placeholder="Nueva contraseña" [(ngModel)]="contra"></td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: center;"><input style="margin-top: 20px;"
                                class="form-control" type="password" placeholder="Confirmar contraseña" [(ngModel)]="contraConf"></td>
                    </tr>
                    <tr>
                        <td><label> </label></td>
                    </tr>
                    <tr>
                        <td style="text-align: center;"><button id="cerrarContra" type="button" class="btn btn-secondary" (click)="cancelarContra()" data-dismiss="modal">Cerrar</button></td>
                        <td style="text-align: center;"><button type="button" class="btn btn-danger" (click)="guardarContrasena()">Guardar</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalReset" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Reset contraseñas</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Confirma que deseas resetear las contraseñas.
        </div>
        <div class="modal-footer">
          <button id="cerrarReset" type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" (click)="resetContrasena()">Aceptar</button>
        </div>
      </div>
    </div>
  </div>