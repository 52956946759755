<div class="container" style="margin-top: 20px;">
    <div class="text-center pt-3">
        <h4 class="title-section">Limpiar Actividades</h4>
    </div>
    <div style="height: 360px; overflow-y: scroll; margin-top: 30px;">
        <table class="table table-striped text-center table-fixed dataTable">
            <thead class="bg-secondary text-white">
                <tr>
                    <th>Cuenta</th>
                    <th>Usuario</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let usuario of usuarios">
                    <td>{{usuario.cuenta}}</td>
                    <td>{{usuario.nombres}}</td>
                    <td><button class="btn btn-danger" data-toggle="modal" data-target="#modalLimpiar" (click)="seleccionarId(usuario.idUsuario)">Limpiar actividades</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    <br>
    <button data-toggle="modal" data-target="#modalBorrar" class="btn btn-danger">Limpiar todos</button>
</div>

<!-- Modal -->
<div class="modal fade" id="modalLimpiar" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" style="margin-top: 10%; width: 20%; margin-left: 40%;" role="document">
        <div class="modal-content" style="width: 100%;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Borrar respuestas</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Confirma que deseas limpiar las actividades.
              </div>
              <div class="modal-footer">
                <button id="cerrarActividad" type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-danger" (click)="limpiarActividadad()">Aceptar</button>
              </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalBorrar" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Limpiar actividades</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Confirma que deseas limpiar las acividades de todos los usuarios.
        </div>
        <div class="modal-footer">
          <button id="cerrarBorrar" type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" (click)="borrarActividadad()">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
