
<!--Contenedor-->
<div class="container align-items-center flex-column">

    <!--Encabezado-->
    <div class="text-center pt-3">
      <h4 class="title-section">Secciones</h4>
    </div>

    <div class="row" *ngFor="let rowData of actRows">

        <div class="col-md-1 text-center">
            &nbsp;
        </div>

        <div class="col-md-2 text-center" *ngFor="let item of rowData">

            <button class="btn icon-btn" (click)="gotoEncuesta(item)" ngbTooltip="">
                <img class="img-box p-3 item-image bg-white rounded animate__animated animate__backInDown" src="/assets/img/seccion/{{ item.idSeccion }}.svg" alt="" />
            </button>

            <p class="text-danger item-text" >{{ item.descripcion }}</p>
        </div>
        <div class="col-md-1 text-center">
            &nbsp;
        </div>

    </div>

</div>
