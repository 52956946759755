<nav class="navbar navbar-expand-lg navbar-light bg-secondary" id="nav">
  <div class="container">
    <a class="navbar-brand" [routerLink]="" (click)="home()">
      <img src="assets/img/nav/mxibero-blanco.png" height="60" class="mr-4">
      <img src="assets/img/nav/PIT PNG-03.png" height="80">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
      <ul class="navbar-nav ">

        <!--li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarUsuario" 
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ us.usr() }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarUsuario">
            <a class="dropdown-item" [routerLink]="['/perfil/']">Perfil</a>
            <a class="dropdown-item" [routerLink]="" (click)="logout()">Cerrar sesión</a>
          </div>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarEstudiante" *ngIf="us.es()"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Estudiante
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarEstudiante">
            <a class="dropdown-item" [routerLink]="['/e-inscripcion/']">Inscripciones</a>
            <a class="dropdown-item" [routerLink]="['/e-evaluacion/']">Encuestas</a>
          </div>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarPlaneacion" *ngIf="us.adm()"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Administración
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarPlaneacion">
            <a class="dropdown-item" [routerLink]="['/grupo/']">Grupos</a>
            <a class="dropdown-item" [routerLink]="['/usuario/']">Usuarios</a>
            <a class="dropdown-item" [routerLink]="['/rol/']">Roles</a>            
            <a class="dropdown-item" [routerLink]="['/inscripcion/']">Inscripciones</a>
            <a class="dropdown-item" [routerLink]="['/evaluacion']">Evaluaciones</a>
            
          </div>

        </li-->

        <li *ngIf="us.adm() || us.coordPIT()" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarAdministrador"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Catálogos
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarAdministrador">
            <a class="dropdown-item" [routerLink]="['/tema']">Temas</a> 
            <a class="dropdown-item" [routerLink]="['/actividad']">Actividades</a>
            <a class="dropdown-item" [routerLink]="['/competencia']">Competencias Manresa</a>
            <a class="dropdown-item" [routerLink]="['/concepto-clave']">Conceptos Clave</a>
            <a class="dropdown-item" [routerLink]="['/clave-semanal']">Clave Actividad Semanal</a>
            <a class="dropdown-item" [routerLink]="['/clave-semestral']">Clave Actividad Semestral</a>
            <a class="dropdown-item" [routerLink]="['/contra-invitado']">Contraseñas Invitados</a>
            <a class="dropdown-item" [routerLink]="['/actividades-invitado']">Limpiar Actividades Invitados</a>
          </div>
        </li>

        <li *ngIf="isAlumno || Usuario.idPerfil === 10" class="nav-item">
            <a class="nav-link" [routerLink]="['/e-resumen']">Resumen Actividades</a>
        </li>

        <li *ngIf="!isAlumno && !us.adm() && !us.coordPIT() && Usuario.idPerfil != 10" class="nav-item">
            <a class="nav-link" [routerLink]="['/e-bienvenida']">Ver Guías</a>
        </li>

        <div class="btn-group pull-right top-head-dropdown" *ngIf="Usuario.idPerfil != 10">
            <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding-top: 2px;padding-bottom: 2px;position:relative;flex:unset">
                <fa-icon [icon]="['fas','bell']" class="text-white" style="font-size:1.4em"></fa-icon><span *ngIf="CantidadNotif" class="badge badge-warning" style="position:absolute;top:1px;right:0px">{{ CantidadNotif }}</span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right p-0">
                <li *ngFor="let item of Notificaciones" class="dropdown-item d-flex align-items-center notificacion-content" (click)="stopClick($event)">
                    <div class="mr-3">
                        <div class="icon-circle bg-info">
                        <fa-icon [icon]="['far','comment-dots']" class="text-white"></fa-icon>
                        </div>
                    </div>
                    <div>
                        <div class="small text-gray-500">{{ item.fechaRetroalimentacion }}</div>
                        <span class="small"><b>{{ item.nombreComenta }}</b> ha comentado la actividad.</span>
                        <div class="small text-red-500 notificacion-accion" style="max-height: 0"><b>{{ item.nombreActividad }}</b><br/>
                            <a class="font-weight-bold text-info d-inline-block mt-2" (click)="marcarLeido(item)" style="cursor:pointer">Ir a la Actividad</a>
                        </div>
                    </div>
                </li>
                <li *ngIf="!CantidadNotif" class="dropdown-item d-flex align-items-center justify-content-center notificacion-content" (click)="stopClick($event)">
                    <div class="small text-gray-500 py-2"><i>No tienes notificaciones</i></div>
                </li>
              <!--li *ngFor="let item of Notificaciones">
                <div class="top-text-block">
                  <div class="top-text-heading">Ha respondido <b>{{ item.nombreComenta }}</b> en:</div>
                  <div class="top-text-light"><b>{{ item.nombreGuia }}</b> > <b>{{ item.nombreModulo }}</b> > <b>{{ item.nombreTema }}</b> > <b>{{ item.nombreActividad }}</b></div>
                </div> 
              </li-->
            </ul>
        </div>

        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle font-weight-bold" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ Usuario.nombres }} <fa-icon [icon]="['fas','sort-down']" class="d-inline-block" style="transform: translateY(-2px)"></fa-icon>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarAdministrador">
                <a class="dropdown-item" [routerLink]="" (click)="home()">Ir a Inicio</a>
                <a class="dropdown-item" [routerLink]="" (click)="logout()">Cerrar Sesión</a> 
            </div>
        </li>

      </ul>
    </div>
  </div>
  </nav>
