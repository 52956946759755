import { Component } from '@angular/core';
import { Router } from '@angular/router';
//import { LoginService } from './core/services/login.service';
import { LoginService } from './data-access/login.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'imxpit';

  validar = false;

  constructor(
    public ls: LoginService,
    private router: Router
  ) {

  }

  ngOnInit() {
    let valor = localStorage.getItem('valido');

    if (valor == '1') {
      this.validar = true;
    }

  }

}