import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/data-access/login.service';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from 'jquery';
import * as myGlobals from 'src/app/data-access/util';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent {
  isExpanded = false;
  validar = false;

  isAlumno = false;

  Usuario;
  Notificaciones;
  CantidadNotif = 0;

  constructor(
    public ls: LoginService,
    public us: UsuarioService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    //this.oAdmin = true;
    let permisos = JSON.parse(localStorage.getItem('listaPermisos') || '{}') || [];
    this.setPermisosModulos(permisos);
  }

  collapse() {
    this.isExpanded = true;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  ngOnInit() {
    //Toggle Click Function
    this.Usuario = this.us.getCuenta();
    if(this.Usuario.idPerfil == 99){
        this.isAlumno = true;
    }
    this.addLista();
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });

  }

    addLista() {
        this.spinner.show();
        this.us.obtenerRetroalimentacionActividadSinLeer(this.Usuario.idUsuario).toPromise()
        .then(data => {
            this.Notificaciones = data.comentarios;
            this.CantidadNotif = this.Notificaciones.length;
            this.spinner.hide();
        })
    }

    marcarLeido(item){
        this.spinner.show();
        this.us.retroalimentacionLeida(item.idRetroalimentacionActividad, {}).subscribe(
            (res) => { 
                this.addLista();
                if(this.Usuario.idPerfil == 99){
                    localStorage.setItem('eGuia', JSON.stringify({idGuia: item.idGuia, nombre: item.nombreGuia}));
                    localStorage.setItem('eModulo', JSON.stringify({idModulo: item.idModulo, nombre: item.nombreModulo}));
                    localStorage.setItem('eTema', JSON.stringify({idTema: item.idTema, nombre: item.nombreTema}));
                    localStorage.setItem('eActividad', JSON.stringify({idActividad: item.idActividad, nombre: item.nombreActividad}));
                    this.router.navigate(['/e-tema']);
                }else{
                    this.us.obtenerInformacionUsuarioSistema(item.idComenta).toPromise().then(dt => {
                        localStorage.setItem('uCuentaEstudiante', JSON.stringify((dt as any).perfiles.alumno));
                        localStorage.setItem('tRutaActividad', JSON.stringify({idGuia: item.idGuia, idModulo: item.idModulo, idTema: item.idTema, idActividad: item.idActividad}));
                        this.router.navigate(['/e-resumen']);  
                    });
                }
            },
            (error) => {
                //this.spinner.hide();
                console.log(error);
                this.spinner.hide();
            }
        );
    }

  logout() {
    localStorage.setItem('valido', '0');
    this.router.navigate(['/login']);
  }

  stopClick(event){
    event.stopPropagation();
  }

  home() {
    if (!this.Usuario) {
        this.router.navigate(['/login']);
    }
    else {
        switch(this.Usuario.idPerfil){
            case 1:
                this.router.navigate(['/administracion']);
                break;
            case 2:
                this.router.navigate(['/administracion']);
                break;
            case 3:
                this.router.navigate(['/administracion']);
                break;
            case 6:
                this.router.navigate(['/administracion']);
                break;
            case 99:
                this.router.navigate(['/e-status']);
                break;
            case 10:
                this.router.navigate(['/e-bienvenida']);
                break;
            default:
                this.router.navigate(['/e-status-tutor']);
                break;
        }
    }
  }

  horizonte: boolean | undefined; //idModulo = 1
  plan: boolean | undefined; //idModulo = 2
  usuarios: boolean | undefined; //idModulo = 3
  asistentes: boolean | undefined; //idModulo = 4
  oAdmin: boolean | undefined;
  oPlan: boolean | undefined;
  setPermisosModulos(permisos: null) {
    if (permisos === null) return false;
    this.usuarios = myGlobals.getPermiso(1) || myGlobals.getPermiso(2);
    this.asistentes = myGlobals.getPermiso(3) || myGlobals.getPermiso(4);
    this.horizonte = myGlobals.getPermiso(5) || myGlobals.getPermiso(6);
    this.plan = myGlobals.getPermiso(7) || myGlobals.getPermiso(8);
    this.oAdmin = (this.horizonte || this.usuarios) ? true : false;
    this.oPlan = (this.plan || this.asistentes) ? true : false;
    return true;
  }

}
