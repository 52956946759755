import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '../data-access/config';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  user = [];
  constructor(private http: HttpClient) {
  }

    getTipoUsuario() {
      return this.http.get(config.apiUrl+'/Usuario_tipo');
    }

    getPerfil() {
      return this.http.get(config.apiUrl+'/perfil');
    }

    getRol() {
      return this.http.get(config.apiUrl+'/rol');
    }

    getRolListado() {
      return this.http.get(config.apiUrl+'/usuario/getrolfull/0');
    }
    
    getUsuarioAll() {
      return this.http.get(config.apiUrl + '/Usuario');
    }
    

    getUsuarioById(id:undefined,) {
      return this.http.get(config.apiUrl + '/usuario/' + id);
    }

    getStatusGuiaUsuario(guia:any, usuario:any) {
        return this.http.get(config.apiUrl + '/usuario/StatusGuiaUsuario/' + guia + '/' + usuario);
    }

    getStatusGlobalGuiaUsuario(usuario:any) {
        return this.http.get(config.apiUrl + '/usuario/StatusGlobalGuiasUsuario/' + usuario);
    }

    obtenerInformacionUsuarioSistema(usuario:any) {
        return this.http.get(config.apiUrl + '/usuario/ObtenerInformacionUsuarioSistema/' + usuario);
    }

    obtenerEstudiantesTutor(usuario:any) {
      let data = JSON.parse(localStorage.getItem('uCuenta') || '{}') || []
      return this.http.get(config.apiUrl + '/usuario/ObtenerInformacionUsuarioSistemaCarrera/' + usuario + '/'+data.cuenta);
    }

    actividadesResueltasGuiaUsuario(guia:any, usuario:any) {
        return this.http.get(config.apiUrl + '/usuario/ActividadesResueltasGuiaUsuario/' + guia + '/' + usuario);
    }

    actividadesResueltasTemaUsuario(tema:any, usuario:any) {
        return this.http.get(config.apiUrl + '/usuario/ActividadesResueltasTemaUsuario/' + tema + '/' + usuario);
    }

    asignacionActividadAlumno(tutor:any, alumno:any, actividad:any): Observable<any> {
        return this.http.post(config.apiUrl + '/usuario/AsignacionActividadAlumno/' + tutor + '/' + alumno + '/' + actividad, null);
    }

    obtenerEmpleados(): Observable<any> {
        return this.http.get(config.apiUrl + '/usuario/ObtenerEmpleados/0');
    }

    obtenerEmpleadosCarrera(): Observable<any> {
      let data = JSON.parse(localStorage.getItem('uCuenta') || '{}') || []
      return this.http.get(config.apiUrl + '/usuario/ObtenerEmpleadosCarrera/0/'+data.cuenta);
    }

    obtenerEstudiantes(): Observable<any> {
        return this.http.get(config.apiUrl + '/usuario/ObtenerEstudiantes');
    }

    obtenerEstudiantesCarrera(): Observable<any> {
      let data = JSON.parse(localStorage.getItem('uCuenta') || '{}') || []
      return this.http.get(config.apiUrl + '/usuario/ObtenerEstudiantesCarrera/'+data.cuenta);
    }

    statusAsignacionActividadEstudiantes(tutor:any, actividad:any): Observable<any> {
        return this.http.get(config.apiUrl + '/usuario/StatusAsignacionActividadEstudiantes/' + tutor + '/' + actividad);
    }

    statusAsignacionActividadEstudiantesCarrera(tutor:any, actividad:any): Observable<any> {
      let data = JSON.parse(localStorage.getItem('uCuenta') || '{}') || []
      return this.http.get(config.apiUrl + '/usuario/StatusAsignacionActividadEstudiantesCarrera/' + tutor + '/' + actividad +'/'+data.cuenta);
  }

    asignarActividadGlobalEstudiantes(tutor:any, actividad:any, alumnos:any): Observable<any> {
        return this.http.post(config.apiUrl + '/usuario/AsignarActividadGlobalEstudiantes/' + tutor + '/' + actividad, alumnos);
    }

    desasignarActividadGlobalEstudiantes(tutor:any, actividad:any, alumnos:any): Observable<any> {
        return this.http.post(config.apiUrl + '/usuario/DesasignarActividadGlobalEstudiantes/' + tutor + '/' + actividad, alumnos);
    }

    retroalimentacionActividad(tutor:any, alumno:any, actividad:any, data:any): Observable<any> {
        return this.http.post(config.apiUrl + '/usuario/RetroalimentacionActividad/' + tutor + '/' + alumno + '/' + actividad, data);
    }

    obtenerRetroalimentacionActividadSinLeer(user:any): Observable<any> {
        return this.http.get(config.apiUrl + '/usuario/ObtenerRetroalimentacionActividadSinLeer/' + user);
    }

    retroalimentacionLeida(id:any, data:any): Observable<any> {
        return this.http.post(config.apiUrl + '/usuario/RetroalimentacionLeida/' + id, data);
    }

    validaCuenta(data:any): Observable<any> {
        return this.http.post(config.apiUrl + '/usuario/ValidaCuenta', data);
    }

    //Consulta
    getData() {
      return this.http.get(config.apiUrl + '/Usuario');
  }

  //Consulta Listado Usuarios Sistema
  getUsuarioListadoSistema() {
    //return this.http.get(config.apiUrl + '/Usuario/GetListadoSistema');
    return this.http.get(config.apiUrl + '/usuario.json');
  }

  //Consulta Listado Usuarios Sistema
  getEncuestaListado() {
    //return this.http.get(config.apiUrl + '/Usuario/GetListadoSistema');
    
    return this.http.get('http://localhost:4200/assets/json/encuesta.json');
    return this.http.get(config.apiUrl + '/encuesta.json');
  }  

  //Consulta Listado Participantes
  getUsuarioListadoParticipante(instancia:undefined) {
    return this.http.get(config.apiUrl + '/Usuario/GetListadoSistema/'+instancia);
  }

    //Consulta Listado Participantes
    getRoles() {
      return this.http.get(config.apiUrl + '/rol/');
    }


    //Alta
    addData(data:undefined): Observable<any> {
      return this.http.post(config.apiUrl + '/Usuario/', data);
    }

    //Cambio
    updData(id:undefined, data:undefined): Observable<any> {
      return this.http.put(config.apiUrl + '/Usuario/' + id, data);
    }

    //Login
    login(data:undefined): Observable<any> {
      return this.http.post(config.apiUrl + '/Usuario/UserAccount/', data);
  }

  //Login Cuenta
  /*
  loginAccount(cuenta): Observable<any> {
    return this.http.get(config.apiUrl + '/Usuario/LoginCuenta/' + cuenta);
  }
  */

  //Login Cuenta
  loginAccount(data:undefined): Observable<any> {
    console.log(data)
    return this.http.post(config.apiUrl + '/Usuario/LoginCuenta/', data);
  }

    //Login
    loginSHA(data:undefined): Observable<any> {
      return this.http.post(config.apiUrl + '/Usuario/login/', data);
    }

    //Restaura
    restaura(id:undefined, data:undefined): Observable<any> {
      return this.http.put(config.apiUrl + '/Usuario/Restaura/' + id, data);
    }

    //Restaura
    recupera(data:undefined): Observable<any> {
      return this.http.put(config.apiUrl + '/Usuario/PutSendRandom', data);
    }

    //Alta
    addResponsableColegio(data:undefined): Observable<any> {
      return this.http.post(config.apiUrl + '/Colegio_responsable/', data);
    }

    //Perfiles
    getPerfiles() {
      return this.http.get(config.apiUrl + '/Usuario/GetPerfiles');
    }
    /*
    //Consulta
    getResponsableColegio(id:undefined){
      return this.http.get(config.apiUrl + '/Colegio_responsable/GetbyColegio/' + id);
    }

    //Consulta
    getColegioListado(){
      return this.http.get(config.apiUrl + '/Colegio/GetActive');
    }

    //Consulta
    getColegioAll(){
      return this.http.get(config.apiUrl + '/Colegio');
    }

    //Consulta
    getColegio(id:undefined){
      return this.http.get(config.apiUrl + '/Colegio/' + id);
    }

    //Agergar
    addColegio(data:undefined): Observable<any> {
      return this.http.post(config.apiUrl + '/Colegio/', data);
    }

    //Cambio
    updColegio(id:undefined, data:undefined): Observable<any> {
      return this.http.put(config.apiUrl + '/Colegio/' + id, data);
    }
  */

    //Alta
    addInscripcion(data:undefined): Observable<any> {
      return this.http.post(config.apiUrl + '/inscripcion/', data);
    }

    //Inscripción
    getInscripcion() {
      return this.http.get(config.apiUrl + '/inscripcion');
    } 

    //RolesPermisos
    updRolPermisos(data:undefined): Observable<any> {
      return this.http.put(config.apiUrl + '/ModuloPermiso/PutPermisos/', data);
    }
    
    addUser(usuario:undefined) {
      localStorage.setItem('user', JSON.stringify(usuario));
    }

    getUser() {
      return JSON.parse(localStorage.getItem('user') || '{}') || [];
    }

    getCuenta() {
        return JSON.parse(localStorage.getItem('uCuenta') || 'null') || 0;
    }


    /*
    getUsuarioAll() {
      return this.http.get(config.apiUrl + '/Usuario');
    }

    getDatosEmpleado() {
      return this.http.get('assets/json/datosEmpleado.json');
  }
  */



    es() {
      let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
      return (usr.idRol === 3)
      //return (usr.usuario_tipoId === 2)
    }

    in() {
      let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
      return (usr.idRol === 2)
      //return (usr.usuario_tipoId === 1)
    }

    adm() {
      let usr = JSON.parse(localStorage.getItem('uCuenta') || '{}') || [];
      return (usr.idPerfil === 1)
      //return (usr.perfilId === 1)
    }

    coordPIT() {
      let usr = JSON.parse(localStorage.getItem('uCuenta') || '{}') || [];
      return (usr.idPerfil === 2)
      //return (usr.perfilId === 1)
    }

    espTOE() {
      let usr = JSON.parse(localStorage.getItem('uCuenta') || '{}') || [];
      return (usr.idPerfil === 3)
      //return (usr.perfilId === 1)
    }

    tutor() {
      let usr = JSON.parse(localStorage.getItem('uCuenta') || '{}') || [];
      return (usr.idPerfil === 5)
      //return (usr.perfilId === 1)
    }

    coordAcad() {
      let usr = JSON.parse(localStorage.getItem('uCuenta') || '{}') || [];
      return (usr.idPerfil === 6)
      //return (usr.perfilId === 1)
    }

    isAlumno() {
        let usr = JSON.parse(localStorage.getItem('uCuenta') || '{}') || [];
        return (usr.idPerfil === 99)
        //return (usr.perfilId === 1)
    }
    /*
    ser() {
      let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
      return (usr.perfilId === 3)
    }
    */
    usr() {
      //return "Nombre Usuario";
      let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
      return usr.nombres + ' ' + usr.apellido_Paterno + ' ' + usr.apellido_Materno;
    }

    alumnoInvitado(data){
      return this.http.post(config.apiUrl + '/usuario/alumnoInvitado/',data);
    }

}
