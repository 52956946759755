export const per = '';

export var cUsuario = false;
export var eUsuario = false;
export var cAsistente = false;
export var eAsistente = false;
export var cHorizonte = false;
export var eHorizonte = false;
export var cPlan = false;
export var ePlan = false;
export var eObsSup = false;
export var eObsDPEI = false;
export var fPlan = false;
export var fObsSup = false;
export var fObsDPEI = false;
export var otrasInstancias = false;
export var fAutoEval = false;
export var fAproEval = false;

export function setPermiso(permisos:any) {
  //Usuario. "idModulo": 3
  //"idOperacion":1, Consultar
  let _cUsuario = permisos.find((x:any) => x.idModulo === 3 && x.idOperacion === 1 && x.acceso.toString() === 'true');
  cUsuario = (_cUsuario !== undefined) ? true : false;
  //"idOperacion":2, Editar
  let _eUsuario = permisos.find((x:any) => x.idModulo === 3 && x.idOperacion === 2 && x.acceso.toString() === 'true');
  eUsuario = (_eUsuario !== undefined) ? true : false;

  //Asistente. "idModulo": 4
  //"idOperacion":1, Consultar
  let _cAsistente = permisos.find((x:any) => x.idModulo === 4 && x.idOperacion === 1 && x.acceso.toString() === 'true');
  cAsistente = (_cAsistente !== undefined) ? true : false;
  //"idOperacion":2, Editar
  let _eAsistente = permisos.find((x:any) => x.idModulo === 4 && x.idOperacion === 2 && x.acceso.toString() === 'true');
  eAsistente = (_eAsistente !== undefined) ? true : false;

  //Horizonte de Planeación. "idModulo": 1
  //"idOperacion":1, Consultar
  let _cHorizonte = permisos.find((x:any) => x.idModulo === 1 && x.idOperacion === 1 && x.acceso.toString() === 'true');
  cHorizonte = (_cHorizonte !== undefined) ? true : false;
  //"idOperacion":2, Editar
  let _eHorizonte = permisos.find((x:any) => x.idModulo === 1 && x.idOperacion === 2 && x.acceso.toString() === 'true');
  eHorizonte = (_eHorizonte !== undefined) ? true : false;
  //console.log(eHorizonte);
  //Plan Anual. "idModulo": 2
  //"idOperacion":1, Consultar
  let _cPlan = permisos.find((x:any) => x.idModulo === 2 && x.idOperacion === 1 && x.acceso.toString() === 'true');
  cPlan = (_cPlan !== undefined) ? true : false;
  //"idOperacion":2, Editar
  let _ePlan = permisos.find((x:any) => x.idModulo === 2 && x.idOperacion === 2 && x.acceso.toString() === 'true');
  ePlan = (_ePlan !== undefined) ? true : false;
  //"idOperacion":3, Editar Observaciones Superior
  let _eObsSup = permisos.find((x:any) => x.idModulo === 2 && x.idOperacion === 3 && x.acceso.toString() === 'true');
  eObsSup = (_eObsSup !== undefined) ? true : false;
  //"idOperacion":4, Editar Observaciones DPEI
  let _eObsDPEI = permisos.find((x:any) => x.idModulo === 2 && x.idOperacion === 4 && x.acceso.toString() === 'true');
  eObsDPEI = (_eObsDPEI !== undefined) ? true : false;
  //"idOperacion":5, fPlan
  let _fPlan = permisos.find((x:any) => x.idModulo === 2 && x.idOperacion === 5 && x.acceso.toString() === 'true');
  fPlan = (_fPlan !== undefined) ? true : false;
  //"idOperacion":6, fObsSup
  let _fObsSup = permisos.find((x:any) => x.idModulo === 2 && x.idOperacion === 6 && x.acceso.toString() === 'true');
  fObsSup = (_fObsSup !== undefined) ? true : false;
  //"idOperacion":7, fObsDPEI
  let _fObsDPEI = permisos.find((x:any) => x.idModulo === 2 && x.idOperacion === 7 && x.acceso.toString() === 'true');
  fObsDPEI = (_fObsDPEI !== undefined) ? true : false;
  //"idOperacion":8, otrasInstancias
  let _otrasInstancias = permisos.find((x:any) => x.idModulo === 2 && x.idOperacion === 8 && x.acceso.toString() === 'true');
  otrasInstancias = (_otrasInstancias !== undefined) ? true : false;

  //Evaluación. "idModulo": 5
  //"idOperacion":9, Consultar
  let _fAutoEval = permisos.find((x:any) => x.idModulo === 5 && x.idOperacion === 9 && x.acceso.toString() === 'true');
  fAutoEval = (_fAutoEval !== undefined) ? true : false;
  //"idOperacion":10, Editar
  let _fAproEval = permisos.find((x:any) => x.idModulo === 5 && x.idOperacion === 10 && x.acceso.toString() === 'true');
  fAproEval = (_fAproEval !== undefined) ? true : false;

}

export function getPermiso(aPermiso:any) {
  let permiso = false;
  //console.log(aPermiso);
  switch (aPermiso) {
    case 1: permiso = cUsuario; break;
    case 2: permiso = eUsuario; break;
    case 3: permiso = cAsistente; break;
    case 4: permiso = eAsistente; break;
    case 5: permiso = cHorizonte; break;
    case 6: permiso = eHorizonte; break;
    case 7: permiso = cPlan; break;
    case 8: permiso = ePlan; break;
    case 9: permiso = eObsSup; break;
    case 10: permiso = eObsDPEI; break;
    case 11: permiso = fPlan; break;
    case 12: permiso = fObsSup; break;
    case 13: permiso = fObsDPEI; break;
    case 14: permiso = otrasInstancias; break;
    case 15: permiso = fAutoEval; break;
    case 16: permiso = fAproEval; break;
    default: break;
  }
  //console.log(permiso);
  return permiso;
}

export function permiso(x:any) {
  console.log(x);
}

export function dtLanguage() {
    var l = {
        "lengthMenu": "Mostrar _MENU_ por p&aacute;gina",
        "zeroRecords": "No se encontr&oacute; registro",
        "info": "P&aacute;gina _PAGE_ de _PAGES_",
        "infoEmpty": "",
        "infoFiltered": "",
        "search": "Buscar",
        "processing": "Procesando...",
        "emptyTable": "No hay datos disponibles en la tabla",
        "infoPostFix": "",
        "url": "",
        "infoThousands": ",",
        "loadingRecords": "Cargando...",
        "paginate": {
            "first": "Primero",
            "last": "Ultimo",
            "next": ">",
            "previous": "<"
        },
        "aria": {
            "sortAscending": ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
        }
    };
    return l;
}
