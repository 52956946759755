import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class InvitadoService {

  constructor(private http: HttpClient) { }

  getInvitados() {
    return this.http.get(config.apiUrl+'/Invitado');
  }

  cambiarContra(data){
    return this.http.put(config.apiUrl+'/Invitado/contrasena/cambiar', data);
  }

  resetContra(){
    return this.http.get(config.apiUrl+'/Invitado/contrasena/reset');
  }

  limpiarActividad(idUsuario){
    return this.http.get(config.apiUrl+'/Invitado/actividades/limpiar/'+idUsuario);
  }

  borrarActividad(){
    return this.http.get(config.apiUrl+'/Invitado/actividades/limpiar');
  }

}
