<!--Contenedor-->
<div class="container align-items-center flex-column">

    <!--Encabezado-->
    <div class="text-center pt-2">
      <h4 class="title-section">Preguntas</h4>
    </div>
  
    <!--Listado-->
    <div class="row justify-content-between py-2">
      <div class="col-md-4 col-sm-12">
          <h5 class="title-subsection">&nbsp;</h5>
      </div>
      <div class="col-md-4 col-sm-12 text-right">
          <button class="btn btn-danger" (click)="nuevo(content)">Agregar</button>
      </div>
    </div>
  
    <table class="table table-striped text-center table-fixed">
        <thead class="bg-secondary text-white">
        <tr>
          <th scope="col" class="encabezado">#</th>
          <!--th scope="col" class="encabezado">idPregunta</th-->
          <th scope="col" class="encabezado">Descripción</th>
          <th scope="col" class="encabezado">Acciones</th>          
        </tr>
      </thead>
      <tbody>
        <tr #item *ngFor="let item of Preguntas; let i = index">
          <td scope="row">{{ i+1 }}</td>
          <!--td class="w-10">{{ item.idPregunta }}</td-->
          <td class="w-10">{{ item.descripcion }}</td>
          <td>
            <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
              <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot class="tfoot-ibero text-center bg-secondary text-white">
        <tr>
          <td colspan="8">{{ (Preguntas)?.length }} Registros</td>
        </tr>
      </tfoot>
    </table>
  </div>
  
  <!--Formulario-->
  
  <ng-template #content let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Pregunta</div>
        <div class="card-body text-dark">
  
          <div class=" card-body row">
            <div class="col-12">
              <form [formGroup]="forma">
                <div class="row text-dark">
                  <input class="form-control" type="hidden" formControlName="idPregunta"
                         [(ngModel)]="model.idPregunta">                      
                </div>

                <div class="form-group form-row">
                  <label for="descripcion">Descripción:</label>
                  <input class="form-control" type="text" formControlName="descripcion"
                         [(ngModel)]="model.descripcion">
                </div>

                <div class="row text-dark pt-3">
                  <div class="col-lg-12">
                    <div class="buttonSelect text-center">
                      <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                      <button class="btn btn-danger" (click)="guardar()">Guardar</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>