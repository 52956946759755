<div class="container">
    <div class="row mt-5">
        <div class="col-md-12 mb-5 text-center">
            <h1 class="pb-3" style="border-bottom:3px solid #5a7377">BIENVENIDAS y BIENVENIDOS</h1>
            <p class="font-weight-bold mb-0 text-danger">{{ Cuenta.cuenta + ' - ' + Cuenta.nombres + ' ' + Cuenta.aPaterno + ' ' + Cuenta.aMaterno }}</p>
        </div>
        <div class="col-md-4">
            <img src="../../../../assets/img/nacho1.png" class="img-fluid">
        </div>
        <div class="col-md-8">
            <a *ngIf="Usuario.idPerfil != 10" (click)="irInicio()" style="cursor:pointer" class="text-danger h6"><fa-icon [icon]="['fas','arrow-circle-left']"></fa-icon> Volver a Inicio</a>
            <!--iframe style="width:100%;height:100%" width="560" height="315" src="https://www.youtube.com/embed/43YiyeZSzfE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe-->
            <div *ngFor="let item of Guia" style="margin-top: 50px; margin-bottom: 70px;">
                <div class="card w-100">
                    <div class="card-header h4">{{ item.nombre }}</div>
                    <div class="card-body" style="overflow-y:auto; max-height: 500px">
                        <div [innerHtml]="item.descripcion" class="text-justify"></div>
                        <h5 class="mb-4">Módulos:</h5>
                        <a *ngFor="let itm of item.modulos" class="btn btn-danger" (click)="modulo(item, itm)">{{ itm.nombre }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>