//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
    Component, OnInit, ViewChild, Input, ViewChildren,
    QueryList, AfterViewInit, ElementRef
  } from '@angular/core';
  import { Router } from '@angular/router';
  import { Observable, Subject } from 'rxjs';
  import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
  import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
  import { ToastrService } from 'ngx-toastr';
  import { NgxSpinnerService } from "ngx-spinner";
  import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
  //import { UsuarioService } from 'src/app/data-access/usuario.service';
  import { UsuarioService } from 'src/app/data-access/usuario.service';
  import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
  import * as myGlobals from 'src/app/data-access/util';
  import { ModuloService } from 'src/app/data-access/modulo.service';
  //import {NgbCalendar, NgbDate} from "@ng-bootstrap/ng-bootstrap";
  
  @Component({
      selector: 'app-evaluacion',
      templateUrl: './estudiantes.component.html',
      styles: []
  })

  export class EstudiantesComponent implements OnInit {
    
      constructor(
          private router: Router,
          public fb: FormBuilder,
          private usuarioService: UsuarioService,
          private spinner: NgxSpinnerService,
          public modal: NgbModal,
      ) {
          
      }

      dtOptions: DataTables.Settings = {};
      dtTrigger: Subject<any> = new Subject<any>();
  
      sUsuario;
      ngOnInit(): void {
        this.dtOptions = {
            language: myGlobals.dtLanguage(),
            search: {
                smart: true
            }
        };
        this.addLista();
        //this.sort();
          //this.sUsuario = this.usuarioService.getUser();
      }
  
      Tutores;
      TutoresLista;
      addLista() {
          this.spinner.show();
          if (this.usuarioService.coordAcad()){
            this.usuarioService.obtenerEstudiantesCarrera().toPromise()
            .then(data => {
                this.Tutores = data;
                //Asignamos el universo de items al listado, mediante una entidad de filtro
                this.TutoresLista = this.Tutores.estudiantes;
                this.dtTrigger.next();
                this.spinner.hide();
            });
          }else{
            this.usuarioService.obtenerEstudiantes().toPromise()
            .then(data => {
                this.Tutores = data;
                //Asignamos el universo de items al listado, mediante una entidad de filtro
                this.TutoresLista = this.Tutores.estudiantes;
                this.dtTrigger.next();
                this.spinner.hide();
            });
          }
          
      }

      tutor;
      detalle(item) {
        this.spinner.show();  
        this.usuarioService.obtenerInformacionUsuarioSistema(item.idUsuario).toPromise().then(dt => {
            this.tutor = dt;
            localStorage.setItem('uCuentaEstudiante', JSON.stringify(this.tutor.perfiles.alumno));
            this.spinner.hide();  
            this.router.navigate(['/e-status']);  
        });
      }  
  
      formatDate(fecha) {
          var d = new Date(fecha);
          d.setHours(0, 0, 0, 0);
          return [
              ('0' + d.getDate()).slice(-2),
              ('0' + (d.getMonth() + 1)).slice(-2),
              d.getFullYear()
          ].join('/');
      }
  
      asc;
      sort() {
          const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;
  
          const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
              v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
          )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));
  
          // do the work...
          document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
              const table = th.closest('table');
              const tbody = table.querySelector('tbody');
              Array.from(tbody.querySelectorAll('tr'))
                  .sort(
                      comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc)
                  )
                  .forEach(tr => tbody.appendChild(tr));
          })));
      }
  }
  