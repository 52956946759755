<div class="pit-container">
    <div class="pit-card">
        <div class="pit-card-info">
            <div class="pit-card-name">
                <span class="pit-card-welcome">Hola!</span>
                <h2>MANUEL ALEJANDRO<br>GUERRERO MARTINEZ</h2>
                <h4>Coordinador de Carrera</h4>
            </div>
            <div class="pit-card-data">
                <div class="pit-card-data-content">
                    <div class="pit-card-data-row">
                        <h3>Cuenta</h3>
                        <p>26903</p>
                    </div>
                    <div class="pit-card-data-row">
                        <h3>Carrera</h3>
                        <p>COMUNICACIÓN</p>
                    </div>
                    <div class="pit-card-data-row">
                        <h3>Coordinación</h3>
                        <p>COMUNICACIÓN</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="pit-card-progress" style="background: #f5a6b2">
            <h2 class="text-center mb-4 mt-2">Tutores Asignados</h2>
            <div class="row">
                <div class="col-md-6">
                    <h2><a href="#" class="text-dark">Rosalia Perez<fa-icon [icon]="['fas','long-arrow-alt-right']" class="ml-2"></fa-icon></a></h2>
                </div>
                <div class="col-md-6">
                    <h2><a href="#" class="text-dark">Roman Mena<fa-icon [icon]="['fas','long-arrow-alt-right']" class="ml-2"></fa-icon></a></h2>
                </div>
                <div class="col-md-6">
                    <h2><a href="#" class="text-dark">Jeronimo Martinez<fa-icon [icon]="['fas','long-arrow-alt-right']" class="ml-2"></fa-icon></a></h2>
                </div>
                <div class="col-md-6">
                    <h2><a href="#" class="text-dark">Gisela Bolaños<fa-icon [icon]="['fas','long-arrow-alt-right']" class="ml-2"></fa-icon></a></h2>
                </div>
                <div class="col-md-6">
                    <h2><a href="#" class="text-dark">Aya Cerdan<fa-icon [icon]="['fas','long-arrow-alt-right']" class="ml-2"></fa-icon></a></h2>
                </div>
                <div class="col-md-6">
                    <h2><a href="#" class="text-dark">Hamid Santiago<fa-icon [icon]="['fas','long-arrow-alt-right']" class="ml-2"></fa-icon></a></h2>
                </div>
                <div class="col-md-6">
                    <h2><a href="#" class="text-dark">Sebastiana Andujar<fa-icon [icon]="['fas','long-arrow-alt-right']" class="ml-2"></fa-icon></a></h2>
                </div>
            </div>
        </div>
        <div class="pit-card-progress">
            <h2 class="text-white text-center mb-4 mt-2">Estudiantes Asignados</h2>
            <div class="d-flex justify-content-between mb-2">
                <div class="pit-card-progress-info">
                    <h2><a href="#">ANA GARCIA JUANOLA
                        <fa-icon [icon]="['fas','long-arrow-alt-right']" class="text-white"></fa-icon></a></h2>
                </div>
                <div class="d-flex">
                    <div class="pit-card-bar mr-2" style="width: 180px">
                        <div class="pit-card-bar-fill" style="width: 71%"></div>
                    </div>
                    <p>71% <span>(5 de 7)</span></p>
                </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="pit-card-progress-info">
                    <h2><a href="#">JUAN PABLO GONZÁLEZ DE OTEYZA
                        <fa-icon [icon]="['fas','long-arrow-alt-right']" class="text-white"></fa-icon></a></h2>
                </div>
                <div class="d-flex">
                    <div class="pit-card-bar mr-2" style="width: 180px">
                        <div class="pit-card-bar-fill" style="width: 57%"></div>
                    </div>
                    <p>57% <span>(4 de 7)</span></p>
                </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="pit-card-progress-info">
                    <h2><a href="#">DIEGO IVÁN GONZÁLEZ GÓMEZ
                        <fa-icon [icon]="['fas','long-arrow-alt-right']" class="text-white"></fa-icon></a></h2>
                </div>
                <div class="d-flex">
                    <div class="pit-card-bar mr-2" style="width: 180px">
                        <div class="pit-card-bar-fill" style="width: 85%"></div>
                    </div>
                    <p>85% <span>(6 de 7)</span></p>
                </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="pit-card-progress-info">
                    <h2><a href="#">ARIADNA HERNANDEZ RAMIREZ
                        <fa-icon [icon]="['fas','long-arrow-alt-right']" class="text-white"></fa-icon></a></h2>
                </div>
                <div class="d-flex">
                    <div class="pit-card-bar mr-2" style="width: 180px">
                        <div class="pit-card-bar-fill" style="width: 14%"></div>
                    </div>
                    <p>14% <span>(1 de 7)</span></p>
                </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <div class="pit-card-progress-info">
                    <h2><a href="#">JIMENA MARIA HURTADO ALVARADO
                        <fa-icon [icon]="['fas','long-arrow-alt-right']" class="text-white"></fa-icon></a></h2>
                </div>
                <div class="d-flex">
                    <div class="pit-card-bar mr-2" style="width: 180px">
                        <div class="pit-card-bar-fill" style="width: 42%"></div>
                    </div>
                    <p>42% <span>(3 de 7)</span></p>
                </div>
            </div>
        </div>
    </div>
</div>