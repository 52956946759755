//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { GuiaService } from 'src/app/data-access/guia.service';
import { RHService } from 'src/app/data-access/rh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';
import { UsuarioService } from 'src/app/data-access/usuario.service';

@Component({
  selector: 'app-e-evaluacion',
  templateUrl: './e-bienvenida.component.html',
  styles: [
  ]
})
export class EBienvenidaComponent implements OnInit {
  
    Usuario;
    Cuenta;

    constructor(
        private router: Router,
        public fb: FormBuilder,
        private guiaService: GuiaService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
        public us: UsuarioService
    ) {
    }

    ngOnInit(): void {
        this.Usuario = this.us.getCuenta();
        this.addLista();
        this.Cuenta = JSON.parse(localStorage.getItem('uCuenta') || '{}') || {};
    }

    Guia;
    addLista() {
        
        this.spinner.show();
        this.guiaService.getData().toPromise()
        .then(data => {
            this.Guia = data;
            this.spinner.hide();
            console.log(this.Guia);
        })
    }

    modulo(guia, modulo) {
        localStorage.setItem('eModulo', JSON.stringify(modulo));
        localStorage.setItem('eGuia', JSON.stringify(guia));
        this.router.navigate(['/e-modulo']); 
    }

    irInicio() {
        this.router.navigate(['/e-status']);  
    }  

}
