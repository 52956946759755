<div class="container">
    <div class="row mt-4">
        <div class="col-12">
            <!--p class="text-danger"><a class="text-danger font-weight-bold" href="/e-bienvenida">Inicio</a> > {{ Modulo.nombre }}</p-->
            <div id="breadcrumbs">
                <ul class="steps pl-0 mb-0">
                    <li class="step">                        
                        <a [routerLink]="['/e-bienvenida']">
                            {{ Guia.nombre }}
                        </a>
                    </li>
                    <li class="step inactive">
                        <a >
                            {{ Modulo.nombre }}
                        </a>
                    </li>
                </ul>                
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-4">
            
        </div>
        <div class="col-md-4">
            <h4 class="text-danger font-weight-bold text-center">Módulo</h4>
        </div>
        <div class="col-md-4 text-right">
            <fa-icon [icon]="['far','compass']" [ngClass]="seccion == 'objetivo' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('objetivo', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="Objetivo del Módulo"></fa-icon>
            <fa-icon [icon]="['far','clipboard']" [ngClass]="seccion == 'encontrar' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('encontrar', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="¿Qué vas a encontrar?"></fa-icon>
            <fa-icon [icon]="['fas','asterisk']" [ngClass]="seccion == 'competencias' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('competencias', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="Competencias Manresa"></fa-icon>
            <fa-icon [icon]="['fas','chart-line']" [ngClass]="seccion == 'temas' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('temas', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="Temas"></fa-icon>
            <fa-icon [icon]="['fas','globe']" [ngClass]="seccion == 'metas' ? 'text-danger h5' : 'text-dark'" (click)="cambiarVista('metas', 'tema')" class="mr-2" style="cursor:pointer" ngbTooltip="Metas"></fa-icon>
            <!--fa-icon [icon]="['far','star']" class="text-dark mr-2"></fa-icon>
            <fa-icon [icon]="['far','question-circle']" class="text-dark mr-2"></fa-icon-->
        </div>
    </div>
    <p class="font-weight-bold mb-0 text-danger" style="font-size: 14px">{{ Cuenta.cuenta + ' - ' + Cuenta.nombres + ' ' + Cuenta.aPaterno + ' ' + Cuenta.aMaterno }}</p>
    <div class="row mt-1" *ngIf="nivel=='tema'">
        <div class="col-md-12">
            <div class="bg-secondary text-center font-weight-bold text-white mb-4 py-2">
                {{ Modulo.nombre }}
            </div>
        </div>
        <div class="col-md-4">
            <img src="../../../../assets/img/nacho-caja.png" class="img-fluid">
        </div>
        <div class="col-md-8">
            <div class="arrows-nav text-right">
                <fa-icon *ngIf="seccion!='objetivo'" [icon]="['fas','arrow-circle-left']" (click)="prev('tema')" class="text-danger mr-2 h4" style="cursor:pointer" ngbTooltip="Anterior"></fa-icon>
                <fa-icon *ngIf="seccion!='metas'" [icon]="['fas','arrow-circle-right']" (click)="next('tema')" class="text-danger mr-2 h4" style="cursor:pointer" ngbTooltip="Siguiente"></fa-icon>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='objetivo'">
                <h5 class="mb-4">Objetivo:</h5>
                <div [innerHtml]="Modulo.objetivo"></div>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='encontrar'">
                <h5 class="mb-4">¿Qué vas a encontrar en este módulo?</h5>
                <div [innerHtml]="Modulo.queVasEncontrar"></div>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='competencias'">
                <h5 class="mb-4">Competencias Genéricas Manresa:</h5>
                <ul>
                    <li *ngFor="let item of Modulo.competenciasGlobales">
                        <div [innerHtml]="item.nombre"></div>
                    </li>
                </ul>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='temas'">
                <h5 class="mb-4">Temas:</h5>
                <ul>
                    <li class="mb-2 text-danger font-weight-bold" style="cursor:pointer" *ngFor="let item of Modulo.temas" (click)="tema(item)">{{ item.nombre }}</li>
                </ul>
            </div>
            <div class="h-100" style="overflow-y:auto; max-height: 500px" *ngIf="seccion=='metas'">
                <h5 class="mb-4">Metas:</h5>
                <div class="mb-2" *ngFor="let item of Modulo.temas">
                    <p class="font-weight-bold">{{ item.nombre }}</p>
                    <div [innerHtml]="item.metas"></div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="bg-secondary mt-4 py-3"></div>
        </div>
    </div>
</div>

<ng-template #actividades>
    <ul>
        <li class="mb-2 text-dark font-weight-bold" style="cursor:pointer" *ngFor="let item of Modulo.temas" (click)="tema(item)">{{ item.nombre }}</li>
    </ul>
</ng-template>