import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
//import { UsuarioService } from 'src/app/core/services/usuario.service';
import { RHService } from 'src/app/data-access/rh.service';
import { RolService } from 'src/app/data-access/rol.service';
//import { HorizonteAnualService } from 'src/app/data-access/horizonteAnual.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';

@Component({
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styles: [
  ]
})
export class RolComponent implements OnInit {
  forma: FormGroup;
  model;

  constructor(
    public fb: FormBuilder,
    private usuarioService: UsuarioService,
    private rolService: RolService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.addLista();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idRol: [0],
      clave: [''],
      descripcion: [''],
      activo: [true]
    })
    this.model = this.forma;
  }

  Rol;
  RolLista;
  addLista() {
    //this.spinner.show();
    this.rolService.getRol().toPromise()
      .then(data => {
        this.Rol = data;
        this.RolLista = this.Rol;
      })
  }

  editar(content, modelo) {
    this.model = modelo;
    this.modal.open(content, { size: 'xl' });
    //this.spinner.hide();
  }

  guardar() {
    this.spinner.show();
    console.log(this.forma.value);
    if (this.forma.controls["idRol"].value == undefined) {
      this.rolService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();this.spinner.hide();},
        (error) => {console.log(error);this.spinner.hide();});
    }
    else {
      this.rolService.updData(this.forma.controls["idRol"].value, this.forma.value).subscribe(
          (res) => {this.recargar();this.spinner.hide();},
          (error) => {console.log(error);this.spinner.hide();});
    }

  }
  
  recargar() {
    this.spinner.hide();
    this.modal.dismissAll();
    this.addLista();
  }

  asc;
  sort() {
    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

    const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
      v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
    )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    // do the work...
    document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
      const table = th.closest('table');
      const tbody = table.querySelector('tbody');
      Array.from(tbody.querySelectorAll('tr'))
        .sort(
          comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc)
        )
        .forEach(tr => tbody.appendChild(tr));
    })));
  }

}
