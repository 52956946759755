import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginComponent } from './login/login.component';
import { RegistroComponent } from './registro/registro.component';
import { PerfilComponent } from './perfil/perfil.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { faBell, faSortDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faBell as farBell, faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

@NgModule({
  declarations: [FooterComponent, NavMenuComponent, LoginComponent, RegistroComponent, PerfilComponent],
  imports: [
	CommonModule,
    FontAwesomeModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule 
  ],
  exports: [FooterComponent, NavMenuComponent, LoginComponent]
})
export class ShellModule { 
    constructor(library: FaIconLibrary) {
        library.addIcons(faBell, farBell, faSortDown, faCheck, faCommentDots);
    }
}
