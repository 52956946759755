//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef, TemplateRef
} from '@angular/core';
import { Router } from '@angular/router';
import {jsPDF} from 'jspdf';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { TemaService } from 'src/app/data-access/tema.service';
import { ActividadService } from 'src/app/data-access/actividad.service';
import { DinamicaTablaService } from 'src/app/data-access/dinamicaTabla.service';
import { DinamicaAcuerdoDesacuerdoService } from 'src/app/data-access/dinamicaAcuerdoDesacuerdo.service';
import { DinamicaFrecuenciaRealizacionService } from 'src/app/data-access/dinamicaFrecuenciaRealizacion.service';
import { DinamicaConcentracionSemanalService } from 'src/app/data-access/dinamicaConcentracionSemanal.service';
import { DinamicaPlaneacionSemestralService } from 'src/app/data-access/dinamicaPlaneacionSemestral.service';
import { ClaveSemanalService } from 'src/app/data-access/claveSemanal.service';
import { ClaveSemestralService } from 'src/app/data-access/claveSemestral.service';
import { RHService } from 'src/app/data-access/rh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';

import { ApexAxisChartSeries, ApexTitleSubtitle, ApexChart, ApexXAxis, ApexFill, ApexDataLabels, ChartComponent, ApexStroke,
    ApexPlotOptions, ApexYAxis, ApexMarkers } from "ng-apexcharts";

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    title: ApexTitleSubtitle;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    tooltip: any;
    plotOptions: ApexPlotOptions;
    fill: ApexFill;
    colors: string[];
    yaxis: ApexYAxis;
    markers: ApexMarkers;
    xaxis: ApexXAxis;
};

@Component({
    selector: 'app-e-evaluacion',
    templateUrl: './e-tema.component.html',
    styles: []
})

export class ETemaComponent implements OnInit {

    Cuenta;
    Guia;
    Modulo;
    Tema;
    Actividad;
    ActividadLoaded = false;

    seccion = 'objetivo';
    nivel = 'tema';
    imagen = 'inaki-armando.png';

    secciones = [
        'objetivo',
        'conceptos',
        'actividades',
        'encontrar',
        'competencias',
        'metas'
    ];
    seccionesAct = [
        'contexto',
        'experiencia',
        'reflexion',
        'accion',
        'evaluacion'
    ];

    seccionesActImg = [
        'nacho-escribiendo.png',
        'nacho-explicando.png',
        'inaki-pensando.png',
        'inaki-compu.png',
        'inaki-lupa.png'
    ]

    tablaRespuestas;
    respuestasCheck;
    respuestasOption;

    respuestasAlt;

    claveSemanalList;
    claveSemanal;

    claveForm: FormGroup;

    clavesTabla;

    fprincipal: FormGroup;
    mprincipal;
    mensajeTutor = {};
    mensajeAlumno = {};

    constructor(
        private router: Router,
        public fb: FormBuilder,
        private usuarioService: UsuarioService,
        private temaService: TemaService,
        private actividadService: ActividadService,
        private dinamicaTablaService: DinamicaTablaService,
        private dinamicaAcuerdoDesacuerdoService: DinamicaAcuerdoDesacuerdoService,
        private dinamicaFrecuenciaRealizacionService: DinamicaFrecuenciaRealizacionService,
        private dinamicaConcentracionSemanalService: DinamicaConcentracionSemanalService,
        private dinamicaPlaneacionSemestralService: DinamicaPlaneacionSemestralService,
        private claveSemanalService: ClaveSemanalService,
        private claveSemestralService: ClaveSemestralService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal
    ) {}

    @ViewChild('claveSemanal', { read: TemplateRef }) claveSemanalView:TemplateRef<any>;

    @ViewChild('actSemanal', {static: false}) actSemanal: ElementRef;
    @ViewChild('actSemestral', {static: false}) actSemestral: ElementRef;

    @ViewChild("chart", { static: false }) chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;
  
    ngOnInit(): void {
        this.Modulo = JSON.parse(localStorage.getItem('eModulo') || '{}') || [];
        this.Guia = JSON.parse(localStorage.getItem('eGuia') || '{}') || [];
        this.Tema = JSON.parse(localStorage.getItem('eTema') || '{}') || [];
        this.Actividad = JSON.parse(localStorage.getItem('eActividad') || 'null') || '';
        
        this.Cuenta = JSON.parse(localStorage.getItem('uCuenta') || '{}') || {};

        if(this.Actividad){
            this.getTema();
            this.cambiarVista('evaluacion', 'actividad', 'inaki-lupa.png', this.Actividad);
            localStorage.setItem('eActividad', null);
        }else{
            this.getTema();
        }

        this.iniciarForm();

        this.chartOptions = {
            series: [
              {
                name: "Resultado",
                data: [0, 0, 0, 0]
              }
            ],
            chart: {
              height: 450,
              type: "radar"
            },
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "16px",
              }
            },
            plotOptions: {
              radar: {
                size: 180,
                polygons: {
                  strokeColors: "#e9e9e9",
                  fill: {
                    colors: ["#f8f8f8", "#fff"]
                  }
                }
              }
            },
            title: {
              text: "Resultados - Plano Cartesiano"
            },
            colors: ["#FF4560"],
            markers: {
              size: 4,
              colors: ["#fff"],
              strokeColors: ["#FF4560"],
              strokeWidth: 2
            },
            tooltip: {
              y: {
                formatter: function(val) {
                  return val;
                }
              }
            },
            xaxis: {
              categories: [
                "Activo",
                "Reflexivo",
                "Teórico",
                "Pragmático"
              ],
              labels: {
                style: {
                  fontSize: "14px",
                  fontWeight: 'Bold'
                }
              }
            },
            yaxis: {
              tickAmount: 4,
              max: 20,
              min: 0
            }
        };
    }

    iniciarForm() {
        this.fprincipalIniciar();
    }

    fprincipalIniciar(){
        //Principal
        this.fprincipal = this.fb.group({
            idRetroalimentacionActividad: [0],
            comentadoPor: ['E'],
            comentario: ['', [Validators.required]]
        });
        //this.patch();
        this.mprincipal = this.fprincipal;
    }

    getTema() {
        this.spinner.show();    
        this.temaService.getDataById(this.Tema.idTema).toPromise().then(data => {
            this.Tema = data;
            this.spinner.hide();
        }); 
    }

    getActividad() {
        this.spinner.show(); 
        let dt;   
        this.mensajeTutor = {};
        this.mensajeAlumno = {};
        this.actividadService.getDataByIdAndUser(this.Actividad.idActividad, this.Cuenta.idUsuario).toPromise().then(data => {
            dt = data;
            this.Actividad = data;
            this.ActividadLoaded = true;
            switch (this.Actividad.idTipoActividad) {
                case 1: 
                    if(dt.dinamica[0].dinamicaTabla.filas.length){
                        this.tablaRespuestas = [];
                        let arr = [];
                        let col = dt.dinamica[0].dinamicaTabla.columnas.length;
                        dt.dinamica[0].dinamicaTabla.filas.forEach((x, i) => {
                            if((i + 1) % col == 0){
                                arr.push(x.respuesta);
                                this.tablaRespuestas.push(arr);
                                arr = [];
                            }else{
                                arr.push(x.respuesta);
                            }
                        });
                    }else{
                        let arr = [];
                        dt.dinamica[0].dinamicaTabla.columnas.forEach(element => {
                            arr.push('');
                        });
                        this.tablaRespuestas = [];
                        this.tablaRespuestas.push(arr);
                    }

                    if(dt.idActividad == 3){
                        this.dinamicaAcuerdoDesacuerdoService.getDataById(2, this.Cuenta.idUsuario).toPromise().then(data => {
                            this.respuestasAlt = data;
                            let resData = this.respuestasAlt.resultadosClasificaciones;
                            this.chartOptions.series = [{
                                name: "Resultado",
                                data: [resData[0].totalClasificacion, resData[1].totalClasificacion, resData[2].totalClasificacion, resData[3].totalClasificacion]
                            }];
                        });
                    }
                    break; 
                case 2:
                    this.respuestasCheck = [];
                    if(dt.dinamica[0].dinamicaAcuerdoDesacuerdo.respuestas.length){
                        dt.dinamica[0].dinamicaAcuerdoDesacuerdo.preguntas.forEach((element, idx) => {
                            this.respuestasCheck.push({idDinamicaAcuerdoDesacuerdoPregunta: element.idDinamicaAcuerdoDesacuerdoPregunta, respuesta: dt.dinamica[0].dinamicaAcuerdoDesacuerdo.respuestas[idx].respuesta});
                        });
                        let resData = dt.dinamica[0].dinamicaAcuerdoDesacuerdo.resultadosClasificaciones;
                        this.chartOptions.series = [{
                            name: "Resultado",
                            data: [resData[0].totalClasificacion, resData[1].totalClasificacion, resData[2].totalClasificacion, resData[3].totalClasificacion]
                        }];
                    }else{
                        dt.dinamica[0].dinamicaAcuerdoDesacuerdo.preguntas.forEach(element => {
                            this.respuestasCheck.push({idDinamicaAcuerdoDesacuerdoPregunta: element.idDinamicaAcuerdoDesacuerdoPregunta, respuesta: false});
                        });
                    }
                    break;
                case 3:
                    this.respuestasOption = [];
                    if(dt.dinamica[0].dinamicaFrecuenciaRealizacion.respuestas.length){
                        dt.dinamica[0].dinamicaFrecuenciaRealizacion.respuestas.forEach((element, idx) => {
                            this.respuestasOption.push({idDinamicaFrecuenciaRealizacionPregunta: element.idDinamicaFrecuenciaRealizacionPregunta, idDinamicaFrecuenciaRealizacionOpcion: element.idDinamicaFrecuenciaRealizacionOpcion});
                        });
                    }else{
                        dt.dinamica[0].dinamicaFrecuenciaRealizacion.preguntas.forEach(element => {
                            this.respuestasOption.push({idDinamicaFrecuenciaRealizacionPregunta: element.idDinamicaFrecuenciaRealizacionPregunta, idDinamicaFrecuenciaRealizacionOpcion: false});
                        });
                    }
                    break;
                case 4:
                    this.claveSemanalService.getData().toPromise().then(data => {
                        this.claveSemanalList = data;
                        this.clavesTabla = [];
                        let idx = 1;
                        let arrAux = [];
                        this.claveSemanalList.forEach(v => {
                            if(idx == 1){
                                idx++;
                                arrAux.push({clave: v.clave, significado: v.significado});
                            }else{
                                idx = 1;
                                arrAux.push({clave: v.clave, significado: v.significado});
                                this.clavesTabla.push(arrAux);
                                arrAux = [];
                            }
                        });
                        if(arrAux.length){
                            this.clavesTabla.push(arrAux);
                        }

                        this.respuestasOption = [];
                        dt.dinamica[0].dinamicaConcentracionSemanal.rangosHoras.forEach(v => {
                            let arr = [];
                            dt.dinamica[0].dinamicaConcentracionSemanal.diasSemana.forEach(val => {
                                arr.push(0);
                            });
                            this.respuestasOption.push(arr);
                        });

                        dt.dinamica[0].dinamicaConcentracionSemanal.respuestasOpciones.forEach(v => {
                            const i = dt.dinamica[0].dinamicaConcentracionSemanal.rangosHoras.map(function(e) { return e.idRangoHoraDia; }).indexOf(v.idRangoHoraDia);
                            const j = dt.dinamica[0].dinamicaConcentracionSemanal.diasSemana.map(function(e) { return e.idDiaSemana; }).indexOf(v.idDiaSemana);
                            this.respuestasOption[i][j] = {id: v.idClaveActividadSemanal, clave: this.claveSemanalList.find(x => x.idClaveActividadSemanal == v.idClaveActividadSemanal).clave, idRangoHoraDia: v.idRangoHoraDia, idDiaSemana: v.idDiaSemana};
                        });

                        this.respuestasCheck = [];
                        if(dt.dinamica[0].dinamicaConcentracionSemanal.respuestasPreguntas.length){
                            dt.dinamica[0].dinamicaConcentracionSemanal.preguntas.forEach((v, i) => {
                                this.respuestasCheck.push({
                                    idDinamicaConcentracionSemanalPregunta: v.idDinamicaConcentracionSemanalPregunta,
                                    respuesta: dt.dinamica[0].dinamicaConcentracionSemanal.respuestasPreguntas[i].respuesta,
                                    pregunta: v.pregunta
                                });
                            });
                        }else{
                            dt.dinamica[0].dinamicaConcentracionSemanal.preguntas.forEach((v, i) => {
                                this.respuestasCheck.push({
                                    idDinamicaConcentracionSemanalPregunta: v.idDinamicaConcentracionSemanalPregunta,
                                    respuesta: '',
                                    pregunta: v.pregunta
                                });
                            });
                        }
                    });

                    break;
                case 5:
                    this.claveSemestralService.getData().toPromise().then(data => {
                        this.claveSemanalList = data;
                        this.clavesTabla = [];
                        let idx = 1;
                        let arrAux = [];
                        this.claveSemanalList.forEach(v => {
                            if(idx == 1){
                                idx++;
                                arrAux.push({clave: v.clave, significado: v.significado});
                            }else{
                                idx = 1;
                                arrAux.push({clave: v.clave, significado: v.significado});
                                this.clavesTabla.push(arrAux);
                                arrAux = [];
                            }
                        });
                        if(arrAux.length){
                            this.clavesTabla.push(arrAux);
                        }

                        this.respuestasOption = [];
                        dt.dinamica[0].dinamicaPlaneacionSemestral.semanasSemestre.forEach(v => {
                            let arr = [];
                            dt.dinamica[0].dinamicaPlaneacionSemestral.diasSemana.forEach(val => {
                                arr.push(0);
                            });
                            this.respuestasOption.push(arr);
                        });

                        dt.dinamica[0].dinamicaPlaneacionSemestral.respuestasOpciones.forEach(v => {
                            const i = dt.dinamica[0].dinamicaPlaneacionSemestral.semanasSemestre.map(function(e) { return e.idSemanaSemestre; }).indexOf(v.idSemanaSemestre);
                            const j = dt.dinamica[0].dinamicaPlaneacionSemestral.diasSemana.map(function(e) { return e.idDiaSemana; }).indexOf(v.idDiaSemana);
                            this.respuestasOption[i][j] = {id: v.idClaveActividadSemestral, clave: this.claveSemanalList.find(x => x.idClaveActividadSemestral == v.idClaveActividadSemestral).clave, idRangoHoraDia: v.idSemanaSemestre, idDiaSemana: v.idDiaSemana};
                        });
                    });

                    break;
                default:
                    console.log('default');
            }

            this.mensajeTutor = this.Actividad.comentariosRetroalimentacion[0] || {comentario: ''};
            this.mensajeAlumno = this.Actividad.comentariosRetroalimentacion[1] || {comentario: ''};
            this.mprincipal.comentario = this.mensajeAlumno['comentario'];

            this.spinner.hide();
        }); 
    }

    cambiarVista(vista, nv, img = '', item = null){
        this.seccion = vista;
        this.nivel = nv;
        if(img){
            this.imagen = img;
            if(!this.ActividadLoaded || item.idActividad != this.Actividad.idActividad){
                this.Actividad = item;
                this.getActividad();
            }
        }
    }

    agregarFila(){
        let arr = [];
        this.Actividad.dinamica[0].dinamicaTabla.columnas.forEach(element => {
            arr.push('');
        });
        this.tablaRespuestas.push(arr);
    }

    borrarFila(i){
        this.tablaRespuestas.splice(i, 1);
    }

    selectOption(i, j){
        this.respuestasOption[i].idDinamicaFrecuenciaRealizacionOpcion = j;
    }

    seleccionClaveSemanal(item){
        if(this.Actividad.idTipoActividad == 4){
            this.claveSemanal = this.claveSemanalList.find(x => x.idClaveActividadSemanal == item);
        }else{
            this.claveSemanal = this.claveSemanalList.find(x => x.idClaveActividadSemestral == item);
        }
        this.claveForm.controls['significado'].setValue(this.claveSemanal.significado);
        this.claveForm.controls['clave'].setValue(this.claveSemanal.clave);
    }

    selectClaveSemanal(i, j, idRangoHoraDia, idDiaSemana){
        this.claveForm = this.fb.group({
            id: [0],
            clave: [''],
            significado: [''],
            dias: [j],
            horas: [i],
            idRangoHoraDia: [idRangoHoraDia],
            idDiaSemana: [idDiaSemana]
        });
        this.modal.open(this.claveSemanalView, { size: 'md' });
    }

    agregarClaveSemanal(){
        this.respuestasOption[this.claveForm.controls['horas'].value][this.claveForm.controls['dias'].value] = {clave: this.claveForm.controls['clave'].value, id: this.claveForm.controls['id'].value, idRangoHoraDia: this.claveForm.controls['idRangoHoraDia'].value, idDiaSemana: this.claveForm.controls['idDiaSemana'].value};
        this.modal.dismissAll();
    }

    eliminarClaveSemanal(){
        this.respuestasOption[this.claveForm.controls['horas'].value][this.claveForm.controls['dias'].value] = 0;
        this.modal.dismissAll();
    }

    public descargarSemanal() {
        this.spinner.show();
        const doc = new jsPDF({format: 'letter'});

        const html = '<h4 style="text-align:center">' + this.Actividad.nombre + '</h4>' +
            '<br><table>' +
                '<tbody>' +
                    '<tr>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Cuenta:</b> ' + this.Cuenta.cuenta + '</td>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Nombre:</b> ' + this.Cuenta.nombres + ' ' + this.Cuenta.aPaterno + ' ' + this.Cuenta.aMaterno + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Carrera:</b> ' + this.Cuenta.carrera + '</td>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Tutor:</b> ' + ( this.Cuenta.tutor ? this.Cuenta.tutor.nombres + ' ' + this.Cuenta.tutor.aPaterno + ' ' + this.Cuenta.tutor.aMaterno : '') + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Periodo:</b> ' + this.Cuenta.periodo + '</td>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Año:</b> ' + this.Cuenta.anio + '</td>' +
                    '</tr>' +
                '</tbody>' +
            '</table><br>';
    
        const pdfTable = this.actSemanal.nativeElement;

        doc.html(html + pdfTable.innerHTML, {
            callback: function (doc) {
                doc.save('tableToPdf.pdf');
            },
            margin: [14, 10, 10, 15],
            x: 0,
            y: 0,
            width: 180,
            windowWidth: 800
        });

        this.spinner.hide();
        
    }

    public descargarSemestral() {
        this.spinner.show();
        const doc = new jsPDF({format: 'letter'});

        const html = '<h4 style="text-align:center">' + this.Actividad.nombre + '</h4>' +
            '<br><table>' +
                '<tbody>' +
                    '<tr>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Cuenta:</b> ' + this.Cuenta.cuenta + '</td>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Nombre:</b> ' + this.Cuenta.nombres + ' ' + this.Cuenta.aPaterno + ' ' + this.Cuenta.aMaterno + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Carrera:</b> ' + this.Cuenta.carrera + '</td>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Tutor:</b> ' + ( this.Cuenta.tutor ? this.Cuenta.tutor.nombres + ' ' + this.Cuenta.tutor.aPaterno + ' ' + this.Cuenta.tutor.aMaterno : '') + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Periodo:</b> ' + this.Cuenta.periodo + '</td>' +
                        '<td style="width: 115mm;height: 11mm; vertical-align: middle; letter-spacing: 0.065em"><b>Año:</b> ' + this.Cuenta.anio + '</td>' +
                    '</tr>' +
                '</tbody>' +
            '</table><br>';
    
        const pdfTable = this.actSemestral.nativeElement;

        doc.html(html + pdfTable.innerHTML, {
            callback: function (doc) {
                doc.save('tableToPdf.pdf');
            },
            margin: [14, 10, 10, 15],
            x: 0,
            y: 0,
            width: 180,
            windowWidth: 800
        });

        this.spinner.hide();
        
    }

    guardarActividad(){
        this.spinner.show();

        switch (this.Actividad.idTipoActividad) {
            case 1: 
                let filas = [];
                this.tablaRespuestas.forEach((x, i) => {
                    this.Actividad.dinamica[0].dinamicaTabla.columnas.forEach((v, idx) => {
                        filas.push({idDinamicaTablaColumna: v.idDinamicaTablaColumna, posicionFila: i + 1, respuesta: x[idx]});
                    });
                });
                this.dinamicaTablaService.updData(this.Actividad.idActividad, {idActividad: this.Actividad.idActividad, filas: filas, idUsuario: this.Cuenta.idUsuario}).subscribe(
                    (res) => { 
                        this.Actividad.dinamica[0].dinamicaTabla.fechaRegistroFilasFormateado = res.fechaRegistroFilasFormateado;
                        this.spinner.hide();
                        this.toastr.success('La actividad se ha guardado con éxito', 'Actividad Guardada', {positionClass: 'toast-bottom-left'});
                    },
                    (error) => {
                        //this.spinner.hide();
                        console.log(error);
                        this.toastr.error(error.error.message);
                        this.spinner.hide();
                    }
                );
                break; 
            case 2:
                this.dinamicaAcuerdoDesacuerdoService.updData(this.Actividad.idActividad, {idActividad: this.Actividad.idActividad, respuestas: this.respuestasCheck, idUsuario: this.Cuenta.idUsuario}).subscribe(
                    (res) => { 
                        this.Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.fechaRegistroRespuestasFormateado = res.fechaRegistroRespuestasFormateado;
                        this.Actividad.dinamica[0].dinamicaAcuerdoDesacuerdo.resultadosClasificaciones = res.resultadosClasificaciones;

                        let resData = res.resultadosClasificaciones;
                        this.chartOptions.series = [{
                            name: "Resultado",
                            data: [resData[0].totalClasificacion, resData[1].totalClasificacion, resData[2].totalClasificacion, resData[3].totalClasificacion]
                        }];

                        this.spinner.hide();
                        this.toastr.success('La actividad se ha guardado con éxito', 'Actividad Guardada', {positionClass: 'toast-bottom-left'});
                    },
                    (error) => {
                        //this.spinner.hide();
                        console.log(error);
                        this.toastr.error(error.error.message);
                        this.spinner.hide();
                    }
                );
                break;
            case 3:
                this.dinamicaFrecuenciaRealizacionService.updData(this.Actividad.idActividad, {idActividad: this.Actividad.idActividad, respuestas: this.respuestasOption, idUsuario: this.Cuenta.idUsuario}).subscribe(
                    (res) => { 
                        this.Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.fechaRegistroRespuestasFormateado = res.fechaRegistroRespuestasFormateado;
                        this.Actividad.dinamica[0].dinamicaFrecuenciaRealizacion.resultadoPuntaje = res.resultadoPuntaje;
                        this.spinner.hide();
                        this.toastr.success('La actividad se ha guardado con éxito', 'Actividad Guardada', {positionClass: 'toast-bottom-left'});
                    },
                    (error) => {
                        //this.spinner.hide();
                        console.log(error);
                        this.toastr.error(error.error.message);
                        this.spinner.hide();
                    }
                );
                break;
            case 4:
                let resArr = [];
                this.respuestasOption.forEach(x => {
                    x.forEach(v => {
                        if(v){
                            resArr.push({
                                idRangoHoraDia: v.idRangoHoraDia,
                                idDiaSemana: v.idDiaSemana,
                                idClaveActividadSemanal: v.id,
                                posicionFila: 1
                            });
                        }
                    });
                });
                this.dinamicaConcentracionSemanalService.updData(this.Actividad.idActividad, {idActividad: this.Actividad.idActividad, respuestasOpciones: resArr, respuestasPreguntas: this.respuestasCheck, idUsuario: this.Cuenta.idUsuario}).subscribe(
                    (res) => { 
                        this.Actividad.dinamica[0].dinamicaConcentracionSemanal.fechaRegistroRespuestasOpcionesFormateado = res.fechaRegistroRespuestasOpcionesFormateado;
                        this.Actividad.dinamica[0].dinamicaConcentracionSemanal.resultadoActividades = res.resultadoActividades;
                        this.spinner.hide();
                        this.toastr.success('La actividad se ha guardado con éxito', 'Actividad Guardada', {positionClass: 'toast-bottom-left'});
                    },
                    (error) => {
                        //this.spinner.hide();
                        console.log(error);
                        this.toastr.error(error.error.message);
                        this.spinner.hide();
                    }
                );
                break;
            case 5:
                let resArr2 = [];
                this.respuestasOption.forEach(x => {
                    x.forEach(v => {
                        if(v){
                            resArr2.push({
                                idSemanaSemestre: v.idRangoHoraDia,
                                idDiaSemana: v.idDiaSemana,
                                idClaveActividadSemestral: v.id,
                                posicionFila: 1
                            });
                        }
                    });
                });
                this.dinamicaPlaneacionSemestralService.updData(this.Actividad.idActividad, {idActividad: this.Actividad.idActividad, respuestasOpciones: resArr2, idUsuario: this.Cuenta.idUsuario}).subscribe(
                    (res) => { 
                        this.Actividad.dinamica[0].dinamicaPlaneacionSemestral.fechaRegistroRespuestasOpcionesFormateado = res.fechaRegistroRespuestasOpcionesFormateado;
                        this.spinner.hide();
                        this.toastr.success('La actividad se ha guardado con éxito', 'Actividad Guardada', {positionClass: 'toast-bottom-left'});
                    },
                    (error) => {
                        //this.spinner.hide();
                        console.log(error);
                        this.toastr.error(error.error.message);
                        this.spinner.hide();
                    }
                );
                break;
            default:
                console.log('default');
        }
    }

    guardar() {
        var tutor = this.Actividad.comentariosRetroalimentacion[0].idTutor;
        this.spinner.show();
        this.usuarioService.retroalimentacionActividad(tutor, this.Cuenta.idUsuario, this.Actividad.idActividad, this.fprincipal.value).subscribe(
            (res) => { 
                this.spinner.hide();
                this.mensajeAlumno = res.comentario;
                this.toastr.success('El mensaje ha sido enviado al tutor', 'Mensaje Enviado', {positionClass: 'toast-bottom-left'});
            },
            (error) => {
                //this.spinner.hide();
                console.log(error);
                this.toastr.error(error.error.message);
                this.spinner.hide();
        });
    }

    trackByFn(index, item) {
        return index;  
    }

    next(nv){
        this.nivel = nv;
        if(nv == 'tema'){
            this.seccion = this.secciones[this.secciones.indexOf(this.seccion) + 1];
        }else{
            this.seccion = this.seccionesAct[this.seccionesAct.indexOf(this.seccion) + 1];
            this.imagen = this.seccionesActImg[this.seccionesAct.indexOf(this.seccion)];
        }
    }

    prev(nv){
        this.nivel = nv;
        if(nv == 'tema'){
            this.seccion = this.secciones[this.secciones.indexOf(this.seccion) - 1];
        }else{
            this.seccion = this.seccionesAct[this.seccionesAct.indexOf(this.seccion) - 1];
            this.imagen = this.seccionesActImg[this.seccionesAct.indexOf(this.seccion)];
        }
    }

}
