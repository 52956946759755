import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { RespuestaService } from '../../../data-access/respuesta.service';
import { TipoRespuestaService } from '../../../data-access/tipoRespuesta.service';
import { UsuarioService } from '../../../data-access/usuario.service';

@Component({
  selector: 'app-respuesta',
  templateUrl: './respuesta.component.html',
  styles: [
  ]
})
export class RespuestaComponent implements OnInit {
  forma: FormGroup;
  model;
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private usuarioService: UsuarioService,
    private tipoRespuestaService: TipoRespuestaService,
    private respuestaService: RespuestaService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    this.iniciarForm();
  }

  Opciones;
  ngOnInit(): void {
    this.addRespuesta();
    this.addTipoRespuesta();
    /*
    this.Opciones = [
      {"texto":"Totalmente","valor":1},
      {"texto":"Mucho","valor":2},
      {"texto":"Regular","valor":3},
      {"texto":"Poco","valor":4},
      {"texto":"Nada","valor":5}
    ];
    this.Opciones.sort((a, b) => a.valor > b.valor ? 1 : -1);
    */
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idRespuesta: [0],
      idTipoRespuesta: [0],
      tipoRespuestaDescripcion: [''],
      descripcion: [0],
      opciones: [''],
      activo: [true]
    })
    this.model = this.forma;
  }

  Respuestas;
  addRespuesta(){
    this.spinner.show();
    this.respuestaService.getRespuesta().toPromise()
    .then(data => {
      this.spinner.hide();
      this.Respuestas = data;
    });
  }

  TipoRespuestas;
  addTipoRespuesta(){
    this.spinner.show();
    this.tipoRespuestaService.getTipoRespuesta().toPromise()
    .then(data => {
      this.spinner.hide();
      this.TipoRespuestas = data;
    });
  }  

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = true;
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    this.model = modelo;

    this.Opciones = JSON.parse(modelo.opciones || '[]');
    this.Opciones.sort((a, b) => a.valor > b.valor ? 1 : -1);
    this.modal.open(content,{size:'xl'});
    if(modelo.idTipoRespuesta!=2){
      document.getElementById("opc").classList.toggle("hide");
    }    
  }

  guardar() {
    this.spinner.show();
    //this.model.opciones = JSON.stringify(this.Opciones);
    this.forma.controls["opciones"].setValue(JSON.stringify(this.Opciones));
    console.log(this.forma.value);
    //return false;
    if (this.forma.controls["idRespuesta"].value == undefined) {
      this.respuestaService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();this.spinner.hide();},
        (error) => {console.log(error);this.spinner.hide();});
    }
    else {
      this.respuestaService.updData(this.forma.controls["idRespuesta"].value, this.forma.value).subscribe(
          (res) => {this.recargar();this.spinner.hide();},
          (error) => {console.log(error);this.spinner.hide();});
    }
    
  }
  recargar() {
    this.modal.dismissAll();
    this.addRespuesta();
  }

  addOpcion(){
    var texto = (<HTMLInputElement>document.getElementById("texto")).value;
    var valor = (<HTMLInputElement>document.getElementById("valor")).value;
    //console.log(valor);
    //Revisamos si la opción ya existe
    let valIn = this.Opciones.find(x => x.texto === texto);
    if(valIn!=undefined){
      this.Opciones.find(x => x.texto === texto).valor = valor;
    }
    else{
      //let id = this.Opciones.length-1;
      this.Opciones.push({'texto':texto,'valor':valor});
    }
    this.Opciones.sort((a, b) => a.valor > b.valor ? 1 : -1);
  }

  delOpcion(){
    var texto = (<HTMLInputElement>document.getElementById("texto")).value;
    let valIn = this.Opciones.find(x => x.texto === texto);
    const index: number = this.Opciones.indexOf(valIn);
    this.Opciones.splice(index, 1);
    (<HTMLInputElement>document.getElementById("texto")).value = '';
    (<HTMLInputElement>document.getElementById("valor")).value = '';
    //this.Opciones.sort((a, b) => a.valor < b.valor ? 1 : -1);
  }

  selOpcion($event){
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    let vals = text.split(" - ");
    //console.log(element);
    (<HTMLInputElement>document.getElementById("texto")).value = vals[0];
    (<HTMLInputElement>document.getElementById("valor")).value = vals[1];
    //console.log($event.target.value);
  }

  selTipoRespuesta($event){
    if($event.target.value!=2){
      document.getElementById("opc").classList.toggle("hide");
    }
  }


}