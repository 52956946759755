import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';


@Injectable({
  providedIn: 'root'
})
export class InscripcionService {
  user = [];
  constructor(private http: HttpClient) {
  }

  getInscripcion() {
      return this.http.get(config.apiUrl+'/Inscripcion');
  }

  getInscripcionAll() {
    return this.http.get(config.apiUrl+'/Inscripcion/getlist/0');
  }  

  //Alta
  addData(data:undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/Inscripcion/', data);
  }

  //Cambio
  updData(id:undefined, data:undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/Inscripcion/' + id, data);
  }     

}