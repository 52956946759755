import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class ActividadService {
  user = [];
  constructor(private http: HttpClient) {
  }

  getData() {
    return this.http.get(config.apiUrl+'/Actividad/');
  }

    getDataById(id:undefined) {
        return this.http.get(config.apiUrl+'/Actividad/' + id);
    }

    getDataByIdAndUser(id:undefined, user:any) {
        return this.http.get(config.apiUrl+'/Actividad/' + id + '/' + user);
    }

   //Alta
   addData(data:undefined): Observable<any> {
     return this.http.post(config.apiUrl + '/Actividad/', data);
   }

   //Cambio
   updData(id:undefined, data:undefined): Observable<any> {
     return this.http.put(config.apiUrl + '/Actividad/' + id, data);
   }

   dltData(id:undefined): Observable<any> {
    return this.http.delete(config.apiUrl + '/Actividad/' + id);
  }

}