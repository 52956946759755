import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InvitadoService } from 'src/app/data-access/invitado.service';

@Component({
  selector: 'app-actividades-invitado',
  templateUrl: './actividades-invitado.component.html',
  styleUrls: ['./actividades-invitado.component.css']
})
export class ActividadesInvitadoComponent implements OnInit {

  usuarios:any;
  idUsuario:number;

  constructor(private invitadoService: InvitadoService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.invitadoService.getInvitados().toPromise()
    .then((data:any)=>{
      this.usuarios = data;
    });
  }

  seleccionarId(idUsuario){
    this.idUsuario = idUsuario;
  }

  limpiarActividadad(){
    this.spinner.show();
    this.invitadoService.limpiarActividad(this.idUsuario).toPromise().then((data:any)=>{
      if(data.valido == true){
        this.toastr.success("Se actualizó correctamente", 'Actividades limpias', {positionClass: 'toast-bottom-left'})
      }else{
        this.toastr.error("Error al actualizar",'Error', {positionClass: 'toast-bottom-left'});
      }
      this.spinner.hide();
      $('#cerrarActividad').trigger('click');
    });
    this.spinner.hide();
  }

  borrarActividadad(){
    this.spinner.show();
    this.invitadoService.borrarActividad().toPromise().then((data:any)=>{
      if(data.valido == true){
        this.toastr.success("Se actualizó correctamente", 'Actividades limpias', {positionClass: 'toast-bottom-left'})
      }else{
        this.toastr.error("Error al actualizar",'Error', {positionClass: 'toast-bottom-left'});
      }
      this.spinner.hide();
      $('#cerrarBorrar').trigger('click');
    });
    this.spinner.hide();
  }



}
