//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { GuiaService } from 'src/app/data-access/guia.service';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { RHService } from 'src/app/data-access/rh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';

@Component({
  selector: 'app-e-evaluacion',
  templateUrl: './e-status.component.html',
  styles: [
  ]
})
export class EStatusComponent implements OnInit {
  
    Cuenta;
    isAlumno = false;

    constructor(
        private router: Router,
        public fb: FormBuilder,
        private guiaService: GuiaService,
        public usuarioService: UsuarioService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
    ) {}

    ngOnInit(): void {
        this.addLista();
        this.Cuenta = JSON.parse(localStorage.getItem('uCuentaEstudiante') || 'null') || '';
        if(!this.Cuenta){
            this.isAlumno = true;
            this.Cuenta = JSON.parse(localStorage.getItem('uCuenta') || '{}') || {};
        }
    }

    Guia;
    addLista() {
        this.spinner.show();
        this.guiaService.getData().toPromise().then(data => {
            this.Guia = data;
            this.Guia.forEach(x => {
                this.usuarioService.getStatusGuiaUsuario(x.idGuia, this.Cuenta.idUsuario).toPromise().then(dt => {
                    x.status = dt;
                });
            });
            this.spinner.hide();
        });
    }

    modulo(guia, modulo) {
        localStorage.setItem('eModulo', JSON.stringify(modulo));
        localStorage.setItem('eGuia', JSON.stringify(guia));
        this.router.navigate(['/e-modulo']);  
    } 
    
    irGuia(id){
        if(this.isAlumno){
            this.router.navigate(['/e-bienvenida']); 
        }else{
            localStorage.setItem('tGuia', id);
            this.router.navigate(['/e-resumen']); 
        }
    }

}
