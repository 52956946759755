import { Component, OnInit, Input } from '@angular/core';
//import { UsuarioService } from '../../../data-access/usuario.service';
import { InscripcionService } from '../../../data-access/inscripcion.service';
import { PeriodoService } from '../../../data-access/periodo.service';
import { IdiomaService } from 'src/app/data-access/idioma.service';
import { GrupoService } from '../../../data-access/grupo.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-inscripcion',
  templateUrl: './inscripcion.component.html',
  styles: [
  ]
})
export class InscripcionComponent implements OnInit {
  forma: FormGroup;
  model;
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private inscripcionService: InscripcionService,
    private periodoService: PeriodoService,
    private idiomaService: IdiomaService,
    private grupoService: GrupoService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.addInscripcion();
    this.addIdioma();
    this.addPeriodo();
    this.addGrupo();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idInscripcion: [0],
      idUsuario: [0],
      idIdioma:[0],
      idPeriodo:[0],
      idGrupo:[0],
      nombres: [''],
      aPaterno: [''],
      aMaterno: [''],
      nombreCompleto: [''],
      idiomaDescripcion: [''],
      periodoNombre: [''],
      grupoNombre: [''],
      comprobante: [''],
      validado: [false],
      nivel: [0],
      anio: [0]
    })
    this.model = this.forma;
  }

  Idiomas;
  addIdioma(){
    this.spinner.show();
    this.idiomaService.getIdioma().toPromise()
    .then(data => {
      this.Idiomas = data;
      this.spinner.hide();
    });    
  }  

  Periodos;
  addPeriodo(){
    this.spinner.show();
    this.periodoService.getPeriodo().toPromise()
    .then(data => {
      this.Periodos = data;
      this.spinner.hide();
    });    
  }
  
  Grupos;
  addGrupo(){
    this.spinner.show();
    this.grupoService.getGrupo().toPromise()
    .then(data => {
      this.Grupos = data;
      this.spinner.hide();
    });    
  }  
  


  
  Inscripciones;
  addInscripcion(){
    this.spinner.show();
    this.inscripcionService.getInscripcionAll().toPromise()
    .then(data => {
      this.spinner.hide();
      this.Inscripciones = data;
    });
  }

  nuevo(content) {
    this.iniciarForm();
    //this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    this.model = modelo;
    this.model.nombreCompleto = modelo.nombres + ' ' + modelo.aPaterno + ' ' + modelo.aMaterno;
    this.modal.open(content,{size:'xl'});
  }

  guardar() {
    this.spinner.show();
    console.log(this.forma.value);
    if (this.forma.controls["idInscripcion"].value == undefined) {
      this.inscripcionService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();this.spinner.hide();},
        (error) => {console.log(error);this.spinner.hide();});
    }
    else {
      this.inscripcionService.updData(this.forma.controls["idInscripcion"].value, this.forma.value).subscribe(
          (res) => {this.recargar();this.spinner.hide();},
          (error) => {console.log(error);this.spinner.hide();});
    }
  }

  recargar() {
    this.modal.dismissAll();
    this.addInscripcion()
  }
}

