import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class EvalSeccionService {
  user = [];
  constructor(private http: HttpClient) {
  }

  getEvalSeccion() {
    return this.http.get(config.apiUrl+'/EvalSeccion');
  }

   //Alta
   addData(data:undefined): Observable<any> {
     return this.http.post(config.apiUrl + '/EvalSeccion/', data);
   }

   //Cambio
   updData(id:undefined, data:undefined): Observable<any> {
     return this.http.put(config.apiUrl + '/EvalSeccion/' + id, data);
   }  
}