//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { GuiaService } from 'src/app/data-access/guia.service';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { RHService } from 'src/app/data-access/rh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';

@Component({
  selector: 'app-e-evaluacion',
  templateUrl: './e-status-tutor.component.html',
  styles: [
  ]
})
export class EStatusTutorComponent implements OnInit {
  
    Cuenta;
    isTutor = false;

    constructor(
        private router: Router,
        public fb: FormBuilder,
        private guiaService: GuiaService,
        private usuarioService: UsuarioService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
    ) {}

    ngOnInit(): void {
        this.Cuenta = JSON.parse(localStorage.getItem('uCuentaTutor') || 'null') || '';
        if(!this.Cuenta){
            this.isTutor = true;
            this.Cuenta = JSON.parse(localStorage.getItem('uCuenta') || '{}') || {};
        }
        this.addLista();
    }

    addLista() {
        this.spinner.show();
        if(this.Cuenta.alumnos){
            this.Cuenta.alumnos.forEach(x => {
                this.usuarioService.getStatusGlobalGuiaUsuario(x.idUsuario).toPromise().then(dt => {
                    x.status = dt;
                });
            });
        }
        this.spinner.hide();
    }
    
    alumno;
    irEstudiante(item) {
        this.usuarioService.obtenerInformacionUsuarioSistema(item).toPromise().then(dt => {
            this.alumno = dt;
            localStorage.setItem('uCuentaEstudiante', JSON.stringify(this.alumno.perfiles.alumno));
            this.router.navigate(['/e-status']);  
        });
    } 

}
