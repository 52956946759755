import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { GrupoService } from '../../../data-access/grupo.service';
import { UsuarioService } from '../../../data-access/usuario.service';

@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styles: [
  ]
})
export class GrupoComponent implements OnInit {
  forma: FormGroup;
  model;
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private usuarioService: UsuarioService,
    private grupoService: GrupoService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.addGrupo();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idGrupo: [0],
      nombre: [''],
      anio: [0],
      idIdioma: [0],
      idPeriodo: [0],
      nivel: [0],
      periodoNombre: [''],
      idiomaDescripcion: ['']
    })
    this.model = this.forma;
  }

  Grupos;
  addGrupo(){
    this.spinner.show();
    this.grupoService.getGrupo().toPromise()
    .then(data => {
      this.spinner.hide();
      this.Grupos = data;
    });
  }

  nuevo(content) {
    this.iniciarForm();
    //this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    this.model = modelo;
    this.modal.open(content,{size:'xl'});
  }

  guardar() {
    
    if (this.forma.controls["idGrupo"].value == undefined) {
      this.grupoService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
    else {
      this.grupoService.updData(this.forma.controls["idGrupo"].value, this.forma.value).subscribe(
          (res) => {this.recargar();},
          (error) => {console.log(error);});
    }
    
  }
  recargar() {
    this.modal.dismissAll();
    this.addGrupo();
  }
}


