<!--Contenedor-->
<div class="container align-items-center flex-column pb-5">
    <div class="row mt-4">
        <div class="col-12">
            <!--p class="text-danger"><a class="text-danger font-weight-bold" href="/e-bienvenida">Inicio</a> > {{ Modulo.nombre }}</p-->
            <div id="breadcrumbs">
                <ul class="steps pl-0 mb-0">
                    <li class="step">                        
                        <a [routerLink]="['/administracion']">
                            Inicio
                        </a>
                    </li>
                    <li class="step inactive">
                        <a >
                            Actividades
                        </a>
                    </li>
                </ul>                
            </div>
        </div>
    </div>
    <!--Encabezado-->
    <div class="text-center pt-3">
      <h4 class="title-section">Actividades</h4>
    </div>
  
    <!--Filtros-->
  
    <!--Listado-->
  
    <div class="row justify-content-between py-2">
      <div class="col-md-4 col-sm-12">
        <h5 class="title-subsection">&nbsp;</h5>
      </div>
      <div class="col-md-4 col-sm-12 text-right">
        <button class="btn btn-danger" (click)="nuevo(content)">Agregar</button>
      </div>
    </div>
  
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped text-center table-fixed">
      <thead class="bg-secondary text-white">
        <tr>
          <th scope="col" class="encabezado orden">Nombre</th>
          <th scope="col" class="encabezado orden">Fecha Registro</th>
          <th scope="col" class="encabezado orden">Estatus</th>
          <th scope="col" class="encabezado orden">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr #item *ngFor="let item of ActividadLista; let i = index">
          <td class="w-20">{{item.nombre}}</td>
          <td class="w-10">{{ item.fechaRegistroFormateado }}</td>
          <td class="w-10">
            {{ item.activo ? "Activo":"Inactivo" }}
          </td>
          <td class="w-10">
            <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
              <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
            </button>
            <button class="btn icon-btn" (click)="gestionar(item)" ngbTooltip="Gestionar">
                <fa-icon [icon]="['fas','cogs']" class="text-dark"></fa-icon>
            </button>
            <button class="btn icon-btn" (click)="eliminar(delete, item)" ngbTooltip="Eliminar">
              <fa-icon [icon]="['fas','trash-alt']" class="text-dark"></fa-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot class="tfoot-ibero text-center bg-secondary text-white">
        <tr>
          <td colspan="4">{{ (ActividadLista)?.length }} Registros</td>
        </tr>
      </tfoot>
    </table>
  </div>
  
  <!--Formulario-->
  
  <ng-template #content let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Actividad</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="fprincipal">
            <div class="row text-dark">
              <input class="form-control" type="hidden" formControlName="idActividad"
                [(ngModel)]="mprincipal.idActividad">
            </div>
  
            <div class="row text-dark">
              <div class="form-group col-lg-12">
                <label for="nombre">Nombre:</label>
                <input class="form-control" type="text" formControlName="nombre" [(ngModel)]="mprincipal.nombre">
              </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                  <label for="dirigidoA">Dirigido a:</label>
                  <input class="form-control" type="text" formControlName="dirigidoA" [(ngModel)]="mprincipal.dirigidoA">
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="conceptosClave">Conceptos Clave:</label>
                    <select class="form-control" formControlName='conceptosClave' (change)="onChangeConcepto($event.target.value)">
                        <!--option [ngValue]="0">--Seleccionar--</option-->
                        <option *ngFor="let item of ConceptoClave" [ngValue]="item.idConceptoClave" [value]="item.idConceptoClave">
                            {{ item.concepto }}
                        </option>
                    </select>
                </div>
                <div class="col-lg-12">
                    <ul>
                        <li *ngFor="let item of ConceptoClaveList">
                            {{ item.concepto }}
                            <button class="btn icon-btn" (click)="eliminarConcepto(item)" ngbTooltip="Eliminar">
                                <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="objetivo">Objetivo:</label>
                    <quill-editor class="d-block" formControlName="objetivo" [(ngModel)]="mprincipal.objetivo" id="objetivo"></quill-editor>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="bibliografiaRec">Bibliografía y Recursos:</label>
                    <quill-editor class="d-block" formControlName="bibliografiaRec" [(ngModel)]="mprincipal.bibliografiaRec" id="bibliografiaRec"></quill-editor>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="momentosTrayectoria">Momento de la trayectoria académica:</label>
                    <quill-editor class="d-block" formControlName="momentosTrayectoria" [(ngModel)]="mprincipal.momentosTrayectoria" id="momentosTrayectoria"></quill-editor>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="actividadesRelacionadas">Actividades Relacionadas:</label>
                    <select class="form-control" formControlName='actividadesRelacionadas' (change)="onChangeRelacionadas($event.target.value)">
                        <!--option [ngValue]="0">--Seleccionar--</option-->
                        <option *ngFor="let item of ActividadLista" [ngValue]="item.idActividad" [value]="item.idActividad">
                            {{ item.nombre }}
                        </option>
                    </select>
                </div>
                <div class="col-lg-12">
                    <ul>
                        <li *ngFor="let item of RelacionadasLista">
                            {{ item.nombre }}
                            <button class="btn icon-btn" (click)="eliminarRelacionada(item)" ngbTooltip="Eliminar">
                                <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="queVasEncontrar">¿Qué vas a encontrar?</label>
                    <quill-editor class="d-block" formControlName="queVasEncontrar" [(ngModel)]="mprincipal.queVasEncontrar" id="queVasEncontrar"></quill-editor>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="inicio">Presentación:</label>
                    <quill-editor class="d-block" formControlName="inicio" [(ngModel)]="mprincipal.inicio" id="inicio"></quill-editor>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="desarrollo">¿Qué vamos a aprender?:</label>
                    <quill-editor class="d-block" formControlName="desarrollo" [(ngModel)]="mprincipal.desarrollo" id="desarrollo"></quill-editor>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="cierre">¿Que vamos a hacer? (Parte 1):</label>
                    <quill-editor class="d-block" formControlName="cierre" [(ngModel)]="mprincipal.cierre" id="cierre"></quill-editor>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="cierre2">¿Que vamos a hacer? (Parte 2):</label>
                    <quill-editor class="d-block" formControlName="cierre2" [(ngModel)]="mprincipal.cierre2" id="cierre2"></quill-editor>
                </div>
            </div>


            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="alConcluir">Al concluir:</label>
                    <quill-editor class="d-block" formControlName="alConcluir" [(ngModel)]="mprincipal.alConcluir" id="alConcluir"></quill-editor>
                </div>
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="paraSaberMas">Para saber más:</label>
                    <quill-editor class="d-block" formControlName="paraSaberMas" [(ngModel)]="mprincipal.paraSaberMas" id="paraSaberMas"></quill-editor>
                </div>
            </div>
    
            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="tipoActividad">Tipo de Actividad:</label>
                    <select class="form-control" formControlName='idTipoActividad' [(ngModel)]="mprincipal.idTipoActividad" >
                        <!--option [ngValue]="0">--Seleccionar--</option-->
                        <option *ngFor="let item of TipoActividadLista" [ngValue]="item.idTipoActividad">
                            {{ item.nombre }}
                        </option>
                    </select>
                </div>
            </div>
            
            <div class="row text-dark pt-3">
              <div class="col-lg-12">
                <div class="buttonSelect text-center">
                  <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                  <button class="btn btn-danger" (click)="guardar()" [disabled]="fprincipal.invalid">Guardar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>


  <ng-template #gestion let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Actividad</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="factividad">
            <div class="row text-dark">
              <input class="form-control" type="hidden" formControlName="idActividad"
                [(ngModel)]="mactividad.idActividad">
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="columnas">Nombre de Columna</label>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text" class="form-control" formControlName="columnaNombre" [(ngModel)]="mactividad.columnaNombre">
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-danger" (click)="agregarColumna()">Agregar Columna</button>
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-12">
                    <label for="columnasA">Columnas Agregadas:</label>
                    <ul>
                        <li *ngFor="let item of ListaColumnas">
                            {{ item.nombre }}
                            <button class="btn icon-btn" (click)="eliminarColumna(item)" ngbTooltip="Eliminar">
                                <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            
            <div class="row text-dark pt-3">
              <div class="col-lg-12">
                <div class="buttonSelect text-center">
                  <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                  <button class="btn btn-danger" (click)="guardarColumnas()">Guardar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #acuerdoDesacuerdo let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Actividad</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="facuerdoDesacuerdo">
            <div class="row text-dark">
              <input class="form-control" type="hidden" formControlName="idActividad"
                [(ngModel)]="macuerdoDesacuerdo.idActividad">
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="columnas">Pregunta</label>
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <input type="text" class="form-control" formControlName="columnaNombre" [(ngModel)]="macuerdoDesacuerdo.columnaNombre">
                        </div>
                    </div>
                    <label for="">Clasificación</label>
                    <div class="row">
                        <div class="col-md-6">
                            <select class="form-control" formControlName="clasificacion" [(ngModel)]="macuerdoDesacuerdo.clasificacion">
                                <option value="ACTIVO">ACTIVO</option>
                                <option value="REFLEXIVO">REFLEXIVO</option>
                                <option value="TEÓRICO">TEÓRICO</option>
                                <option value="PRAGMÁTICO">PRAGMÁTICO</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-danger" (click)="agregarPregunta()">Agregar Pregunta</button>
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-12">
                    <label for="columnasA">Preguntas Agregadas:</label>
                    <ol>
                        <li *ngFor="let item of ListaColumnas">
                            {{ item.pregunta }} <b>({{ item.clasificacion }})</b>
                            <button class="btn icon-btn" (click)="eliminarPregunta(item)" ngbTooltip="Eliminar">
                                <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                            </button>
                        </li>
                    </ol>
                </div>
            </div>
            
            <div class="row text-dark pt-3">
              <div class="col-lg-12">
                <div class="buttonSelect text-center">
                  <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                  <button class="btn btn-danger" (click)="guardarPreguntas()">Guardar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #frecuenciaRealizacion let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Actividad</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="ffrecuencia">
            <div class="row text-dark">
              <input class="form-control" type="hidden" formControlName="idActividad"
                [(ngModel)]="mfrecuencia.idActividad">
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="columnas">Opcion</label>
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <input type="text" class="form-control" formControlName="columnaNombre" [(ngModel)]="mfrecuencia.columnaNombre">
                        </div>
                    </div>
                    <label for="columnas">Descripción</label>
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <input type="text" class="form-control" formControlName="columnaDescripcion" [(ngModel)]="mfrecuencia.columnaDescripcion">
                        </div>
                    </div>
                    <label for="">Valor</label>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text" class="form-control" formControlName="columnaValor" [(ngModel)]="mfrecuencia.columnaValor">
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-danger" (click)="agregarOpcion()">Agregar Opción</button>
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-12">
                    <label for="columnasA">Opciones Agregadas:</label>
                    <ul>
                        <li *ngFor="let item of ListaColumnas">
                            {{ item.nombre }}<br/><small>({{ item.descripcion }})</small><br/>Valor: <span>{{ item.valor }}</span>
                            <button class="btn icon-btn" (click)="eliminarOpcion(item)" ngbTooltip="Eliminar">
                                <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <hr>
            <div class="row text-dark mt-2">
                <div class="form-group col-lg-12">
                    <label for="">Preguntas</label>
                    <div class="row mb-2">
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="filaNombre" [(ngModel)]="mfrecuencia.filaNombre">
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-danger" (click)="agregarOpcionPregunta()">Agregar Pregunta</button>
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-12">
                    <label for="columnasA">Preguntas Agregadas:</label>
                    <ol>
                        <li *ngFor="let item of ListaFilas">
                            {{ item.pregunta }}
                            <button class="btn icon-btn" (click)="eliminarOpcionPregunta(item)" ngbTooltip="Eliminar">
                                <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                            </button>
                        </li>
                    </ol>
                </div>
            </div>
            
            <div class="row text-dark pt-3">
              <div class="col-lg-12">
                <div class="buttonSelect text-center">
                  <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                  <button class="btn btn-danger" (click)="guardarOpcionesPreguntas()">Guardar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #concentracionSemanal let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Actividad</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="fconcentracionSemanal">
            <div class="row text-dark">
              <input class="form-control" type="hidden" formControlName="idActividad"
                [(ngModel)]="mconcentracionSemanal.idActividad">
            </div>

            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="columnas">Días de la Semana</label>
                    <div class="row mb-2">
                        <div class="col">
                            <input type="checkbox" class="mr-2" [checked]="ListaColumnas[0].value" (change)="ListaColumnas[0].value = !ListaColumnas[0].value.value">
                            <label> Lunes</label>
                        </div>
                        <div class="col">
                            <input type="checkbox" class="mr-2" [checked]="ListaColumnas[1].value" (change)="ListaColumnas[1].value = !ListaColumnas[1].value">
                            <label> Martes</label>
                        </div>
                        <div class="col">
                            <input type="checkbox" class="mr-2" [checked]="ListaColumnas[2].value" (change)="ListaColumnas[2].value = !ListaColumnas[2].value">
                            <label> Miércoles</label>
                        </div>
                        <div class="col">
                            <input type="checkbox" class="mr-2" [checked]="ListaColumnas[3].value" (change)="ListaColumnas[3].value = !ListaColumnas[3].value">
                            <label> Jueves</label>
                        </div>
                        <div class="col">
                            <input type="checkbox" class="mr-2" [checked]="ListaColumnas[4].value" (change)="ListaColumnas[4].value = !ListaColumnas[4].value">
                            <label> Viernes</label>
                        </div>
                        <div class="col">
                            <input type="checkbox" class="mr-2" [checked]="ListaColumnas[5].value" (change)="ListaColumnas[5].value = !ListaColumnas[5].value">
                            <label> Sábado</label>
                        </div>
                        <div class="col">
                            <input type="checkbox" class="mr-2" [checked]="ListaColumnas[6].value" (change)="ListaColumnas[6].value = !ListaColumnas[6].value">
                            <label> Domingo</label>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="columnas">Rangos de Hora</label>
                    <div class="row mb-2">
                        <div class="col-md-4">
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[0].value" (change)="ListaFilas[0].value = !ListaFilas[0].value">
                            <label> 00:00 - 01:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[1].value" (change)="ListaFilas[1].value = !ListaFilas[1].value">
                            <label> 01:00 - 02:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[2].value" (change)="ListaFilas[2].value = !ListaFilas[2].value">
                            <label> 02:00 - 03:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[3].value" (change)="ListaFilas[3].value = !ListaFilas[3].value">
                            <label> 03:00 - 04:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[4].value" (change)="ListaFilas[4].value = !ListaFilas[4].value">
                            <label> 04:00 - 05:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[5].value" (change)="ListaFilas[5].value = !ListaFilas[5].value">
                            <label> 05:00 - 06:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[6].value" (change)="ListaFilas[6].value = !ListaFilas[6].value">
                            <label> 06:00 - 07:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[7].value" (change)="ListaFilas[7].value = !ListaFilas[7].value">
                            <label> 07:00 - 08:00</label><br>
                        </div>
                        <div class="col-md-4">
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[8].value" (change)="ListaFilas[8].value = !ListaFilas[8].value">
                            <label> 08:00 - 09:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[9].value" (change)="ListaFilas[9].value = !ListaFilas[9].value">
                            <label> 09:00 - 10:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[10].value" (change)="ListaFilas[10].value = !ListaFilas[10].value">
                            <label> 10:00 - 11:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[11].value" (change)="ListaFilas[11].value = !ListaFilas[11].value">
                            <label> 11:00 - 12:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[12].value" (change)="ListaFilas[12].value = !ListaFilas[12].value">
                            <label> 12:00 - 13:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[13].value" (change)="ListaFilas[13].value = !ListaFilas[13].value">
                            <label> 13:00 - 14:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[14].value" (change)="ListaFilas[14].value = !ListaFilas[14].value">
                            <label> 14:00 - 15:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[15].value" (change)="ListaFilas[15].value = !ListaFilas[15].value">
                            <label> 15:00 - 16:00</label><br>
                        </div>
                        <div class="col-md-4">
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[16].value" (change)="ListaFilas[16].value = !ListaFilas[16].value">
                            <label> 16:00 - 17:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[17].value" (change)="ListaFilas[17].value = !ListaFilas[17].value">
                            <label> 17:00 - 18:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[18].value" (change)="ListaFilas[18].value = !ListaFilas[18].value">
                            <label> 18:00 - 19:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[19].value" (change)="ListaFilas[19].value = !ListaFilas[19].value">
                            <label> 19:00 - 20:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[20].value" (change)="ListaFilas[20].value = !ListaFilas[20].value">
                            <label> 20:00 - 21:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[21].value" (change)="ListaFilas[21].value = !ListaFilas[21].value">
                            <label> 21:00 - 22:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[22].value" (change)="ListaFilas[22].value = !ListaFilas[22].value">
                            <label> 22:00 - 23:00</label><br>
                            <input type="checkbox" class="mr-2" [checked]="ListaFilas[23].value" (change)="ListaFilas[23].value = !ListaFilas[23].value">
                            <label> 23:00 - 00:00</label><br>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="columnas">Pregunta</label>
                    <div class="row mb-2">
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="pregunta" [(ngModel)]="mconcentracionSemanal.pregunta">
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-danger" (click)="agregarPreguntaC()">Agregar Pregunta</button>
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-12">
                    <label for="columnasA">Preguntas Agregadas:</label>
                    <ol>
                        <li *ngFor="let item of ListaPreguntas">
                            {{ item.pregunta }}
                            <button class="btn icon-btn" (click)="eliminarPreguntaC(item)" ngbTooltip="Eliminar">
                                <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                            </button>
                        </li>
                    </ol>
                </div>
            </div>
            
            <div class="row text-dark pt-3">
              <div class="col-lg-12">
                <div class="buttonSelect text-center">
                  <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                  <button class="btn btn-danger" (click)="guardarConcentracion()">Guardar</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #planeacionSemestral let-modal>
    <div class="modal-hidden">
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    
      <div class="modal-body">
        <div class="card border-dark mb-0">
          <div class="card-header text-white text-center bg-dark">Actividad</div>
          <div class="card-body text-dark">
    
            <form [formGroup]="fplaneacionSemestral">
              <div class="row text-dark">
                <input class="form-control" type="hidden" formControlName="idActividad"
                  [(ngModel)]="mplaneacionSemestral.idActividad">
              </div>
  
              <div class="row text-dark">
                  <div class="form-group col-lg-12">
                      <label for="columnas">Días de la Semana</label>
                      <div class="row mb-2">
                          <div class="col">
                              <input type="checkbox" class="mr-2" [checked]="ListaColumnas[0].value" (change)="ListaColumnas[0].value = !ListaColumnas[0].value.value">
                              <label> Lunes</label>
                          </div>
                          <div class="col">
                              <input type="checkbox" class="mr-2" [checked]="ListaColumnas[1].value" (change)="ListaColumnas[1].value = !ListaColumnas[1].value">
                              <label> Martes</label>
                          </div>
                          <div class="col">
                              <input type="checkbox" class="mr-2" [checked]="ListaColumnas[2].value" (change)="ListaColumnas[2].value = !ListaColumnas[2].value">
                              <label> Miércoles</label>
                          </div>
                          <div class="col">
                              <input type="checkbox" class="mr-2" [checked]="ListaColumnas[3].value" (change)="ListaColumnas[3].value = !ListaColumnas[3].value">
                              <label> Jueves</label>
                          </div>
                          <div class="col">
                              <input type="checkbox" class="mr-2" [checked]="ListaColumnas[4].value" (change)="ListaColumnas[4].value = !ListaColumnas[4].value">
                              <label> Viernes</label>
                          </div>
                          <div class="col">
                              <input type="checkbox" class="mr-2" [checked]="ListaColumnas[5].value" (change)="ListaColumnas[5].value = !ListaColumnas[5].value">
                              <label> Sábado</label>
                          </div>
                          <div class="col">
                              <input type="checkbox" class="mr-2" [checked]="ListaColumnas[6].value" (change)="ListaColumnas[6].value = !ListaColumnas[6].value">
                              <label> Domingo</label>
                          </div>
                      </div>
                  </div>
              </div>
              
              <div class="row text-dark pt-3">
                <div class="col-lg-12">
                  <div class="buttonSelect text-center">
                    <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                    <button class="btn btn-danger" (click)="guardarPlaneacion()">Guardar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  </ng-template>
  
  <!--Perfiles-->
  <ng-template #delete let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Eliminar Actividad</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="fprincipal">
              <div class="row text-dark">
                  <input class="form-control" type="hidden" formControlName="idActividad"
                    [(ngModel)]="mprincipal.idActividad">
              </div>
  
              <div class="row text-dark">
                  <p>Se borrará la actividad: {{ mprincipal.nombre }}</p>
              </div>
  
              <div class="row text-dark pt-3">
                  <div class="col-lg-12">
                      <div class="buttonSelect text-center">
                          <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                          <button class="btn btn-danger" (click)="borrar()">Eliminar</button>
                      </div>
                  </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>