<div class="container-fluid  py-3">
    <!--div class="content">
      <img src="/assets/img/medio-sol.svg" alt="" style="float:left; margin-left:-100px; width: 250px; padding-top: 5%; z-index:2; position: absolute; ">
    </div--->
  <!--div class="content">
    <img src="/assets/img/medio-sol.svg" alt="" style="float:left; margin-left:-100px; width: 250px; padding-top: 100%; z-index:2; position: absolute; ">
  </div-->
    <!--div class="row">
    <div class="col-sm-12 col-md-12 text-center">
      <h1 class="titulo-registro text-danger py-5">
        Registro de Usuario
    </h1>
    </div>
  </div-->
    <div class="row justify-content-center py-1">
      <div class="col-sm-12 col-md-7 content-registro shadow">
        <!--div id="contiene-img-registro">
          <img src="./assets/img/registro/icono-usuario-registro.svg" alt="">
        </div-->
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-8">
            <div class="text-center">
              <h4 class="text-danger pt-1">Editar Perfil</h4>
            </div>

           <form [formGroup]="forma">
              <div class="row text-dark">
                <input class="form-control" type="hidden" formControlName="idUsuario" [(ngModel)]="model.idUsuario">
                <input class="form-control" type="hidden" formControlName="idRol" [(ngModel)]="model.idRol">
              </div
              >
              <div class="form-group">
                <label for="cuenta"><span class="text-danger">*</span>Matrícula</label>
                <input class="form-control" type="text" formControlName="cuenta" [(ngModel)]="model.cuenta">
                <div class="text-danger" *ngIf="forma.controls['cuenta'].touched && forma.controls['cuenta'].errors?.required">La matrícula es requerida</div>
                <div class="text-danger" *ngIf="forma.controls['cuenta'].touched && forma.controls['cuenta'].errors?.pattern">Ingrese solo números</div>
              </div>
              <div class="form-group form-row">
                <div class="col-sm-12 col-md-10">

                  <label for="idCarrera"><span class="text-danger">*</span>Carrera</label>
                  <select class="form-control" formControlName="idCarrera"
                  [(ngModel)]="model.idCarrera">
                    <!--option [ngValue]="0">--Seleccionar--</option-->
                    <option *ngFor="let item of Carreras"
                    [ngValue]="item.idCarrera">
                      {{ item.descripcion }}
                      </option>
                  </select>
                  <div class="text-danger" *ngIf="forma.controls['idCarrera'].touched && forma.controls['idCarrera'].errors?.required">La carrera es requerida</div>

                <!--label for="idCarrera"><span class="text-danger">*</span>Carrera</label>
                <input class="form-control" type="text" formControlName="idCarrera" [(ngModel)]="model.idCarrera">
                <div class="text-danger" *ngIf="forma.controls['idCarrera'].touched && forma.controls['idCarrera'].errors?.required">El apellido paterno es requerido</div-->
                </div>
                <div class="col-sm-12 col-md-2">
                  <label for="semestre"><span class="text-danger">*</span>Semestre</label>
                  <input class="form-control" type="text" formControlName="semestre" [(ngModel)]="model.semestre" maxlength="2">
                  <div class="text-danger" *ngIf="forma.controls['semestre'].touched && forma.controls['semestre'].errors?.pattern">Ingresa solo números</div>
                  <div class="text-danger" *ngIf="forma.controls['semestre'].touched && forma.controls['semestre'].errors?.minlength || forma.controls['semestre'].errors?.maxlength">El semestre debe contener 2 dígitos máximo</div>
                </div>
              </div>

             <div class="form-group">
              <label for="nombres"><span class="text-danger">*</span>Nombre(s)</label>
              <input class="form-control" type="text" formControlName="nombres" [(ngModel)]="model.nombres">
              <div class="text-danger" *ngIf="forma.controls['nombres'].touched && forma.controls['nombres'].errors?.required">El nombre es requerido</div>
              <div class="text-danger" *ngIf="forma.controls['nombres'].touched && forma.controls['nombres'].errors?.pattern">Ingresa solo letras</div>
            </div>
            <div class="form-group form-row">
              <div class="col-sm-12 col-md-6">
              <label for="aPaterno"><span class="text-danger">*</span>Apellido Paterno</label>
              <input class="form-control" type="text" formControlName="aPaterno" [(ngModel)]="model.aPaterno">
              <div class="text-danger" *ngIf="forma.controls['aPaterno'].touched && forma.controls['aPaterno'].errors?.required">El apellido paterno es requerido</div>
              <div class="text-danger" *ngIf="forma.controls['aPaterno'].touched && forma.controls['aPaterno'].errors?.pattern">Ingresa solo letras</div>
              </div>
              <div class="col-sm-12 col-md-6">
                <label for="aMaterno"><span class="text-danger">*</span>Apellido Materno</label>
                <input class="form-control" type="text" formControlName="aMaterno" [(ngModel)]="model.aMaterno">
                <div class="text-danger" *ngIf="forma.controls['aMaterno'].touched && forma.controls['aMaterno'].errors?.pattern">Ingresa solo letras</div>
              </div>
            </div>
             <div class="form-group form-row">
              <div class="col-sm-12 col-md-6">
                <label for="telefonoPersonal"><span class="text-danger">*</span>Teléfono Personal</label>
                <input class="form-control" type="text" formControlName="telefonoPersonal" [(ngModel)]="model.telefonoPersonal" maxlength="10">
                <div class="text-danger" *ngIf="forma.controls['telefonoPersonal'].touched && forma.controls['telefonoPersonal'].errors?.required">El teléfono es requerido</div>
                <div class="text-danger" *ngIf="forma.controls['telefonoPersonal'].touched && forma.controls['telefonoPersonal'].errors?.pattern">Ingrese solo números</div>
                <div class="text-danger" *ngIf="forma.controls['telefonoPersonal'].touched && forma.controls['telefonoPersonal'].errors?.minlength || forma.controls['telefonoPersonal'].errors?.maxlength">El teléfono debe contener 10 dígitos</div>
              </div>
              <div class="col-sm-12 col-md-6">
                <label for="telefonoCasa"><span class="text-danger">*</span>Teléfono Casa</label>
                <input class="form-control" type="text" formControlName="telefonoCasa" [(ngModel)]="model.telefonoCasa" maxlength="10">
                <div class="text-danger" *ngIf="forma.controls['telefonoCasa'].touched && forma.controls['telefonoCasa'].errors?.required">El teléfono es requerido</div>
                <div class="text-danger" *ngIf="forma.controls['telefonoCasa'].touched && forma.controls['telefonoCasa'].errors?.pattern">Ingrese solo números</div>
                <div class="text-danger" *ngIf="forma.controls['telefonoCasa'].touched && forma.controls['telefonoCasa'].errors?.minlength || forma.controls['telefonoCasa'].errors?.maxlength">El teléfono debe contener 10 dígitos</div>
               </div>
             </div>
             <div class="form-group">
              <label for="correo"><span class="text-danger">*</span>Correo electrónico</label>
              <input class="form-control" type="text" formControlName="correo" [(ngModel)]="model.correo">
              <div class="text-danger" *ngIf="forma.controls['correo'].touched && forma.controls['correo'].errors?.required">El correo es requerido</div>
              <div class="text-danger" *ngIf="forma.controls['correo'].touched && forma.controls['correo'].errors?.email">Ingrese un correo válido</div>
             </div>
             <div class="form-group form-row">
                <div class="col-sm-12 col-md-6">
                <label for="clave"><span class="text-danger">*</span>Contraseña:</label>
                <input class="form-control" type="password" formControlName="clave" [(ngModel)]="model.clave">
                <div class="text-danger" *ngIf="forma.controls['clave'].touched && forma.controls['clave'].errors?.required">La contraseña es requerida</div>
                </div>
              <div class="col-sm-12 col-md-6">
                <label><span class="text-danger">*</span>Confirmar Contraseña</label>
                <input type="password" class="form-control" formControlName="confirmaclave" [(ngModel)]="model.confirmaclave">
                <div class="text-danger" *ngIf="forma.controls['confirmaclave'].touched && forma.controls['confirmaclave'].errors?.required">La confirmación de contraseña es requerida</div>
                <div class="text-danger" *ngIf="forma.errors?.invalid"> Las contraseñas no coinciden</div>
              </div>
             </div>

            <div class="text-center">
              <!--button class="btn btn-danger" (click)="cancelar()">Cancelar</button-->
              <button type="submit" class="btn btn-danger my-2" (click)="guardar()" [disabled]="forma.invalid">Guardar</button>
             </div>

           </form>
          </div>
        </div>
    </div>
    </div>
  </div>
