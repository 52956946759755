//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ModuloService } from 'src/app/data-access/modulo.service';
import { RHService } from 'src/app/data-access/rh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';

@Component({
  selector: 'app-e-evaluacion',
  templateUrl: './e-modulo.component.html',
  styles: [
  ]
})
export class EModuloComponent implements OnInit {
  
    seccion = 'objetivo';
    nivel = 'tema';

    Cuenta;

    secciones = [
        'objetivo',
        'encontrar',
        'competencias',
        'temas',
        'metas'
    ];

    constructor(
        private router: Router,
        public fb: FormBuilder,
        private moduloService: ModuloService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
    ) {}
  
    ngOnInit(): void {
        this.Modulo = JSON.parse(localStorage.getItem('eModulo') || '{}') || [];
        this.Guia = JSON.parse(localStorage.getItem('eGuia') || '{}') || [];
        this.getGuia();

        this.Cuenta = JSON.parse(localStorage.getItem('uCuenta') || '{}') || {};
    }

    cambiarVista(vista, nv){
        this.seccion = vista;
        this.nivel = nv;
    }

    Guia;
    Modulo;
    getGuia() {
        this.spinner.show();    
        this.moduloService.getDataById(this.Modulo.idModulo).toPromise().then(data => {
            this.Modulo = data;
            this.spinner.hide();
        }); 
    }

    tema(item) {
        localStorage.setItem('eTema', JSON.stringify(item));
        this.router.navigate(['/e-tema']);  
    } 

    next(nv){
        this.nivel = nv;
        this.seccion = this.secciones[this.secciones.indexOf(this.seccion) + 1];
    }

    prev(nv){
        this.nivel = nv;
        this.seccion = this.secciones[this.secciones.indexOf(this.seccion) - 1];
    }
}
