<div class="container admin-page mt-5">
    <div class="row mt-4" *ngIf="!isTutor">
        <div class="col-12"><div id="breadcrumbs"><ul class="steps pl-0 mb-0"><li class="step"><a ng-reflect-router-link="/administracion" href="#/administracion"> Inicio </a></li><li class="step"><a ng-reflect-router-link="/administracion" href="#/administracion"> Reportes </a></li><li class="step inactive"><a> Honey-Alonso </a></li></ul></div></div>
    </div>
    <div class="text-center pt-3">
        <h4 class="title-section">Reporte Honey-Alonso</h4>
    </div>
    <br><br>
    <div class="row" *ngIf="!tutor">
        <div class="col-md-1 offset-3" style="text-align: right;">
            <label>Cohorte:</label>
        </div>
        <div class="col-md-4">
            <select id="cohorte" name="cohorte" [(ngModel)]="cohorte" style="width: 100%;" (change)="cargarAnios()">
                <option [ngValue]="0">Por asignación</option>
                <option [ngValue]="1">Por ingreso</option>
            </select>
        </div>
    </div>
    <div class="row" *ngIf="!tutor">
        <div class="col-md-1 offset-3" style="text-align: right;">
            <label>Año:</label>
        </div>
        <div class="col-md-4">
            <select id="anio" name="anio" [(ngModel)]="anio" style="width: 100%;">
                <option [ngValue]="0">Todos mis alumnos de tutoria</option>
                <option *ngFor="let anio of anios" [ngValue]="anio">{{anio}}</option>
            </select>
        </div>
    </div>
    <div class="row" *ngIf="!tutor">
        <div class="col-md-1 offset-3" style="text-align: right;">
            <label>Periodo:</label>
        </div>
        <div class="col-md-4">
            <select id="periodo" name="periodo" [(ngModel)]="periodo" style="width: 100%;">
                <option [ngValue]="-1">Todos</option>
                <option [ngValue]="0">Primavera</option>
                <option [ngValue]="1">Verano</option>
                <option [ngValue]="2">Otoño</option>
            </select>
        </div>
    </div>
    <div class="row" *ngIf="!coord && !tutor">
        <div class="col-md-1 offset-3" style="text-align: right;">
            <label>Area:</label>
        </div>
        <div class="col-md-4">
            <select id="area" name="area" [(ngModel)]="area" style="width: 100%;">
                <option [ngValue]="'DV-0'">TODAS</option>
                <option *ngFor="let area of areas" [ngValue]="area.cve_Area">
                    {{area.area}}
                </option>
            </select>
        </div>
    </div>
    <div class="row" *ngIf="!tutor">
        <div class="col-md-1 offset-3" style="text-align: right;">
            <label>Cuenta:</label>
        </div>
        <div class="col-md-4">
            <input type="text" id="cuenta" name="cuenta" [(ngModel)]="cuenta">
        </div>
    </div>
    <div class="row" *ngIf="tutor">
        <div class="col-md-1 offset-4" style="text-align: right;">
            <label>Cuenta:</label>
        </div>
        <div class="col-md-2">
            <input type="text" id="cuenta" name="cuenta" [(ngModel)]="cuenta" style="width: 100%;" placeholder="TODOS">
        </div>
    </div>
    <div class="row" *ngIf="!tutor">
        <div class="offset-4 col-md-4">
            <button class="btn btn-danger ng-star-inserted" style="margin-top: 10px;" (click)="buscar()">Generar Reporte</button>
        </div>
    </div>
    <div class="row" *ngIf="tutor">
        <div class="offset-4 col-md-4" style="text-align: center;">
            <button class="btn btn-danger ng-star-inserted" style="margin-top: 10px;" (click)="buscar()">Generar Reporte</button>
        </div>
    </div>
    <div style="width: 100%; overflow-y:scroll; visibility: hidden;">
        <table id="tabla_reporte" style="width: 100%;">
          <tr>
            <th>Cuenta</th>
            <th>Nombre</th>
            <th>Ingreso</th>
            <th>Asignación</th>
            <th>Carrera</th>
            <th>Tutor</th>
            <th>Departamento/Área de tutoría</th>
            <th>Tipo Tutoría</th>
            <th>Docente TIU</th>
            <th>Departamento/Área de Docente TIU</th>
            <th>CUPPI</th>
            <th>CRA</th>
            <th>Promedio Bachillerato</th>
            <th>Promedio General</th>
            <th>Créditos</th>
            <th>Escuela</th>
            <th>Puntaje</th>
            <th>Fecha de aplicación</th>
            <th>Valoración Estilo Activo</th>
            <th>Preferencia Estilo Activo</th>
            <th>Valoración Estilo Reflexivo</th>
            <th>Preferencia Estilo Reflexivo</th>
            <th>Valoración Estilo Teórico</th>
            <th>Preferencia Estilo Teórico</th>
            <th>Valoración Estilo Pragmático</th>
            <th>Preferencia Estilo Pragmático</th>
            <th *ngFor="let pregunta of preguntas">
                {{pregunta.posicionFila}}.-{{pregunta.pregunta}}
            </th>
          </tr>
          <tr *ngFor="let alumno of reporte">
            <td>{{alumno.cuenta}}</td>
            <td>{{alumno.nombre}}</td>
            <td>{{alumno.ingreso}}</td>
            <td>{{alumno.asignacion}}</td>
            <td>{{alumno.carrera}}</td>
            <ng-container *ngIf="alumno.tipo_tutoria != 'Transición' || alumno.area_tutor != 'TALLER DE INTEGRACIÓN UNIVERSITARIA'">
                <td>{{alumno.tutor}}</td>
                <td>{{alumno.area_tutor}}</td>
                <td>{{alumno.tipo_tutoria}}</td>
                <td></td>
                <td></td>
            </ng-container>
            <ng-container *ngIf="alumno.tipo_tutoria === 'Transición' && alumno.area_tutor === 'TALLER DE INTEGRACIÓN UNIVERSITARIA'">
                <td></td>
                <td></td>
                <td>{{alumno.tipo_tutoria}}</td>
                <td>{{alumno.tutor}}</td>
                <td>{{alumno.area_tutor}}</td>
            </ng-container>
            <td>{{alumno.cuppi}}</td>
            <td>{{alumno.cra}}</td>
            <td>{{alumno.promedio_bach}}</td>
            <td>{{alumno.promedio}}</td>
            <td>{{alumno.creditos_cursados}}</td>
            <td>{{alumno.escuela}}</td>
            <td>{{alumno.puntaje_real}}</td>
            <td>{{alumno.aplicacion}}</td>
            <td>{{alumno.activo}}</td>
            <td *ngIf="alumno.activo == 0"></td>
            <td *ngIf="alumno.activo > 0 && alumno.activo <= 6">MUY BAJA</td>
            <td *ngIf="alumno.activo == 7 || alumno.activo == 8">BAJA</td>
            <td *ngIf="alumno.activo >= 9 && alumno.activo <= 12">MODERADA</td>
            <td *ngIf="alumno.activo == 13 || alumno.activo == 14">ALTA</td>
            <td *ngIf="alumno.activo >= 15">MUY ALTA</td>
            <td>{{alumno.reflexivo}}</td>
            <td *ngIf="alumno.reflexivo == 0"></td>
            <td *ngIf="alumno.reflexivo > 0 && alumno.reflexivo <= 10">MUY BAJA</td>
            <td *ngIf="alumno.reflexivo >= 11 && alumno.reflexivo <= 13">BAJA</td>
            <td *ngIf="alumno.reflexivo >= 14 && alumno.reflexivo <= 17">MODERADA</td>
            <td *ngIf="alumno.reflexivo == 18 || alumno.reflexivo == 19">ALTA</td>
            <td *ngIf="alumno.reflexivo >= 20">MUY ALTA</td>
            <td>{{alumno.teorico}}</td>
            <td *ngIf="alumno.teorico == 0"></td>
            <td *ngIf="alumno.teorico > 0 && alumno.teorico <= 6">MUY BAJA</td>
            <td *ngIf="alumno.teorico >= 7 && alumno.teorico <= 9">BAJA</td>
            <td *ngIf="alumno.teorico >= 10 && alumno.teorico <= 13">MODERADA</td>
            <td *ngIf="alumno.teorico == 14 || alumno.teorico == 15">ALTA</td>
            <td *ngIf="alumno.teorico >= 16">MUY ALTA</td>
            <td>{{alumno.pragmatico}}</td>
            <td *ngIf="alumno.pragmatico == 0"></td>
            <td *ngIf="alumno.pragmatico > 0 && alumno.pragmatico <= 8">MUY BAJA</td>
            <td *ngIf="alumno.pragmatico == 9 || alumno.pragmatico == 10">BAJA</td>
            <td *ngIf="alumno.pragmatico >= 11 && alumno.pragmatico <= 13">MODERADA</td>
            <td *ngIf="alumno.pragmatico == 14 || alumno.pragmatico == 15">ALTA</td>
            <td *ngIf="alumno.pragmatico >= 16">MUY ALTA</td>
            <ng-container *ngFor="let respuesta of alumno.respuestas">
                <td *ngIf="respuesta === '1'" style="text-align: center;">X</td>
                <td *ngIf="respuesta === '0'"> </td>
            </ng-container>
          </tr>
        </table>
      </div>
</div>
