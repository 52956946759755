import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { NgApexchartsModule } from 'ng-apexcharts';

//import {MatStepperModule} from '@angular/material/stepper';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { faCircle, faSquare, faKey, faChartLine, faAsterisk, faGlobe, faMapMarkerAlt, faBalanceScale, faRunning, faHeart, faTimes, faArrowCircleLeft, faArrowCircleRight, faLongArrowAltRight, faMedal, faStar } from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle, faSquare as farSquare, faCompass, faClipboard, faStar as farStar, faQuestionCircle, faSmileWink, faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faStackOverflow, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faTrashAlt, faCheckCircle, faTimesCircle, faEdit, faTrash,
  faCalendarCheck, faSitemap, faLink, faMapMarked, faMap, faPlusCircle, faSave, faDoorOpen, faWindowClose } from '@fortawesome/free-solid-svg-icons';
/*
import { HorizontePlanComponent } from './horizonte-plan/horizonte-plan.component';
import { HorizonteAnualComponent } from './horizonte-anual/horizonte-anual.component';
import { PlanInstitucionalComponent } from './plan-institucional/plan-institucional.component';
import { NgxSummernoteModule } from 'ngx-summernote';

import { RolComponent } from './rol/rol.component';
import { UsuarioComponent } from './usuario/usuario.component';
*/
import { EBienvenidaComponent } from './e-bienvenida/e-bienvenida.component';
import { EStatusComponent } from './e-status/e-status.component';
import { EStatusTutorComponent } from './e-status-tutor/e-status-tutor.component';
import { EStatusCoordinadorComponent } from './e-status-coordinador/e-status-coordinador.component';
import { EResumenComponent } from './e-resumen/e-resumen.component';
import { EAsignarActividadComponent } from './e-asignar-actividad/e-asignar-actividad.component';
import { ETemaComponent } from './e-tema/e-tema.component';
import { EModuloComponent } from './e-modulo/e-modulo.component';
import { EEvaluacionDetalleComponent } from './e-evaluacion-detalle/e-evaluacion-detalle.component';
import { EEvaluacionSeccionComponent } from './e-evaluacion-seccion/e-evaluacion-seccion.component';
import { EInscripcionComponent  } from './e-inscripcion/e-inscripcion.component';
//import { EInscripcionComponent } from './e-inscripcion/e-inscripcion.component';

@NgModule({
  //declarations: [HorizontePlanComponent, HorizonteAnualComponent, UsuarioComponent, PlanInstitucionalComponent, RolComponent],
  declarations: [EBienvenidaComponent, EStatusComponent, EStatusTutorComponent, EStatusCoordinadorComponent, EResumenComponent, EAsignarActividadComponent, ETemaComponent, EModuloComponent, EEvaluacionDetalleComponent, EEvaluacionSeccionComponent, EInscripcionComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgApexchartsModule
    /*MatStepperModule
    /*,NgxSummernoteModule*/
  ],
  providers: [{
    provide: NgbDateAdapter,
    useClass: NgbDateNativeAdapter
  }],
  //exports: [HorizontePlanComponent, HorizonteAnualComponent, UsuarioComponent, PlanInstitucionalComponent]
  //exports: [UsuarioComponent, RolComponent]
})
export class EstudianteModule {

  constructor(library: FaIconLibrary) {
    library.addIcons(faCircle, faSquare, farCircle, farSquare, faStackOverflow, faGithub,
      faMedium, faTrashAlt, faCheckCircle, farCheckCircle, faTimesCircle, faEdit, faTrashAlt, faTrash, faCalendarCheck,
      faSitemap, faLink, faMapMarked, faMap, faPlusCircle, faHeart, faSave, faRunning, faDoorOpen, faBalanceScale, faWindowClose, faCompass, faSmileWink, faMapMarkerAlt, faKey, faChartLine, faClipboard, faAsterisk, faGlobe, faStar, faQuestionCircle, faTimes, faArrowCircleLeft, faArrowCircleRight, faLongArrowAltRight, faMedal, farStar);
  }

}
