//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef, TemplateRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, Form } from '@angular/forms';
//import { UsuarioService } from 'src/app/data-access/usuario.service';
import { ActividadService } from 'src/app/data-access/actividad.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';
import { ConceptoClaveService } from 'src/app/data-access/conceptoClave.service';
import { TipoActividadService } from 'src/app/data-access/tipoActividad.service';
import { DinamicaTablaService } from 'src/app/data-access/dinamicaTabla.service';
import { DinamicaAcuerdoDesacuerdoService } from 'src/app/data-access/dinamicaAcuerdoDesacuerdo.service';
import { DinamicaFrecuenciaRealizacionService } from 'src/app/data-access/dinamicaFrecuenciaRealizacion.service';
import { DinamicaConcentracionSemanalService } from 'src/app/data-access/dinamicaConcentracionSemanal.service';
import { DinamicaPlaneacionSemestralService } from 'src/app/data-access/dinamicaPlaneacionSemestral.service';
import { DataTableDirective } from 'angular-datatables';
//import {NgbCalendar, NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-evaluacion',
    templateUrl: './actividad.component.html',
    styles: []
})
export class ActividadComponent implements OnInit {
    fprincipal: FormGroup;
    factividad: FormGroup;
    facuerdoDesacuerdo: FormGroup;
    ffrecuencia: FormGroup;
    fconcentracionSemanal: FormGroup;
    fplaneacionSemestral: FormGroup;

    mprincipal;
    mactividad;
    macuerdoDesacuerdo;
    mfrecuencia;
    mconcentracionSemanal;
    mplaneacionSemestral;

    @ViewChild('gestion', { read: TemplateRef }) gestion:TemplateRef<any>;
    @ViewChild('acuerdoDesacuerdo', { read: TemplateRef }) acuerdoDesacuerdo:TemplateRef<any>;
    @ViewChild('frecuenciaRealizacion', { read: TemplateRef }) frecuenciaRealizacion:TemplateRef<any>;
    @ViewChild('concentracionSemanal', { read: TemplateRef }) concentracionSemanal:TemplateRef<any>;
    @ViewChild('planeacionSemestral', { read: TemplateRef }) planeacionSemestral:TemplateRef<any>;

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;
  
    constructor(
        private router: Router,
        public fb: FormBuilder,
        private actividadService: ActividadService,
        private conceptoClaveService: ConceptoClaveService,
        private tipoActividadService: TipoActividadService,
        private dinamicaTablaService: DinamicaTablaService,
        private dinamicaAcuerdoDesacuerdoService: DinamicaAcuerdoDesacuerdoService,
        private dinamicaFrecuenciaRealizacionService: DinamicaFrecuenciaRealizacionService,
        private dinamicaConcentracionSemanalService: DinamicaConcentracionSemanalService,
        private dinamicaPlaneacionSemestralService: DinamicaPlaneacionSemestralService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
    ) {
        this.iniciarForm();
    }

    sUsuario;
    ngOnInit(): void {
        this.dtOptions = {
            language: myGlobals.dtLanguage()
        };
        this.addLista();
        this.addConceptoClave();
        this.addTipoActividad();
        this.sort();
        //this.sUsuario = this.usuarioService.getUser();
    }

    iniciarForm() {
        this.fprincipalIniciar();
        this.factividadIniciar();
        this.facuerdoDesacuerdoIniciar();
        this.ffrecuenciaIniciar();
        this.fconcentracionSemanalIniciar();
        this.fplaneacionSemestralIniciar();
        this.ConceptoClaveList = [];
        this.RelacionadasLista = [];
    }

    fprincipalIniciar(){
        //Principal
        this.fprincipal = this.fb.group({
            idActividad: [0],
            idTipoActividad: [''],
            nombre: ['', [Validators.required]],
            dirigidoA: [''],
            queVasEncontrar: [''],
            conceptosClave: [[]],
            objetivo: [''],
            bibliografiaRec: [''],
            momentosTrayectoria: [''],
            actividadesRelacionadas: [[]],
            inicio: [''],
            desarrollo: [''],
            cierre: [''],
            cierre2: [''],
            alConcluir: [''],
            paraSaberMas: ['']
        });
        //this.patch();
        this.mprincipal = this.fprincipal;
    }

    ListaColumnas;
    ListaFilas;
    ListaPreguntas;

    factividadIniciar(){
        //Principal
        this.factividad = this.fb.group({
            idActividad: [0],
            columnas: [[]],
            columnaNombre: ['']
        });
        //this.patch();
        this.mactividad = this.factividad;
    }

    facuerdoDesacuerdoIniciar(){
        this.facuerdoDesacuerdo = this.fb.group({
            idActividad: [0],
            preguntas: [[]],
            columnaNombre: [''],
            clasificacion: ['']
        });
        //this.patch();
        this.macuerdoDesacuerdo = this.facuerdoDesacuerdo;
    }

    ffrecuenciaIniciar(){
        this.ffrecuencia = this.fb.group({
            idActividad: [0],
            opciones: [[]],
            preguntas: [[]],
            columnaNombre: [''],
            columnaDescripcion: [''],
            columnaValor: [''],
            filaNombre: ['']
        });
        //this.patch();
        this.mfrecuencia = this.ffrecuencia;
    }

    fconcentracionSemanalIniciar(){
        this.fconcentracionSemanal = this.fb.group({
            idActividad: [0],
            diasSemana: [[]],
            rangosHoras: [[]],
            preguntas: [[]],
            pregunta: ['']
        });
        //this.patch();
        this.mconcentracionSemanal = this.fconcentracionSemanal;
    }

    fplaneacionSemestralIniciar(){
        this.fplaneacionSemestral = this.fb.group({
            idActividad: [0],
            diasSemana: [[]]
        });
        //this.patch();
        this.mplaneacionSemestral = this.fplaneacionSemestral;
    }

    Actividad;
    ActividadLista;
    RelacionadasLista = [];
    addLista() {
        this.spinner.show();
        this.actividadService.getData().toPromise()
        .then(data => {
            this.Actividad = data;
            //Asignamos el universo de items al listado, mediante una entidad de filtro
            this.ActividadLista = this.Actividad;
            this.rerender();
            this.spinner.hide();
        })
    }

    rerender(): void {
        if(this.dtElement.dtInstance){
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.dtTrigger.next();
            });
        }else{
            this.dtTrigger.next();
        }
    }

    ConceptoClave;
    ConceptoClaveList = [];
    addConceptoClave(){
        this.spinner.show();
        this.conceptoClaveService.getData().toPromise()
        .then(data => {
            this.ConceptoClave = data;
            this.spinner.hide();
        });    
    } 

    TipoActividad;
    TipoActividadLista;
    addTipoActividad() {
        this.spinner.show();
        this.tipoActividadService.getData().toPromise()
        .then(data => {
            this.TipoActividad = data;
            this.TipoActividadLista = this.TipoActividad;
            this.spinner.hide();
        })
    }

    recargar() {
        this.spinner.hide();
        this.modal.dismissAll();
        this.addLista();
    }  
    
    nuevo(content) {
        this.iniciarForm();
        //this.model.activo = 'S';
        this.modal.open(content, { size: 'xl' });
        //document.getElementById("mprincipal.cuenta").focus();
    }

    editar(content, modelo) {
        this.spinner.show();
        let dt;
        this.actividadService.getDataById(modelo.idActividad).toPromise().then(data => {
            dt = data;
            this.mprincipal = data;
            this.ConceptoClaveList = dt.conceptosClave;
            this.RelacionadasLista = dt.actividadesRelacionadas;
            this.modal.open(content, { size: 'xl' });
            this.spinner.hide();
        }); 
    }

    gestionar(modelo) {
        this.spinner.show();
        let dt;
        let content;
        this.actividadService.getDataById(modelo.idActividad).toPromise().then(data => {
            dt = data;
            switch (dt.idTipoActividad) {
                case 1: 
                    this.mactividad = dt.dinamica[0].dinamicaTabla;
                    this.ListaColumnas = dt.dinamica[0].dinamicaTabla.columnas;
                    content = this.gestion;
                    break; 
                case 2:
                    this.macuerdoDesacuerdo = dt.dinamica[0].dinamicaAcuerdoDesacuerdo;
                    this.ListaColumnas = dt.dinamica[0].dinamicaAcuerdoDesacuerdo.preguntas;
                    content = this.acuerdoDesacuerdo;
                    break;
                case 3:
                    this.mfrecuencia = dt.dinamica[0].dinamicaFrecuenciaRealizacion;
                    this.ListaColumnas = dt.dinamica[0].dinamicaFrecuenciaRealizacion.opciones;
                    this.ListaFilas = dt.dinamica[0].dinamicaFrecuenciaRealizacion.preguntas;
                    content = this.frecuenciaRealizacion;
                    break;
                case 4:
                    this.mconcentracionSemanal = dt.dinamica[0].dinamicaConcentracionSemanal;
                    
                    this.ListaColumnas = [];
                    for(let i = 0; i < 7; i++){
                        this.ListaColumnas.push({idDiaSemana: i + 1, value: dt.dinamica[0].dinamicaConcentracionSemanal.diasSemana.find(x => x.idDiaSemana == i + 1)});
                    }

                    this.ListaFilas = [];
                    for(let i = 0; i < 24; i++){
                        this.ListaFilas.push({idRangoHoraDia: i + 1, value: dt.dinamica[0].dinamicaConcentracionSemanal.rangosHoras.find(x => x.idRangoHoraDia == i + 1)});
                    }
                    
                    this.ListaPreguntas = dt.dinamica[0].dinamicaConcentracionSemanal.preguntas;
                    content = this.concentracionSemanal;
                    break;
                case 5:
                    this.mplaneacionSemestral = dt.dinamica[0].dinamicaPlaneacionSemestral;
                    
                    this.ListaColumnas = [];
                    for(let i = 0; i < 7; i++){
                        this.ListaColumnas.push({idDiaSemana: i + 1, value: dt.dinamica[0].dinamicaPlaneacionSemestral.diasSemana.find(x => x.idDiaSemana == i + 1)});
                    }

                    content = this.planeacionSemestral;
                    break;
                        
                default:
                    console.log('default');
            }

            this.modal.open(content, { size: 'xl' });
            this.spinner.hide();
        }); 
    }

    agregarColumna(){
        this.ListaColumnas.push({nombre: this.factividad.controls["columnaNombre"].value});
        this.factividad.controls["columnaNombre"].setValue('');
    }

    agregarPregunta(){
        this.ListaColumnas.push({pregunta: this.facuerdoDesacuerdo.controls["columnaNombre"].value, clasificacion: this.facuerdoDesacuerdo.controls["clasificacion"].value});
        this.facuerdoDesacuerdo.controls["columnaNombre"].setValue('');
        this.facuerdoDesacuerdo.controls["clasificacion"].setValue('');
        console.log(this.ListaColumnas);
    }

    agregarOpcion(){
        this.ListaColumnas.push({nombre: this.ffrecuencia.controls["columnaNombre"].value, descripcion: this.ffrecuencia.controls["columnaDescripcion"].value, valor: this.ffrecuencia.controls["columnaValor"].value});
        this.ffrecuencia.controls["columnaNombre"].setValue('');
        this.ffrecuencia.controls["columnaDescripcion"].setValue('');
        this.ffrecuencia.controls["columnaValor"].setValue('');
    }

    agregarOpcionPregunta(){
        this.ListaFilas.push({pregunta: this.ffrecuencia.controls["filaNombre"].value});
        this.ffrecuencia.controls["filaNombre"].setValue('');
    }

    agregarPreguntaC(){
        this.ListaPreguntas.push({pregunta: this.fconcentracionSemanal.controls["pregunta"].value});
        this.fconcentracionSemanal.controls["pregunta"].setValue('');
    }

    eliminar(content, modelo) {
        console.log(modelo);
        this.mprincipal = modelo;
        this.modal.open(content, { size: 'md' });
        //document.getElementById("mprincipal.cuenta").focus();
    }

    guardar() {
        this.spinner.show();
        let conceptos = [];
        this.ConceptoClaveList.forEach(x => {
            conceptos.push({idConceptoClave: x.idConceptoClave});
        });
        this.fprincipal.controls["conceptosClave"].setValue(conceptos); 
        let actividadesRelacionadas = [];
        this.RelacionadasLista.forEach(x => {
            actividadesRelacionadas.push({idActividad: x.idActividad});
        });
        this.fprincipal.controls["actividadesRelacionadas"].setValue(actividadesRelacionadas); 
        if (this.fprincipal.controls["idActividad"].value == undefined) {
            this.actividadService.addData(this.fprincipal.value).subscribe(
                (res) => { this.recargar(); },
                (error) => {
                    //this.spinner.hide();
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
            });
        }
        else {
            this.actividadService.updData(this.fprincipal.controls["idActividad"].value, this.fprincipal.value).subscribe(
                (res) => { this.recargar(); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
                });
        }

    }

    guardarColumnas() {
        if(this.mactividad.actividadResuelta){
            this.toastr.error('No se puede editar porque ya se ha respondido con la estructura actual');
            return;
        }
        this.spinner.show();
        let columnas = [];
        this.ListaColumnas.forEach(x => {
            columnas.push({nombre: x.nombre});
        });
        this.factividad.controls["columnas"].setValue(columnas); 
        this.dinamicaTablaService.updData(this.factividad.controls["idActividad"].value, this.factividad.value).subscribe(
            (res) => { this.recargar(); },
            (error) => {
                console.log(error);
                this.toastr.error(error.error.message);
                this.spinner.hide();
            });

    }

    guardarPreguntas() {
        if(this.macuerdoDesacuerdo.actividadResuelta){
            this.toastr.error('No se puede editar porque ya se ha respondido con la estructura actual');
            return;
        }
        this.spinner.show();
        this.facuerdoDesacuerdo.controls["preguntas"].setValue(this.ListaColumnas); 
        this.dinamicaAcuerdoDesacuerdoService.updData(this.facuerdoDesacuerdo.controls["idActividad"].value, this.facuerdoDesacuerdo.value).subscribe(
            (res) => { this.recargar(); },
            (error) => {
                console.log(error);
                this.toastr.error(error.error.message);
                this.spinner.hide();
            });

    }

    guardarOpcionesPreguntas() {
        if(this.mfrecuencia.actividadResuelta){
            this.toastr.error('No se puede editar porque ya se ha respondido con la estructura actual');
            return;
        }
        this.spinner.show();
        this.ffrecuencia.controls["opciones"].setValue(this.ListaColumnas); 
        this.ffrecuencia.controls["preguntas"].setValue(this.ListaFilas); 
        this.dinamicaFrecuenciaRealizacionService.updData(this.ffrecuencia.controls["idActividad"].value, this.ffrecuencia.value).subscribe(
            (res) => { this.recargar(); },
            (error) => {
                console.log(error);
                this.toastr.error(error.error.message);
                this.spinner.hide();
            });

    }

    guardarConcentracion() {
        if(this.mconcentracionSemanal.actividadResuelta){
            this.toastr.error('No se puede editar porque ya se ha respondido con la estructura actual');
            return;
        }
        this.spinner.show();
        let arr = [];
        this.ListaColumnas.forEach(x => {
            if(x.value){
                arr.push({idDiaSemana: x.idDiaSemana});
            }
        });
        this.fconcentracionSemanal.controls["diasSemana"].setValue(arr); 
        arr = [];
        this.ListaFilas.forEach(x => {
            if(x.value){
                arr.push({idRangoHoraDia: x.idRangoHoraDia});
            }
        });
        this.fconcentracionSemanal.controls["rangosHoras"].setValue(arr); 
        arr = [];
        this.ListaPreguntas.forEach(x => {
            arr.push({pregunta: x.pregunta});
        });
        this.fconcentracionSemanal.controls["preguntas"].setValue(arr); 
        this.dinamicaConcentracionSemanalService.updData(this.fconcentracionSemanal.controls["idActividad"].value, this.fconcentracionSemanal.value).subscribe(
            (res) => { this.recargar(); },
            (error) => {
                console.log(error);
                this.toastr.error(error.error.message);
                this.spinner.hide();
            });

    }

    guardarPlaneacion() {
        if(this.mplaneacionSemestral.actividadResuelta){
            this.toastr.error('No se puede editar porque ya se ha respondido con la estructura actual');
            return;
        }
        this.spinner.show();
        let arr = [];
        this.ListaColumnas.forEach(x => {
            if(x.value){
                arr.push({idDiaSemana: x.idDiaSemana});
            }
        });
        this.fplaneacionSemestral.controls["diasSemana"].setValue(arr); 
        
        this.dinamicaPlaneacionSemestralService.updData(this.fplaneacionSemestral.controls["idActividad"].value, this.fplaneacionSemestral.value).subscribe(
            (res) => { this.recargar(); },
            (error) => {
                console.log(error);
                this.toastr.error(error.error.message);
                this.spinner.hide();
            });

    }

    borrar() {
        this.spinner.show();
        console.log(this.fprincipal);
        if (this.fprincipal.controls["idActividad"].value != undefined) {
            this.actividadService.dltData(this.fprincipal.controls["idActividad"].value).subscribe(
                (res) => { this.recargar(); },
                (error) => {
                    console.log(error);
                    this.toastr.error(error.error.message);
                    this.spinner.hide();
                }
            );
        }
    }

    onChangeConcepto(deviceValue) {
        console.log(this.ConceptoClaveList);
        let res = this.ConceptoClaveList.filter(elem => elem.idConceptoClave == deviceValue);
        if(!res.length){
            let res = this.ConceptoClave.filter(elem => elem.idConceptoClave == deviceValue);
            this.ConceptoClaveList.push(res[0]);
        }
    } 

    onChangeRelacionadas(deviceValue) {
        let res = this.RelacionadasLista.filter(elem => elem.idActividad == deviceValue);
        if(!res.length && deviceValue != this.mprincipal.idActividad){
            let res = this.ActividadLista.filter(elem => elem.idActividad == deviceValue);
            this.RelacionadasLista.push(res[0]);
        }
    } 

    eliminarConcepto(item){
        this.ConceptoClaveList = this.ConceptoClaveList.filter(elem => elem.idConceptoClave !== item.idConceptoClave);
    }

    eliminarRelacionada(item){
        this.RelacionadasLista = this.RelacionadasLista.filter(elem => elem.idActividad !== item.idActividad);
    }

    eliminarColumna(item){
        this.ListaColumnas = this.ListaColumnas.filter(elem => elem.nombre !== item.nombre);
    }

    eliminarPregunta(item){
        this.ListaColumnas = this.ListaColumnas.filter(elem => elem.pregunta !== item.pregunta);
    }

    eliminarOpcion(item){
        this.ListaColumnas = this.ListaColumnas.filter(elem => elem.nombre !== item.nombre);
    }

    eliminarOpcionPregunta(item){
        this.ListaFilas = this.ListaFilas.filter(elem => elem.pregunta !== item.pregunta);
    }

    eliminarPreguntaC(item){
        this.ListaPreguntas = this.ListaPreguntas.filter(elem => elem.pregunta !== item.pregunta);
    }

    formatDate(fecha) {
        var d = new Date(fecha);
        d.setHours(0, 0, 0, 0);
        return [
            ('0' + d.getDate()).slice(-2),
            ('0' + (d.getMonth() + 1)).slice(-2),
            d.getFullYear()
        ].join('/');
    }

    asc;
    sort() {
        const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

        const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
            v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

        // do the work...
        document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
            const table = th.closest('table');
            const tbody = table.querySelector('tbody');
            Array.from(tbody.querySelectorAll('tr'))
                .sort(
                    comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc)
                )
                .forEach(tr => tbody.appendChild(tr));
        })));
    }
}
