import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class ModuloTemaService {
  user = [];
  constructor(private http: HttpClient) {
  }

    /*getData() {
        return this.http.get(config.apiUrl+'/ModuloTema/');
    }*/

   //Alta
    addData(data:undefined): Observable<any> {
        return this.http.post(config.apiUrl + '/ModuloTema/', data);
    }

   //Cambio
    /*updData(id:undefined, data:undefined): Observable<any> {
        return this.http.put(config.apiUrl + '/Tema/' + id, data);
    }*/

    dltData(id:undefined, data:undefined): Observable<any> {
        return this.http.request('DELETE', config.apiUrl + '/ModuloTema/' + id, {body: data});
    }

}