<div class="pit-container">
    <div class="pit-card mb-5">
        <div class="pit-card-info flex-column">
            <div class="pit-card-name" style="flex: 0 0 100%">
                <h2>{{ Cuenta.nombres }}<br>{{ Cuenta.aPaterno }} {{ Cuenta.aMaterno }}</h2>
                <h4>{{ Cuenta.perfil }}</h4>
            </div>
            <div class="pit-card-data py-2" style="flex: 0 0 100%">
                <div class="pit-card-data-content py-2" style="border: 0">
                    <div *ngFor="let item of Guia">
                        <h4 class="text-danger mb-3">{{ item.nombre }}</h4>
                        <div *ngFor="let itm of (item.modulos ? item.modulos : [])" class="pit-modulo-collapse">
                            <h5><button class="btn icon-btn" (click)="temas(itm)" ngbTooltip="Ver Temas" *ngIf="!itm.expand"><fa-icon [icon]="['fas','plus']" class="text-dark"></fa-icon></button>
                                <button class="btn icon-btn" (click)="temasCollapse(itm)" ngbTooltip="Ocultar Temas" *ngIf="itm.expand"><fa-icon [icon]="['fas','minus']" class="text-dark"></fa-icon></button>
                                Módulo: <span class="font-weight-normal">{{ itm.nombre }}</span>
                            </h5>
                            <div class="tema-title" *ngFor="let it of itm.temas; let j = index">
                                <button class="btn icon-btn" (click)="actividades(it)" ngbTooltip="Ver Actividades" *ngIf="!it.expand"><fa-icon [icon]="['fas','plus']" class="text-dark"></fa-icon></button>
                                <button class="btn icon-btn" (click)="actividadesCollapse(it)" ngbTooltip="Ocultar Actividades" *ngIf="it.expand"><fa-icon [icon]="['fas','minus']" class="text-dark"></fa-icon></button>
                                <span class="mr-2">Tema:</span> {{it.nombre}}
                                <div class="pl-5">
                                    <div *ngFor="let itt of (it.actividades ? it.actividades : []); let k = index" class="pit-card-activity">
                                        <h6>{{ itt.nombre }}</h6>
                                        <div class="pit-star">
                                            <button class="btn btn-danger" (click)="verEstudiantes(itt)">Asignar Estudiantes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #actividadModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{ Actividad.nombre }}</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-right mb-3">
            <button *ngIf="!marcados" class="btn btn-outline-danger" (click)="marcarTodos()">Marcar Todos</button>
            <button *ngIf="marcados" class="btn btn-outline-danger" (click)="desmarcarTodos()">Desmarcar Todos</button>
        </div>
        <div *ngFor="let item of AlumnosLista.estudiantes" class="pit-card-activity">
            <h6>{{ item.nombreCompleto }}</h6>
            <div class="pit-star">
                <fa-icon *ngIf="!item.asignado" [icon]="['far','star']" style="color: #ddd;" (click)="toggleSelection(item)"></fa-icon>
                <fa-icon *ngIf="item.asignado" [icon]="['fas','star']" style="color: #ff9800;" (click)="toggleSelection(item)"></fa-icon>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-light" (click)="modal.close()">Cerrar</button>
        <button class="btn btn-danger" (click)="asignar()">Guardar</button>
    </div>
</ng-template>