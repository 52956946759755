<!--Contenedor-->
<div class="container align-items-center flex-column">

    <!--Encabezado-->
    <div class="text-center pt-2">
      <h4 class="title-section">Grupos</h4>
    </div>
  
    <!--Listado-->
    <div class="row justify-content-between py-2">
      <div class="col-md-4 col-sm-12">
          <h5 class="title-subsection">&nbsp;</h5>
      </div>
      <div class="col-md-4 col-sm-12 text-right">
          <button class="btn btn-danger" (click)="nuevo(content)">Agregar</button>
      </div>
    </div>
  
    <table class="table table-striped text-center table-fixed">
        <thead class="bg-secondary text-white">
        <tr>
          <th scope="col" class="encabezado">#</th>
          <th scope="col" class="encabezado">idGrupo</th>
          <th scope="col" class="encabezado">nombre</th>
          <th scope="col" class="encabezado">Año</th>
          <th scope="col" class="encabezado">Periodo</th>
          <th scope="col" class="encabezado">Idioma</th>
          <th scope="col" class="encabezado">nivel</th>
          <th scope="col" class="encabezado">Acciones</th>          
        </tr>
      </thead>
      <tbody>
        <tr #item *ngFor="let item of Grupos; let i = index">
          <td scope="row">{{ i+1 }}</td>
          <td class="w-10">{{ item.idGrupo }}</td>
          <td class="w-10">{{ item.nombre }}</td>
          <td class="w-10">{{ item.anio }}</td>
          <td class="w-10">{{ item.periodoNombre }}</td>
          <td class="w-10">{{ item.idiomaDescripcion }}</td>
          <td class="w-10">{{ item.nivel }}</td>
          <td>
            <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
              <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot class="tfoot-ibero text-center bg-secondary text-white">
        <tr>
          <td colspan="8">{{ (Grupos)?.length }} Registros</td>
        </tr>
      </tfoot>
    </table>
  </div>
  
  <!--Formulario-->
  
  <ng-template #content let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Datos del grado</div>
        <div class="card-body text-dark">
  
          <div class=" card-body row">
            <div class="col-12">
              <form [formGroup]="forma">
                <div class="row text-dark">
                  <input class="form-control" type="hidden" formControlName="gradoId"
                         [(ngModel)]="model.gradoId">
                </div>
                <div class="row text-dark">
                  <div class="form-group col-lg-12">
                    <label for="nombre">Nombre:</label>
                    <input class="form-control" type="text" formControlName="nombre"
                           [(ngModel)]="model.nombre">
                  </div>
                  <!--div class="form-group col-lg-6">
                    <label for="activo">Estatus</label>
                    <select class="form-control" formControlName="activo"
                            [(ngModel)]="model.activo">
                      <option value="">--Seleccionar--</option>
                      <option value="S">Activo</option>
                      <option value="N">Inactivo</option>
                    </select>
                  </div-->
                </div>
                <div class="row text-dark pt-3">
                  <div class="col-lg-12">
                    <div class="buttonSelect text-center">
                      <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                      <button class="btn btn-danger" (click)="guardar()">Guardar</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  