<div class="container admin-page mt-5">
    <div class="text-center">
        <h2 class="mb-0">BIENVENIDO</h2>
        <p class="h4">¿Que desea hacer?</p>
    </div>
    <div class="mt-5 row">
        <div *ngIf="us.adm() || us.coordPIT()" class="col-md-3">
            <div class="btn-admin bg-gradient-3 text-center text-white w-100" (click)="irGuia()">
                <fa-icon [icon]="['far','file-alt']" class="mb-2"></fa-icon>
                <h4>Gestionar Guías</h4>
            </div>
        </div>
        <div *ngIf="us.espTOE()" class="col-md-2">
        </div>
        <div *ngIf="us.espTOE() || us.coordAcad()" class="col-md-3">
            <div class="btn-admin bg-gradient-3 text-center text-white w-100" [routerLink]="['/e-bienvenida']">
                <fa-icon [icon]="['far','file-alt']" class="mb-2"></fa-icon>
                <h4>Ver Guías</h4>
            </div>
        </div>
        <div *ngIf="us.adm() || us.coordPIT() || us.coordAcad()" class="col-md-3">
            <div class="btn-admin bg-gradient-2 text-center text-white w-100" (click)="irTutores()">
                <fa-icon [icon]="['fas','chalkboard-teacher']" class="mb-2"></fa-icon>
                <h4>Ver Tutores</h4>
            </div>
        </div>
        <div class="col-md-3">
            <div class="btn-admin bg-gradient-1 text-center text-white w-100" (click)="irEstudiantes()">
                <fa-icon [icon]="['fas','graduation-cap']" class="mb-2"></fa-icon>
                <h4>Ver Estudiantes</h4>
            </div>
        </div>
        <div class="col-md-3">
            <div class="btn-admin bg-gradient-4 text-center text-white w-100" (click)="irReportes()">
                <fa-icon [icon]="['far','file-alt']" class="mb-2"></fa-icon>
                <h4>Reportes</h4>
            </div>
        </div>
    </div>
</div>