import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { generate } from 'rxjs';
import { ReporteService } from 'src/app/data-access/reporte.service';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-honey-alonso',
  templateUrl: './honey-alonso.component.html',
  styleUrls: ['./honey-alonso.component.css']
})
export class HoneyAlonsoComponent implements OnInit {

  constructor(private usuarioService: UsuarioService,
    private reporteService: ReporteService,
    private spinner: NgxSpinnerService) { }

  coord = false;
  tutor = false;
  cohorte: number = 0;
  anio: number = 0;
  periodo: number = -1;
  area: string = 'DV-0';
  cuenta: string;
  reporte: any;
  preguntas: any;

  anios: Array<number> = [];
  isTutor = false;
  areas;

  ngOnInit(): void {
    var anioActual = new Date().getFullYear();
    for (let index = anioActual; index >= 2020; index--) {
      this.anios.push(index);
    }
    this.isTutor = this.usuarioService.tutor();
    this.reporteService.getAreas().toPromise().then(data => {
      this.areas = data;
    });
    this.reporteService.getPreguntasHA().toPromise().then(data => {
      this.preguntas = data;
    });
    this.coord = this.usuarioService.coordAcad();
    this.tutor = this.usuarioService.tutor();
  }

  cargarAnios(){
    this.anios = [];
    let anioInicio = 0, anioActual = new Date().getFullYear();
    if(this.cohorte == 0){
      anioInicio = 2020;
    }else{
      anioInicio = 2015;
    }
    for (let index = anioActual; index >= anioInicio; index--) {
      this.anios.push(index);
    }
  }

  generarReporte() {
    let element = document.getElementById('tabla_reporte');

    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

    XLSX.writeFile(book, "Reporte_HA.xlsx");
  }

  buscar() {
    
    if (this.coord) {
      this.consultarCoord();
    } else if (this.tutor) {
      this.consultarTutor();
    } else {
      this.consultarAdm();
    }

  }

  consultarAdm() {
    this.spinner.show();
    if (this.cuenta != null && this.cuenta != '') {
      this.reporteService.getReporteHA(this.cohorte, this.anio, this.periodo, this.area, this.cuenta).toPromise().then(data => {
        this.reporte = data;
        for (let i = 0; i < this.reporte.length; i++) {
          this.reporte[i]['respuestas'] = this.reporte[i].preguntas.split(',');
        }
        this.spinner.hide();
      }).catch((err)=>{
        console.log(err);
        alert("Error al consultar");
        this.spinner.hide();
      }).finally(() => {
        setTimeout(()=>this.generarReporte(), 2000);
      });
    } else {
      this.reporteService.getReporteHA(this.cohorte, this.anio, this.periodo, this.area, 0).toPromise().then(data => {
        this.reporte = data;
        this.reporte = data;
        for (let i = 0; i < this.reporte.length; i++) {
          this.reporte[i]['respuestas'] = this.reporte[i].preguntas.split(',');
        }
        this.spinner.hide();
      }).catch((err)=>{
        console.log(err);
        alert("Error al consultar");
        this.spinner.hide();
      }).finally(() => {
        setTimeout(()=>this.generarReporte(), 2000);
      });
    }
  }

  consultarCoord() {
    this.spinner.show();
    if (this.cuenta != null && this.cuenta != '') {
      this.reporteService.getReporteHACoord(this.cohorte, this.anio, this.periodo, this.usuarioService.getCuenta().cuenta, this.cuenta).toPromise().then(data => {
        this.reporte = data;
        for (let i = 0; i < this.reporte.length; i++) {
          this.reporte[i]['respuestas'] = this.reporte[i].preguntas.split(',');
        }
        this.spinner.hide();
      }).catch((err)=>{
        console.log(err);
        alert("Error al consultar");
        this.spinner.hide();
      }).finally(() => {
        setTimeout(()=>this.generarReporte(), 2000);
      });
    } else {
      this.reporteService.getReporteHACoord(this.cohorte, this.anio, this.periodo, this.usuarioService.getCuenta().cuenta, 0).toPromise().then(data => {
        this.reporte = data;
        for (let i = 0; i < this.reporte.length; i++) {
          this.reporte[i]['respuestas'] = this.reporte[i].preguntas.split(',');
        }
        this.spinner.hide();
      }).catch((err)=>{
        console.log(err);
        alert("Error al consultar");
        this.spinner.hide();
      }).finally(() => {
        setTimeout(()=>this.generarReporte(), 2000);
      });
    }
  }

  consultarTutor() {
    this.spinner.show();
    if (this.cuenta != null && this.cuenta != '') {
      this.reporteService.getReporteHATut(this.usuarioService.getCuenta().cuenta, this.cuenta).toPromise().then(data => {
        this.reporte = data;
        for (let i = 0; i < this.reporte.length; i++) {
          this.reporte[i]['respuestas'] = this.reporte[i].preguntas.split(',');
        }
        this.spinner.hide();
      }).catch((err)=>{
        console.log(err);
        alert("Error al consultar");
        this.spinner.hide();
      }).finally(() => {
        setTimeout(()=>this.generarReporte(), 2000);
      });
    } else {
      this.reporteService.getReporteHATut(this.usuarioService.getCuenta().cuenta, 0).toPromise().then(data => {
        this.reporte = data;
        this.reporte = data;
        for (let i = 0; i < this.reporte.length; i++) {
          this.reporte[i]['respuestas'] = this.reporte[i].preguntas.split(',');
        }
        this.spinner.hide();
      }).catch((err)=>{
        console.log(err);
        alert("Error al consultar");
        this.spinner.hide();
      }).finally(() => {
        setTimeout(()=>this.generarReporte(), 2000);
      });
    }
  }

}