//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef, TemplateRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { GuiaService } from 'src/app/data-access/guia.service';
import { ModuloService } from 'src/app/data-access/modulo.service';
import { ActividadService } from 'src/app/data-access/actividad.service';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { DinamicaAcuerdoDesacuerdoService } from 'src/app/data-access/dinamicaAcuerdoDesacuerdo.service';
import { ClaveSemanalService } from 'src/app/data-access/claveSemanal.service';
import { ClaveSemestralService } from 'src/app/data-access/claveSemestral.service';
import { RHService } from 'src/app/data-access/rh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as myGlobals from 'src/app/data-access/util';

@Component({
  selector: 'app-e-evaluacion',
  templateUrl: './e-resumen.component.html',
  styles: [
  ]
})
export class EResumenComponent implements OnInit {
  
    Usuario;
    Cuenta;
    CuentaTutor;

    isTutor = false;
    hasRuta = false;

    Actividad;

    tablaRespuestas;
    respuestasCheck;
    respuestasOption;

    respuestasAlt;

    claveSemanalList;
    claveSemanal;

    clavesTabla;

    fprincipal: FormGroup;
    mprincipal;
    mensajeTutor = {};
    mensajeAlumno = {};

    constructor(
        private router: Router,
        public fb: FormBuilder,
        private guiaService: GuiaService,
        private moduloService: ModuloService,
        private actividadService: ActividadService,
        public usuarioService: UsuarioService,
        private dinamicaAcuerdoDesacuerdoService: DinamicaAcuerdoDesacuerdoService,
        private claveSemanalService: ClaveSemanalService,
        private claveSemestralService: ClaveSemestralService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
    ) {}

    @ViewChild('actividadModal', { read: TemplateRef }) actividadModal:TemplateRef<any>;

    ngOnInit(): void {
        this.Usuario = this.usuarioService.getCuenta();
        this.Cuenta = JSON.parse(localStorage.getItem('uCuentaEstudiante') || 'null') || '';
        if(!this.Cuenta){
            this.Cuenta = JSON.parse(localStorage.getItem('uCuenta') || '{}') || {};
            if(this.Cuenta.idPerfil != 99 && this.Cuenta.idPerfil != 10){
                this.router.navigate(['/e-status-tutor']); 
            }
        }else{
            this.CuentaTutor = JSON.parse(localStorage.getItem('uCuenta') || '{}') || {};
            this.isTutor = true;
        }

        this.iniciarForm();

        if(!this.isTutor){
            this.addLista();
        }else{
            let idGuia = JSON.parse(localStorage.getItem('tGuia') || 'null') || '';
            localStorage.setItem('tGuia', null);

            if(idGuia){
                this.addGuia(idGuia);
            }else{
                let ruta = JSON.parse(localStorage.getItem('tRutaActividad') || 'null') || '';
                localStorage.setItem('tRutaActividad', null);
                if(ruta){
                    this.hasRuta = true;
                    this.addRuta(ruta.idGuia, ruta.idModulo, ruta.idTema);
                    this.Actividad = {idActividad: ruta.idActividad, actividadResuelta: true};
                }else{
                    this.router.navigate(['/e-status']); 
                }
            }
        }
    }

    ngAfterViewInit() {
        if(this.isTutor && this.hasRuta){
            this.verActividad(this.Actividad);
        }
    }

    iniciarForm() {
        this.fprincipalIniciar();
    }

    fprincipalIniciar(){
        //Principal
        this.fprincipal = this.fb.group({
            idRetroalimentacionActividad: [0],
            comentadoPor: ['T'],
            comentario: ['', [Validators.required]]
        });
        //this.patch();
        this.mprincipal = this.fprincipal;
    }

    Guia;
    addLista() {
        this.spinner.show();
        this.guiaService.getData().toPromise().then(data => {
            this.Guia = data;
            this.Guia.forEach(x => {
                this.usuarioService.actividadesResueltasGuiaUsuario(x.idGuia, this.Cuenta.idUsuario).toPromise().then(dt => {
                    x.resumen = dt;
                    this.spinner.hide();
                });
            });
        });
    }

    addGuia(idGuia) {
        this.spinner.show();
        this.guiaService.getDataById(idGuia).toPromise().then(data => {
            this.Guia = [data];
            this.spinner.hide();
        });
    }

    addRuta(idGuia, idModulo, idTema) {
        this.spinner.show();
        this.guiaService.getDataById(idGuia).toPromise().then(data => {
            this.Guia = [data];
            this.Guia[0].modulos.forEach(e => {
                if(e.idModulo == idModulo){
                    this.moduloService.getDataById(e.idModulo).toPromise().then(dt => {
                        e.temas = (dt as any).temas;
                        e.expand = true;
                        e.temas.forEach(el => {
                            if(el.idTema == idTema){
                                this.usuarioService.actividadesResueltasTemaUsuario(el.idTema, this.Cuenta.idUsuario).toPromise().then(d => {
                                    el.actividades = d;
                                    el.expand = true;
                                    this.spinner.hide();
                                });
                            }
                        });
                    }); 
                }
            });
        });
    }

    temas(modelo) {
        this.spinner.show();
        let temas;
        this.moduloService.getDataById(modelo.idModulo).toPromise().then(data => {
            temas = data;
            modelo.temas = temas.temas;
            modelo.expand = true;
            this.spinner.hide();
            console.log(this.Guia);
        }); 
    }

    temasCollapse(modelo) {
        modelo.temas = [];
        modelo.expand = false;
    }

    actividades(modelo) {
        this.spinner.show();
        this.usuarioService.actividadesResueltasTemaUsuario(modelo.idTema, this.Cuenta.idUsuario).toPromise().then(dt => {
            modelo.actividades = dt;
            modelo.expand = true;
            this.spinner.hide();
            console.log(this.Guia);
        });
    }

    actividadesCollapse(modelo) {
        modelo.actividades = [];
        modelo.expand = false;
    }

    verActividad(act){
        if(act.actividadResuelta){
            this.Actividad = act;
            this.getActividad();
            this.modal.open(this.actividadModal, { size: 'lg' });
        }
    }

    getActividad() {
        this.spinner.show(); 
        let dt;   
        this.mensajeTutor = {};
        this.mensajeAlumno = {};
        this.actividadService.getDataByIdAndUser(this.Actividad.idActividad, this.Cuenta.idUsuario).toPromise().then(data => {
            dt = data;
            this.Actividad = data;

            switch (this.Actividad.idTipoActividad) {
                case 1: 
                    if(dt.dinamica[0].dinamicaTabla.filas.length){
                        this.tablaRespuestas = [];
                        let arr = [];
                        let col = dt.dinamica[0].dinamicaTabla.columnas.length;
                        dt.dinamica[0].dinamicaTabla.filas.forEach((x, i) => {
                            if((i + 1) % col == 0){
                                arr.push(x.respuesta);
                                this.tablaRespuestas.push(arr);
                                arr = [];
                            }else{
                                arr.push(x.respuesta);
                            }
                        });
                    }else{
                        let arr = [];
                        dt.dinamica[0].dinamicaTabla.columnas.forEach(element => {
                            arr.push('');
                        });
                        this.tablaRespuestas = [];
                        this.tablaRespuestas.push(arr);
                    }

                    if(dt.idActividad == 3){
                        this.dinamicaAcuerdoDesacuerdoService.getDataById(2, this.Cuenta.idUsuario).toPromise().then(data => {
                            this.respuestasAlt = data;
                        });
                    }
                    break; 
                case 2:
                    this.respuestasCheck = [];
                    if(dt.dinamica[0].dinamicaAcuerdoDesacuerdo.respuestas.length){
                        dt.dinamica[0].dinamicaAcuerdoDesacuerdo.preguntas.forEach((element, idx) => {
                            this.respuestasCheck.push({idDinamicaAcuerdoDesacuerdoPregunta: element.idDinamicaAcuerdoDesacuerdoPregunta, respuesta: dt.dinamica[0].dinamicaAcuerdoDesacuerdo.respuestas[idx].respuesta});
                        });
                    }else{
                        dt.dinamica[0].dinamicaAcuerdoDesacuerdo.preguntas.forEach(element => {
                            this.respuestasCheck.push({idDinamicaAcuerdoDesacuerdoPregunta: element.idDinamicaAcuerdoDesacuerdoPregunta, respuesta: false});
                        });
                    }
                    break;
                case 3:
                    this.respuestasOption = [];
                    if(dt.dinamica[0].dinamicaFrecuenciaRealizacion.respuestas.length){
                        dt.dinamica[0].dinamicaFrecuenciaRealizacion.respuestas.forEach((element, idx) => {
                            this.respuestasOption.push({idDinamicaFrecuenciaRealizacionPregunta: element.idDinamicaFrecuenciaRealizacionPregunta, idDinamicaFrecuenciaRealizacionOpcion: element.idDinamicaFrecuenciaRealizacionOpcion});
                        });
                    }else{
                        dt.dinamica[0].dinamicaFrecuenciaRealizacion.preguntas.forEach(element => {
                            this.respuestasOption.push({idDinamicaFrecuenciaRealizacionPregunta: element.idDinamicaFrecuenciaRealizacionPregunta, idDinamicaFrecuenciaRealizacionOpcion: false});
                        });
                    }
                    break;
                case 4:
                    this.claveSemanalService.getData().toPromise().then(data => {
                        this.claveSemanalList = data;
                        this.clavesTabla = [];
                        let idx = 1;
                        let arrAux = [];
                        this.claveSemanalList.forEach(v => {
                            if(idx == 1){
                                idx++;
                                arrAux.push({clave: v.clave, significado: v.significado});
                            }else{
                                idx = 1;
                                arrAux.push({clave: v.clave, significado: v.significado});
                                this.clavesTabla.push(arrAux);
                                arrAux = [];
                            }
                        });
                        if(arrAux.length){
                            this.clavesTabla.push(arrAux);
                        }

                        this.respuestasOption = [];
                        dt.dinamica[0].dinamicaConcentracionSemanal.rangosHoras.forEach(v => {
                            let arr = [];
                            dt.dinamica[0].dinamicaConcentracionSemanal.diasSemana.forEach(val => {
                                arr.push(0);
                            });
                            this.respuestasOption.push(arr);
                        });

                        dt.dinamica[0].dinamicaConcentracionSemanal.respuestasOpciones.forEach(v => {
                            this.respuestasOption[v.idRangoHoraDia - 1][v.idDiaSemana - 1] = {id: v.idClaveActividadSemanal, clave: this.claveSemanalList.find(x => x.idClaveActividadSemanal == v.idClaveActividadSemanal).clave};
                        });

                        this.respuestasCheck = [];
                        if(dt.dinamica[0].dinamicaConcentracionSemanal.respuestasPreguntas.length){
                            dt.dinamica[0].dinamicaConcentracionSemanal.preguntas.forEach((v, i) => {
                                this.respuestasCheck.push({
                                    idDinamicaConcentracionSemanalPregunta: v.idDinamicaConcentracionSemanalPregunta,
                                    respuesta: dt.dinamica[0].dinamicaConcentracionSemanal.respuestasPreguntas[i].respuesta,
                                    pregunta: v.pregunta
                                });
                            });
                        }else{
                            dt.dinamica[0].dinamicaConcentracionSemanal.preguntas.forEach((v, i) => {
                                this.respuestasCheck.push({
                                    idDinamicaConcentracionSemanalPregunta: v.idDinamicaConcentracionSemanalPregunta,
                                    respuesta: '',
                                    pregunta: v.pregunta
                                });
                            });
                        }
                    });

                    break;
                case 5:
                    this.claveSemestralService.getData().toPromise().then(data => {
                        this.claveSemanalList = data;
                        this.clavesTabla = [];
                        let idx = 1;
                        let arrAux = [];
                        this.claveSemanalList.forEach(v => {
                            if(idx == 1){
                                idx++;
                                arrAux.push({clave: v.clave, significado: v.significado});
                            }else{
                                idx = 1;
                                arrAux.push({clave: v.clave, significado: v.significado});
                                this.clavesTabla.push(arrAux);
                                arrAux = [];
                            }
                        });
                        if(arrAux.length){
                            this.clavesTabla.push(arrAux);
                        }

                        this.respuestasOption = [];
                        dt.dinamica[0].dinamicaPlaneacionSemestral.semanasSemestre.forEach(v => {
                            let arr = [];
                            dt.dinamica[0].dinamicaPlaneacionSemestral.diasSemana.forEach(val => {
                                arr.push(0);
                            });
                            this.respuestasOption.push(arr);
                        });

                        dt.dinamica[0].dinamicaPlaneacionSemestral.respuestasOpciones.forEach(v => {
                            this.respuestasOption[v.idSemanaSemestre - 1][v.idDiaSemana - 1] = {id: v.idClaveActividadSemestral, clave: this.claveSemanalList.find(x => x.idClaveActividadSemestral == v.idClaveActividadSemestral).clave};
                        });
                    });

                    break;
                default:
                    console.log('default');
            }

            this.mensajeTutor = this.Actividad.comentariosRetroalimentacion[0] || {comentario: ''};
            this.mensajeAlumno = this.Actividad.comentariosRetroalimentacion[1] || {comentario: ''};
            this.mprincipal.comentario = this.mensajeTutor['comentario'];

            this.spinner.hide();
        }); 
    }

    guardar() {
        this.spinner.show();
        this.usuarioService.retroalimentacionActividad(this.CuentaTutor.idUsuario, this.Cuenta.idUsuario, this.Actividad.idActividad, this.fprincipal.value).subscribe(
            (res) => { 
                this.spinner.hide();
                this.mensajeTutor = res.comentario;
                this.toastr.success('El mensaje ha sido enviado al estudiante', 'Mensaje Enviado', {positionClass: 'toast-bottom-left'});
            },
            (error) => {
                //this.spinner.hide();
                console.log(error);
                this.toastr.error(error.error.message);
                this.spinner.hide();
        });
    }
    
    asignar(item){
        this.spinner.show();
        this.usuarioService.asignacionActividadAlumno(this.CuentaTutor.idUsuario, this.Cuenta.idUsuario, item.idActividad).subscribe(
            (res) => { 
                this.spinner.hide();
                if(!res.actividadAsignada.activo){
                    item.fechaAsignacion = '';
                    this.toastr.warning('Se ha eliminado la asignación de la actividad al estudiante', 'Asignación Removida', {positionClass: 'toast-bottom-left'});
                }else{
                    item.fechaAsignacion = res.actividadAsignada.fechaRegistro;
                    this.toastr.success('Se ha asignado la actividad al estudiante', 'Actividad Asignada', {positionClass: 'toast-bottom-left'});
                }
            },
            (error) => {
                this.toastr.error(error.error.message);
                this.spinner.hide();
            }
        )
    }

}
