import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { QuillModule } from 'ngx-quill';

import { DataTablesModule } from "angular-datatables";

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { faCircle, faSquare, faInfoCircle, faEye, faPlus, faMinus, faChalkboardTeacher, faGraduationCap, faCogs } from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle, faSquare as farSquare, faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faStackOverflow, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faTrashAlt, faCheckCircle, faTimesCircle, faEdit, faTrash,
  faCalendarCheck, faSitemap, faLink, faMapMarked, faMap, faPlusCircle, faSave, faDoorOpen, faWindowClose } from '@fortawesome/free-solid-svg-icons';
/*
import { HorizontePlanComponent } from './horizonte-plan/horizonte-plan.component';
import { HorizonteAnualComponent } from './horizonte-anual/horizonte-anual.component';
import { PlanInstitucionalComponent } from './plan-institucional/plan-institucional.component';
import { NgxSummernoteModule } from 'ngx-summernote';
*/
import { RolComponent } from './rol/rol.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { AdministracionComponent } from './administracion/administracion.component';
import { GuiaComponent } from './guia/guia.component';
import { TutoresComponent } from './tutores/tutores.component';
import { EstudiantesComponent } from './estudiantes/estudiantes.component';
import { GuiaDetalleComponent } from './guia-detalle/guia-detalle.component';
import { ActividadComponent } from './actividad/actividad.component';
import { TemaComponent } from './tema/tema.component';
import { CompetenciaComponent } from './competencia/competencia.component';
import { ConceptoClaveComponent } from './concepto-clave/concepto-clave.component';
import { ClaveSemanalComponent } from './clave-semanal/clave-semanal.component';
import { ClaveSemestralComponent } from './clave-semestral/clave-semestral.component';
import { EvaluacionDetalleComponent } from './evaluacion-detalle/evaluacion-detalle.component';
import { GrupoComponent } from './grupo/grupo.component';
import { InscripcionComponent } from './inscripcion/inscripcion.component';
import { RespuestaComponent } from './respuesta/respuesta.component';
import { TipoRespuestaComponent } from './tipo-respuesta/tipo-respuesta.component';
import { SeccionComponent } from './seccion/seccion.component';
import { PreguntaComponent } from './pregunta/pregunta.component';
import { ContraInvitadoComponent } from './contra-invitado/contra-invitado.component';
import { ActividadesInvitadoComponent } from './actividades-invitado/actividades-invitado.component';

@NgModule({
  //declarations: [HorizontePlanComponent, HorizonteAnualComponent, UsuarioComponent, PlanInstitucionalComponent, RolComponent],
  declarations: [UsuarioComponent, RolComponent, AdministracionComponent, GuiaComponent, TutoresComponent, EstudiantesComponent, GuiaDetalleComponent, ActividadComponent, TemaComponent, CompetenciaComponent, ConceptoClaveComponent, ClaveSemanalComponent, ClaveSemestralComponent, EvaluacionDetalleComponent, GrupoComponent, InscripcionComponent, RespuestaComponent, TipoRespuestaComponent, SeccionComponent, PreguntaComponent, ContraInvitadoComponent, ActividadesInvitadoComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    NgbModule,
    DataTablesModule,
    ReactiveFormsModule,
    FormsModule,
    QuillModule.forRoot({
        placeholder: 'Ingrese su texto aquí...',
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],
            
                ['clean'],                                         // remove formatting button
                ['link', 'image']                                  // link and image, video
            ]
        }
    })
    /*,NgxSummernoteModule*/
  ],
  providers: [{
    provide: NgbDateAdapter,
    useClass: NgbDateNativeAdapter
  }],
  //exports: [HorizontePlanComponent, HorizonteAnualComponent, UsuarioComponent, PlanInstitucionalComponent]
  //exports: [UsuarioComponent, RolComponent]
})
export class AdminModule {

  constructor(library: FaIconLibrary) {
    library.addIcons(faCircle, faSquare, farCircle, farSquare, faStackOverflow, faGithub, faChalkboardTeacher, faGraduationCap, faFileAlt,
      faMedium, faTrashAlt, faCheckCircle, faTimesCircle, faEdit, faTrashAlt, faTrash, faCalendarCheck,
      faSitemap, faLink, faMapMarked, faMap, faPlusCircle, faSave, faDoorOpen, faWindowClose, faInfoCircle, faEye, faPlus, faMinus, faCogs);
  }

}
