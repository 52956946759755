//import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  Component, OnInit, ViewChild, Input, ViewChildren,
  QueryList, AfterViewInit, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
//import { RHService } from 'src/app/data-access/rh.service';
import { GuiaService } from 'src/app/data-access/guia.service';
import { EvalSeccionService } from 'src/app/data-access/evalseccion.service';
import { EvalPreguntaService } from 'src/app/data-access/evalpregunta.service';
import { SeccionService } from '../../../data-access/seccion.service';
import { PreguntaService } from '../../../data-access/pregunta.service';
import { TipoRespuestaService } from '../../../data-access/tipoRespuesta.service';
import { RespuestaService } from '../../../data-access/respuesta.service';
//import { HorizonteAnualService } from 'src/app/data-access/horizonteAnual.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import * as myGlobals from 'src/app/data-access/util';
//import {NgbCalendar, NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-evaluacion-detalle',
  templateUrl: './evaluacion-detalle.component.html',
  styles: [
  ]
})
export class EvaluacionDetalleComponent implements OnInit {
  fprincipal: FormGroup;
  fperfil: FormGroup;
  ffiltro: FormGroup;
  mprincipal;
  mperfil;
  mfiltro;
  PerfilLista;
  constructor(
    private router: Router,
    public fb: FormBuilder,
    private usuarioService: UsuarioService,
    private seccionService: SeccionService,
    private preguntaService: PreguntaService,
    private tipoRespuestaService: TipoRespuestaService,
    private respuestaService: RespuestaService,
    //private rhService: RHService,
    private evalSeccionService: EvalSeccionService,
    private evalPreguntaService: EvalPreguntaService,
    private evaluacionService: GuiaService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal,
  ) {
    this.iniciarForm();
  }

  sUsuario;
  idEvaluacion;
  ngOnInit(): void {

    let evaluacion = JSON.parse(localStorage.getItem('evaluacion') || '{}') || [];
    this.idEvaluacion = evaluacion.idEvaluacion;
    this.evaluacionNombre = evaluacion.nombre;
    this.addEvalSeccion();
    this.addFiltros();
    this.sort();   
    /*
    this.activatedRoute.params.subscribe(params => {
      this.spinner.show();
      this.idEvaluacion = Number(params['id']);
      //alert(this.idEvaluacion);
      this.addEvalSeccion();
      this.addFiltros();
      this.sort();      
    });
    */
  }

  iniciarForm() {
    //this.fprincipalIniciar();
    this.fseccionIniciar();
    this.fpreguntaIniciar();
  }

fseccion: FormGroup;
mseccion;
fseccionIniciar(){
  //Principal
  this.fseccion = this.fb.group({
    idEvalSeccion: [0],
    idSeccion: [0],
    idEvaluacion: [0],
    nombre: ['']
  });
  this.mseccion = this.fseccion;
}

fpregunta: FormGroup;
mpregunta;
fpreguntaIniciar(){
  //Principal
  this.fpregunta = this.fb.group({
    idEvalPregunta: [0],
    idEvalSeccion: [0],
    idPregunta: [0],
    descripcion: [''],
    idRespuesta: [0],
    orden: [0],
    activo: [true]
  });
  this.mpregunta = this.fpregunta;
}

  //Encuesta;
  evaluacionNombre;
  //EncuestaLista;
  //EncuestaDetalle;
  //SeccionLista;
  EvalSeccion;
  par = [];
  addEvalSeccion() {
    //this.spinner.show();    
    this.evalSeccionService.getEvalSeccion().toPromise()
    //this.par.push({'idEvaluacion':this.idEvaluacion});
    //this.evaluacionService.getEvaluacionEstructura(this.par[0]).toPromise()
      .then(data => {
        this.EvalSeccion = data;
        console.log(this.EvalSeccion);
        this.addEvalPregunta();
        //this.spinner.hide();
      }); 
  }
  
  EvalPregunta;
  addEvalPregunta() {
    //this.spinner.show();    
    this.evalPreguntaService.getEvalPregunta().toPromise()
      .then(data => {
        this.EvalPregunta = data;
        this.loadTree();
        //this.spinner.hide();
      }); 
  }
  
  Evaluacion;
  loadTree(){
        /*
         * Armar arbol con Lineas, Objetivo y metas
         * planLista, objetivoLista, metaLista
         */
        var result = this.EvalSeccion;
        //var idx;
        //Lineas
        for (var i = 0; i < this.EvalSeccion.length; i++) {
          var prop = this.EvalSeccion[i].idEvalSeccion;
          var child;

          result[i].children = [];
          //Objetivos
          for (var j = 0; j < this.EvalPregunta.length; j++) {
            var parent = this.EvalPregunta[j].idEvalSeccion;

            if (parent == prop) {
              child = this.EvalPregunta[j];
              result[i].children.push(child);
            }
          }          
        }
        this.Evaluacion = result;
        //console.log(result);    
  }  

  Secciones;
  addSeccion(){
    this.spinner.show();
    this.seccionService.getSeccion().toPromise()
    .then(data => {
      this.spinner.hide();
      this.Secciones = data;
    });
  }
  
  Preguntas;
  addPregunta(){
    this.spinner.show();
    this.preguntaService.getPregunta().toPromise()
    .then(data => {
      this.spinner.hide();
      this.Preguntas = data;
    });
  }  
  
  TipoRespuestas;
  addTipoRespuesta(){
    this.spinner.show();
    this.tipoRespuestaService.getTipoRespuesta().toPromise()
    .then(data => {
      this.spinner.hide();
      this.TipoRespuestas = data;
    });
  }
  
  Respuestas;
  addRespuesta(){
    this.spinner.show();
    this.respuestaService.getRespuesta().toPromise()
    .then(data => {
      this.spinner.hide();
      this.Respuestas = data;
    });
  }   

  addFiltros() {
    //this.loadRoles();
    this.addSeccion();
    this.addPregunta();
    this.addTipoRespuesta();
    this.addRespuesta();
  }

  recargar() {
    this.spinner.hide();
    this.modal.dismissAll();
    this.addEvalSeccion();
  }  

  nuevo(content) {
    this.iniciarForm();
    //this.model.activo = 'S';
    this.mseccion.idEvaluacion = this.idEvaluacion;
    this.modal.open(content, { size: 'xl' });
    //document.getElementById("mprincipal.cuenta").focus();
  }

  editar(content, modelo) {
    console.log(modelo);
    this.mseccion = modelo;
    this.modal.open(content, { size: 'xl' });
    //document.getElementById("mseccion.nombre").focus();
  }

  guardarSeccion() {
    this.spinner.show();
    if (this.fseccion.controls["idEvalSeccion"].value == undefined) {
      this.evalSeccionService.addData(this.fseccion.value).subscribe(
        (res) => { this.recargar(); },
        (error) => {
          //this.spinner.hide();
          console.log(error);
          this.toastr.error(error.error.message);
          this.spinner.hide();
        });
    }
    else {
      this.evalSeccionService.updData(this.fseccion.controls["idEvalSeccion"].value, this.fseccion.value).subscribe(
        (res) => { this.recargar(); },
        (error) => {
          console.log(error);
          this.toastr.error(error.error.message);
          this.spinner.hide();
        });
    }
    
  }

  guardarPregunta() {
    this.spinner.show();
    if (this.fpregunta.controls["idEvalPregunta"].value == undefined) {
      this.evalPreguntaService.addData(this.fpregunta.value).subscribe(
        (res) => { this.recargar(); },
        (error) => {
          //this.spinner.hide();
          console.log(error);
          this.toastr.error(error.error.message);
          this.spinner.hide();
        });
    }
    else {
      this.evalPreguntaService.updData(this.fpregunta.controls["idEvalPregunta"].value, this.fpregunta.value).subscribe(
        (res) => { this.recargar(); },
        (error) => {
          console.log(error);
          this.toastr.error(error.error.message);
          this.spinner.hide();
        });
    }
    
  }  

  nuevaPregunta(pregunta, seccion){
    this.fpreguntaIniciar();
    //this.model.activo = 'S';
    //alert(seccion.idEvalSeccion);
    this.mpregunta.idEvalSeccion = seccion.idEvalSeccion;
    this.modal.open(pregunta, { size: 'xl' });
    //document.getElementById("mprincipal.cuenta").focus();
  }

  editarPregunta(pregunta, modelo) {
    console.log(modelo);
    this.mpregunta = modelo;
    /*
    this.mpregunta.activo = modelo.activo;
    this.mpregunta.descripcion = modelo.descripcion;
    this.mpregunta.idEvalPregunta = modelo.idEvalPregunta;
    this.mpregunta.idEvalSeccion = modelo.idEvalSeccion;
    this.mpregunta.idPregunta = modelo.idPregunta;
    this.mpregunta.idRespuesta = modelo.idRespuesta;
    this.mpregunta.orden = modelo.orden;
    */
    this.modal.open(pregunta, { size: 'xl' });
    
    //document.getElementById("mseccion.nombre").focus();
  }  

  detalle(item) {
    this.router.navigate(['/encuesta-detalle']);  
  }

  formatDate(fecha) {
    var d = new Date(fecha);
    d.setHours(0, 0, 0, 0);
    return [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear()
    ].join('/');
  }

  asc;
  sort() {
    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

    const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
      v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
    )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    // do the work...
    document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
      const table = th.closest('table');
      const tbody = table.querySelector('tbody');
      Array.from(tbody.querySelectorAll('tr'))
        .sort(
          comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc)
        )
        .forEach(tr => tbody.appendChild(tr));
    })));
  }



}
