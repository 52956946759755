import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class ConceptoClaveService {
  user = [];
  constructor(private http: HttpClient) {
      
  }

  getData() {
    return this.http.get(config.apiUrl+'/ConceptoClave/');
  }

  getDataById(id:undefined) {
    return this.http.get(config.apiUrl+'/ConceptoClave/' + id);
  }

   //Alta
   addData(data:undefined): Observable<any> {
     return this.http.post(config.apiUrl + '/ConceptoClave/', data);
   }

   //Cambio
   updData(id:undefined, data:undefined): Observable<any> {
     return this.http.put(config.apiUrl + '/ConceptoClave/' + id, data);
   }

   //Eliminar
   dltData(id:undefined): Observable<any> {
    return this.http.delete(config.apiUrl + '/ConceptoClave/' + id);
  }

}